export const cyberbullying = {
  title: {
    en: "Cyberbullying",
    tc: "網絡欺凌",
    sc: "网络欺凌",
  },
  img: "hh4.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
      // color: "#6DC3F2",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Cyberbullying",
        tc: "網絡欺凌",
        sc: "网络欺凌",
      },
      title: {
        en: "Cyberbullying",
        tc: "網絡欺凌",
        sc: "网络欺凌",
      },
      img: "teens_cyberbullying.png",
      aspectRatio: 278 / 225,
      showTips: true,
      points: [
        {
          en: "Ask a trusted adult for help!",
          tc: "把事情告知你信任的人以尋求協助。",
          sc: "把事情告知你信任的人以寻求协助。",
        },
        {
          en: "If you are a bystander, do not participate!",
          tc: "若你是旁觀者，不要參與其中！",
          sc: "若你是旁观者，不要参与其中！",
        },
        {
          en: "If you are a victim, you do not need to defend or take revenge.",
          tc: "若你是受害者，亦無須與其對抗或進行報復。",
          sc: "若你是受害者，亦无须与其对抗或进行报复。",
        },
        {
          en:
            "Do not further circulate any video/ do not download any concerned videos.",
          tc: "不要進一步轉發任何影片／不要下載任何相關影片。",
          sc: "不要进一步转发任何影片／不要下载任何相关影片。",
        },
        {
          en:
            "If possible, seek assistance from service provider to delete the content concerned.",
          tc: "在可能的情況下尋求服務供應者協助，以刪除相關內容。",
          sc: "在可能的情况下寻求服务供应者协助，以删除相关内容。",
        },
        {
          en:
            "You may seek assistance from school, social worker and police.",
          tc: "你亦可尋求學校、社工及警方協助。",
          sc: "你亦可寻求学校、社工及警方协助。",
        },
      ],
    },
  ],
};
