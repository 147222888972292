<template>
  <!-- <div style="margin-top: 20px"> -->
  <div
    :class="isMobile ? '' : 'container'"
    class="mx-0 px-0 mt-4"
    fluid
    style="min-height: 100px; text-align: center;"
  >
    <v-row v-if="isMobile">
      <v-col class="my-0 py-0" icon v-for="(i, index) in icons" :key="index">
          <v-icon size="30px" color="#6A6760" @click="openUrl(i.url)" aria-label="footerItem">
            {{ i.icon }}
          </v-icon>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-spacer />

      <v-col cols="1" v-for="(i, index) in icons" :key="index">
        <!-- <v-btn class="mx-3 white--text" icon> -->
          <v-icon size="30px" color="#6A6760" @click="openUrl(i.url)" aria-label="footerItem">
            {{ i.icon }}
          </v-icon>
        <!-- </v-btn> -->
      </v-col>

      <v-spacer />
    </v-row>

    <v-row>
      <v-col class="my-0 py-1">
        <div class="text-subtitle-2 mt-2" style="color:#000;">
          {{ $t("common.copyRight") }}
        </div>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col class="text-caption my-0 py-0">
        {{ version }}
      </v-col>
    </v-row> -->
  </div>
  <!-- </div> -->
</template>

<style lang="scss" scoped></style>

<script>
// @ is an alias to /src
import SessionTitle from "../atoms/SessionTitle.vue";
import DynamicMarquee from "@/components/organisms/DynamicMarquee.vue";
import config from "../../../config";
import axios from "axios";

export default {
  name: "Footer",
  components: {
    SessionTitle,
    DynamicMarquee,
  },
  props: {
    socialMedia: Array,
  },
  created() {
    // this.fetchData();
    if (this.socialMedia != null && this.socialMedia.length > 0) {
      this.mapDataToIcons(this.socialMedia);
    } else {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      axios
        .get(config.wordpressUrl + `/configs?order=asc&per_page=100`)
        .then((response) => {
          let data = response.data[0];
          if (data != null) {
            this.mapDataToIcons(data.acf.social_media);
          }
        });
    },
    mapDataToIcons(socialMedia) {
      if (socialMedia != null) {
        this.icons = [
          {
            icon: "mdi-instagram",
            url: socialMedia.instagram,
          },
          {
            icon: "mdi-facebook",
            url: socialMedia.facebook,
          },
          {
            icon: "mdi-youtube",
            url: socialMedia.youtube,
          },
          {
            icon: "mdi-twitter",
            url: socialMedia.twitter,
          },
          {
            icon: "mdi-sina-weibo",
            url: socialMedia.weibo,
          },
        ];
      } else {
        this.icons = [
          {
            icon: "mdi-instagram",
            url: "https://www.instagram.com/hongkongpoliceforce/",
          },
          {
            icon: "mdi-facebook",
            url: "https://www.facebook.com/HongKongPoliceForce",
          },
          {
            icon: "mdi-youtube",
            url: "http://www.youtube.com/user/HKPChannel",
          },
          {
            icon: "mdi-twitter",
            url: "https://twitter.com/hkpoliceforce?s=09",
          },
          {
            icon: "mdi-sina-weibo",
            url: "https://m.weibo.cn/u/6572654129",
          },
        ];
      }
    },
    openUrl(url) {
      window.open(url);
    },
  },

  data: () => ({
    version: config.versionNo,
    icons: [
      // {
      //   icon: "mdi-instagram",
      //   url: "https://www.instagram.com/hongkongpoliceforce/",
      // },
      // {
      //   icon: "mdi-facebook",
      //   url: "https://www.facebook.com/HongKongPoliceForce",
      // },
      // {
      //   icon: "mdi-youtube",
      //   url: "http://www.youtube.com/user/HKPChannel",
      // },
      // {
      //   icon: "mdi-twitter",
      //   url: "https://twitter.com/hkpoliceforce?s=09",
      // },
      // {
      //   icon: "mdi-sina-weibo",
      //   url: "https://m.weibo.cn/u/6572654129",
      // },
    ],
  }),
};
</script>
