<template>
  <div class="photoTheme d-flex align-center justify-center">
    <div style="max-width: 450px; min-width: 50px">
      <div class="d-flex align-center justify-space-between">
        <BackButton />
        <div
          class="mb-3 d-flex align-center justify-center ml-1"
          style="font-size: 12px"
        >
          <!-- <div
            class="btn d-flex align-center justify-center"
            @click="onUploadBtnClick()"
          >
            <div class="text-center d-flex align-center">
              <v-icon class="mr-1" small color="#fff">mdi-camera</v-icon>
              <span>{{ $t("common.uploadPhoto") }}</span>
            </div>
          </div> -->
          <v-btn
            class="btn d-flex align-center justify-center ml-2"
            @click="onUploadBtnClick()"
          >
            <div class="text-center d-flex align-center">
              <v-icon class="mr-1" color="#000000">mdi-camera</v-icon>
              <span
                style="letter-spacing: 0; font-size: 12px; color: #000000;"
                >{{ $t("common.uploadPhoto") }}</span
              >
            </div>
          </v-btn>
          <v-btn
            class="btn d-flex align-center justify-center ml-2"
            :loading="isSaveBtnLoading"
            @click="onSaveBtnClick()"
          >
            <div class="text-center d-flex align-center">
              <v-icon class="mr-1" color="#000000">mdi-content-save</v-icon>
              <span style="letter-spacing: 0; font-size: 12px; color: #000000">
                {{ $t("common.save") }}
              </span>
            </div>
          </v-btn>
          <!-- <div
            class="btn d-flex align-center justify-center ml-2"
            @click="onSaveBtnClick()"
          >
            <div class="text-center d-flex align-center">
              <v-icon class="mr-1" small color="#fff">mdi-content-save</v-icon>
              <span>{{ $t("common.save") }}</span>
            </div>
          </div> -->
        </div>
      </div>

      <!-- dropdown  -->
      <div
        class="d-flex align-center justify-center flex-row mb-6 mt-3"
        style="
          border-radius: 20px;
          -webkit-box-shadow: 0px 0px 14px -5px #000000;
          box-shadow: 0px 0px 14px -5px #000000;
          position: relative;
          background-color: #fff;
        "
      >
        <div
          cols="3"
          style="width: 23%"
          v-for="(images, i) in allImages"
          :key="i"
        >
          <v-select
            :items="images"
            style="min-height: 50px"
            :value="images[0]"
            flat
            solo
            aria-label="image"
          >
              <template v-slot:selection="{ item }">
                <v-responsive :aspect-ratio="1"> 
                  <v-img
                    class="ma-1"
                    maxWidth="100"
                    maxHeight="100"
                    minWidth="50"
                    minHeight="50"
                    :src="getImageUrl(item.image)"
                    :alt="item.image"
                    aria-label="images"
                  >
                  </v-img>
                </v-responsive>
              </template>

              <template v-slot:item="{ item }">
                  <img :src="getImageUrl(item.image)"
                    @click="onSelectChange(i, item)"
                    contain
                    class="mt-4 mb-4"
                    width="100"
                    alt="test">
              </template>
          </v-select> 
        </div>
      </div>

      <input id="upload" type="file" @change="onFileChange" />

      <!-- Canvas -->
      <v-responsive
        id="photo-canvas"
        :aspect-ratio="1"
        width="100%"
        style="background-color: #fff"
      >
        <v-img :src="getImageUrl(border)" width="100%" height="100%" contain>
          <!-- images background -->
          <Moveable
            class="moveable"
            v-if="userImage"
            v-bind="moveable"
            @drag="handleDrag"
            @resize="handleResize"
            @scale="handleScale"
            @rotate="handleRotate"
            @warp="handleWarp"
            @pinch="handlePinch"
            style="width: 100%; height: 100%; position: absolute"
          >
            <v-img
              :src="userImage"
              width="100%"
              height="100%"
              contain
              style="position: absolute; top: 0; left: 0; pointer-events: none"
            ></v-img>
          </Moveable>

          <!-- frame accessories -->
          <Moveable
            class="moveable"
            v-bind="moveable"
            @drag="handleDrag"
            @resize="handleResize"
            @scale="handleScale"
            @rotate="handleRotate"
            @warp="handleWarp"
            @pinch="handlePinch"
            style="width: 100px; height: 100px; position: absolute"
            v-for="(image, i) in images"
            :key="i"
          >
            <v-img
              :src="getImageUrl(image)"
              width="100"
              height="100"
              contain
              style="position: absolute; top: 0; left: 0; pointer-events: none"
            ></v-img>
          </Moveable>

          <!-- iamge border -->
          <v-img
            :src="getImageUrl(border)"
            width="100%"
            height="100%"
            contain
            style="position: absolute; top: 0; left: 0; pointer-events: none"
          ></v-img>
        </v-img>
      </v-responsive>
      <div>{{ err }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.scale-element {
  display: block;
  max-width: 100%;
  margin: 1rem auto;
  touch-action: none;
}

.scale-element.reset {
  transition: transform 0.3s ease-in-out;
}

.btn {
  background-color: #ffffff !important;
  // background: rgb(90, 147, 255);
  // background: linear-gradient(
  //   180deg,
  //   rgba(90, 147, 255, 1) 0%,
  //   rgba(105, 197, 255, 1) 95%,
  //   rgba(23, 239, 189, 1) 100%
  // );
  height: 36px;
  border-radius: 40px;
  border:        1px solid #556699;
  padding: 0 12px;
  color: #fff;
}

.v-input__append-inner {
  display: none !important;
}

#upload {
  display: none;
}
</style>

<style lang="scss">
.photoTheme {
  .v-input__slot {
    padding: 0 !important;
    margin-top: 8px;
  }
  .v-text-field__details {
    display: none;
  }
  .v-select-list {
    padding: 0 !important;
  }
  .v-list-item {
    color: transparent !important;
  }
}
</style>

<script>
// @ is an alias to /src
import BackButton from "@/components/atoms/BackButton.vue";
import html2canvas from "html2canvas";
import Moveable from "vue-moveable";
import * as htmlToImage from "html-to-image";
import { svg2png } from "svg-png-converter";
import { saveAs } from "file-saver";

export default {
  name: "",
  components: {
    BackButton,
    Moveable,
  },
  props: [],
  created() {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    allImages() {
      return [
        this.borderImages,
        this.netBorderImages,
        this.otherItemImages,
        this.characterImages,
      ];
    },
    borderImages() {
      let images = [];
      for (let i = 1; i < 7; ++i) {
        images.push({ image: "border-" + i + ".png" });
      }
      return images;
    },
    netBorderImages() {
      let images = [];
      for (let i = 1; i < 7; ++i) {
        images.push({ image: "net-border-" + i + ".png" });
      }
      return images;
    },
    otherItemImages() {
      let images = [];
      for (let i = 1; i < 6; ++i) {
        images.push({ image: "item-" + i + ".png" });
      }
      return images;
    },
    characterImages() {
      let images = [];
      for (let i = 1; i < 7; ++i) {
        images.push({ image: "character-" + i + ".png" });
      }
      return images;
    },
  },
  methods: {
    //
    handleDrag({ target, transform }) {
      console.log("onDrag left, top", transform);
      target.style.transform = transform;
    },
    handleResize({ target, width, height, delta }) {
      console.log("onResize", width, height);
      delta[0] && (target.style.width = `${width}px`);
      delta[1] && (target.style.height = `${height}px`);
    },
    handleScale({ target, transform, scale }) {
      console.log("onScale scale", scale);
      target.style.transform = transform;
    },
    handleRotate({ target, dist, transform }) {
      console.log("onRotate", dist);
      target.style.transform = transform;
    },
    handleWarp({ target, transform }) {
      console.log("onWarp", transform);
      target.style.transform = transform;
    },
    handlePinch({ target }) {
      console.log("onPinch", target);
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.userImage = URL.createObjectURL(file);

      this.setImgGesture("gesture-area", "scale-element", this.userImage);
    },
    getImageUrl(img) {
      return require(`@/assets/images/photoTheme/${img}`);
    },
    onSelectChange(i, event) {
      switch (i) {
        case 0:
        case 1:
          this.border = event.image;
          break;
        case 2:
        case 3:
          this.images.push(event.image);
          break;
      }
    },
    onUploadBtnClick() {
      document.getElementById("upload").click();
    },
    onSaveBtnClick() {
      this.isSaveBtnLoading = true;

      let isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
      const _this = this;
      if (isIOS) {
        htmlToImage
          .toSvg(document.getElementById("photo-canvas"))
          .then(function(dataUrl) {
            var aDownloadLink = document.createElement("a");
            aDownloadLink.download = "canvas_image";
            aDownloadLink.href = dataUrl;
            aDownloadLink.target = "_blank";
            aDownloadLink.click();
            _this.isSaveBtnLoading = false;
          })
          .catch(function(e) {
            console.log(e);
            // this.err = e;
            _this.isSaveBtnLoading = false;
          });
      } else {
        htmlToImage
          .toPng(document.getElementById("photo-canvas"))
          .then(function(dataUrl) {
            var aDownloadLink = document.createElement("a");
            aDownloadLink.download = "canvas_image";
            aDownloadLink.href = dataUrl;
            aDownloadLink.target = "_blank";
            aDownloadLink.click();
            _this.isSaveBtnLoading = false;
          })
          .catch(function(e) {
            console.log(e);
            // this.err = e;
            _this.isSaveBtnLoading = false;
          });
      }
    },
  },
  mounted: function() {},
  data: () => ({
    //
    images: [],
    border: "border-1.png",
    userImage: null,
    templates: [],
    isSaveBtnLoading: false,
    err: "",

    moveable: {
      draggable: true,
      throttleDrag: 0,
      resizable: false,
      throttleResize: 1,
      keepRatio: true,
      scalable: true,
      throttleScale: 0,
      rotatable: true,
      throttleRotate: 0,
      pinchable: true, // ["draggable", "resizable", "scalable", "rotatable"]
      origin: false,
    },
  }),
};
</script>
