<template>
  <div id="videoGallery" class="justify-center align-center mb-6">
    <v-row>
      <v-col :cols="isMobilePhone ? 12 : 12">
        <v-responsive class="videoContainer" :aspect-ratio="16 / 9">
          <youtube
            :video-id="currentVideo.videoId"
            ref="youtube"
            :player-vars="{ autoplay: 0 }"
            :width="'100%'"
            :height="'100%'"
          />
        </v-responsive>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            {{ currentVideo.title }}
          </v-list-item-title>
          <!-- <v-list-item-subtitle>
            {{ currentVideo.date }}
          </v-list-item-subtitle> -->
        </v-list-item-content>
        <v-divider />
        <!-- {{ tx }} -->
      </v-col>

      <v-col :cols="isMobilePhone ? 12 : 12">
        <!-- Desktop -->
        <v-sheet
          class="mx-auto pa-0"
          style="background-color: transparent;"
          v-if="!isMobilePhone"
        >
          <v-slide-group
            v-model="model"
            class="pa-0"
            active-class="success"
            show-arrows
            style="background-color: transparent;"
          >
            <v-slide-item
              v-for="(item, i) in videoList"
              :key="i"
              style="margin-right: 12px"
            >
              <v-card
                elevation="0"
                color="transparent"
                @click.stop="onItemClick(item)"
                width="250"
              >
                <v-responsive class="videoContainer" :aspect-ratio="16 / 9">
                  <v-img
                    :src="item.thumbnail"
                    max-width="250"
                    height="100%"
                    cover
                    class="grey darken-4"
                  ></v-img>
                </v-responsive>
                <div class="pa-1">
                  <v-list-item-content>
                    <v-list-item-subtitle class=" mb-1">
                      {{ item.title.includes("#") ? "優異作品" : item.title }}
                    </v-list-item-subtitle>
                    <!-- <v-list-item-subtitle>
                      {{ item.date }}
                    </v-list-item-subtitle> -->
                  </v-list-item-content>
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
        <!-- Mobile -->
        <div v-else v-for="(item, i) in videoList" :key="i">
          <v-card
            elevation="0"
            color="transparent"
            @click.stop="onItemClick(item)"
            v-if="currentVideo.url != item.url"
          >
            <v-img
              :src="item.thumbnail"
              :max-height="250"
              contain
              class="grey darken-4"
            ></v-img>
            <div class="pa-2">
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  {{ item.title }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle>
                  {{ item.date }}
                </v-list-item-subtitle> -->
              </v-list-item-content>
            </div>
          </v-card>
        </div>
        <!--  -->
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.videoContainer {
  // background-color: red;
}
.list {
  overflow: scroll;
}
</style>

<script>
import BackButton from "@/components/atoms/BackButton.vue";
import axios from "axios";

export default {
  components: {
    BackButton,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      tx: "",
      videoList: [],
      currentVideo: "",
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  created: async function() {
    const response = [
      {
        title: "2021及2022保護兒童計劃回顧",
        url: "https://youtu.be/7n1T1rJshA8",
        date: "13-6-2023"
      },
      {
        title: "冠軍 - 保良局蕭漢森小學",
        url: "https://youtu.be/F0sxJ89tS3g",
        date: "28-11-2021",
      },
      {
        title: "亞軍 - 消防處調派及通訊組",
        url: "https://youtu.be/5cxuUPYXT-k",
        date: "28-11-2021",
      },
      {
        title: "季軍 - 香港路路德會増城兆霖學校",
        url: "https://youtu.be/_MO_MAZplRA",
        date: "28-11-2021",
      },
      {
        title: "最佳服裝獎",
        url: "https://youtu.be/C8YrnuX6QFY",
        date: "28-11-2021",
      },
      {
        title: "最佳⾳樂獎",
        url: "https://youtu.be/XrtWaPdB53M",
        date: "28-11-2021",
      },
      {
        title: "最搞笑奬",
        url: "https://youtu.be/y6mmQnHqlbg",
        date: "28-11-2021",
      },
      {
        title: "最具創意奬",
        url: "https://youtu.be/IFSIDbZJVO4",
        date: "28-11-2021",
      },
      {
        title: "最多人參與獎",
        url: "https://youtu.be/7_OhwQrmXKA",
        date: "28-11-2021",
      },
      {
        title: "最合拍家庭⼤獎",
        url: "https://youtu.be/l0GS3c8mE2s",
        date: "28-11-2021",
      },
      { title: "#10", url: "https://youtu.be/vNP1CBloakc", date: "28-11-2021" },
      { title: "#11", url: "https://youtu.be/-XUn_EZGmNU", date: "28-11-2021" },
      { title: "#12", url: "https://youtu.be/0hEKJI4M1Js", date: "28-11-2021" },
      { title: "#13", url: "https://youtu.be/IjtSitGJVqU", date: "28-11-2021" },
      { title: "#14", url: "https://youtu.be/qfIHjeqzntk", date: "28-11-2021" },
      { title: "#15", url: "https://youtu.be/4V6wYhhC-k4", date: "28-11-2021" },
      { title: "#16", url: "https://youtu.be/t1hQk_7waLs", date: "28-11-2021" },
      { title: "#17", url: "https://youtu.be/FJMPd3SJQQQ", date: "28-11-2021" },
      { title: "#18", url: "https://youtu.be/6QmvBIVcCYA", date: "28-11-2021" },
      { title: "#19", url: "https://youtu.be/hI5oGXK2Wsk", date: "28-11-2021" },
      { title: "#20", url: "https://youtu.be/bGQ_ZelGtfc", date: "28-11-2021" },
      { title: "#21", url: "https://youtu.be/ZGQZfYGEMzc", date: "28-11-2021" },
      { title: "#22", url: "https://youtu.be/exCvN6EMNLA", date: "28-11-2021" },
      { title: "#23", url: "https://youtu.be/tVJt2ZbJ4qE", date: "28-11-2021" },
      { title: "#24", url: "https://youtu.be/omer4QntwU8", date: "28-11-2021" },
      { title: "#25", url: "https://youtu.be/3VNnfWzAibk", date: "28-11-2021" },
      { title: "#26", url: "https://youtu.be/TLh2sJQFRAw", date: "28-11-2021" },
      { title: "#27", url: "https://youtu.be/4qGlz83A-X4", date: "28-11-2021" },
      { title: "#28", url: "https://youtu.be/punotmEsaro", date: "28-11-2021" },
      { title: "#29", url: "https://youtu.be/NErYStRwZ6s", date: "28-11-2021" },
    ];

    let videoList = [];

    for (let data of response) {
      // console.log("====")
      // console.log(await this.getTitle(data.url))
      let details = await this.getVideoDetails(data.url);
      videoList.push({
        ...data,
        videoId: this.$youtube.getIdFromUrl(data.url),
        title: details.title,
        thumbnail: details.thumbnail_url,
        // viewCount: this.getViewCount(url),
        // title,
      });
    }

    this.videoList = videoList;
    this.currentVideo = videoList[0];
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },

  methods: {
    onItemClick(item) {
      // alert("???")
      this.currentVideo = item;
      this.tx = item;

      // player.loadVideoById(item.videoId)
      // this.$youtube.loadVideoById(item.videoId)
      this.player.loadVideoById(item.videoId);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    },
    onResize() {
      this.windowSize = {
        x: window.innerWidth,
        y: document.documentElement.scrollHeight,
      };
    },
    getVideoDetails(videoUrl) {
      return new Promise((resolve, reject) => {
        axios
          .get("https://noembed.com/embed", {
            params: {
              url: videoUrl,
            },
          })
          .then((res) => {
            // console.log(res.data);
            resolve(res.data);
          })
          .catch(function(error) {
            resolve(error);
          });
      });
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>
