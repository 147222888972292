<template>
  <div>
    <BackButton />
    <SessionTitle class="mb-6" :text="$t('home.activities')" color="#005385" />
    <div>
      <v-row>
        <v-col
          :cols="isMobile ? 12 : 4"
          v-for="item in items"
          :key="item.id"
          class="d-flex flex-column mb-4"
        >
          <v-card
            :tile="true"
            class="text-left flex d-flex flex-column"
            style="border-radius: 0 !important; height: 100%"
          >
            <div style="height: 200px">
              <v-img
                :src="getImage(item.img)"
                class="card-image"
                height="200px"
                cover
              ></v-img>
            </div>
            <v-card-title class="text-wrap text-center h-title px-8">
              <!-- {{
                $i18n.locale == "en"
                  ? item.title.en
                  : $i18n.locale == "tc"
                  ? item.title.tc
                  : item.title.sc
              }} -->

              <div
                class="h-title"
                style="width: 100%"
                v-for="title in ($i18n.locale == 'en'
                  ? item.title.en
                  : $i18n.locale == 'tc'
                  ? item.title.tc
                  : item.title.sc
                ).split('<br>')"
              >
                {{ title }}
              </div>
            </v-card-title>
            <v-card-text class="h-body text-wrap limit-line-3">
              {{
                $i18n.locale == "en"
                  ? item.content.en
                  : $i18n.locale == "tc"
                  ? item.content.tc
                  : item.content.sc
              }}
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                rounded
                small
                outlined
                color="black"
                dark
                class="c-font-family"
                style="border-width: 3px"
                @click="
                  $router.push({
                    name:
                      item.title.en?.includes(
                        'Review for Child Protection Campaign 2021 and 2022'
                      ) || item?.id == 501
                        ? 'VideoGallery'
                        : 'ActivityDetails',
                    query: { id: item.id },
                  })
                "
              >
                <div class="c-font-family">{{ $t("common.more") }} >></div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-card__title {
  justify-content: center !important;
}
// .v-sheet.v-card:not(.v-sheet--outlined) {
//   box-shadow: none;
// }
// .v-card__subtitle,
// .v-card__text,
// .v-card__title {
//   padding-left: 0px;
// }
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-sheet.v-card {
  border-radius: 10px !important;
}

.limit-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.limit-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.limit-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

<script>
// @ is an alias to /src
import BackButton from "@/components/atoms/BackButton.vue";
import SessionTitle from "../atoms/SessionTitle.vue";
import config from "/config";
import axios from "axios";

export default {
  name: "ActivityDetails",
  props: {
    id: Number,
  },
  components: {
    BackButton,
    SessionTitle,
  },
  created() {
    this.fetchData();
  },
  methods: {
    getImage(item) {
      if (this.$i18n.locale == "en") {
        return item.en;
      } else if (this.$i18n.locale == "tc") {
        return item.tc;
      } else {
        return item.sc;
      }
    },
    onResize() {
      this.width = window.innerWidth;
    },
    fetchData() {
      axios
        .get(config.wordpressUrl + "/activity_and_event?order=asc&per_page=100")
        .then((response) => {
          const id = this.$route.query.id;
          console.log("activity_and_event", response.data);

          let activity = [];

          const data = response.data;
          if (data) {
            data.map((item, index) => {
              activity.push({
                title: this.getFieldFromResp(item, "title"),
                content: this.getFieldFromResp(item, "description"),
                img: this.getImageFromResp(item, "image"),
                html: this.getFieldFromResp(item, "html"),
                sequence: parseInt(item.acf?.sequence ?? "0"),
                id: index,
              });
            });

            const index = activity.findIndex(
              (item) =>
                item?.title?.en?.includes(
                  "A Look Back to 2022 Child Protection Campaign"
                ) || item?.id == 501
            );
            if (index !== -1) {
              const item = activity.splice(index, 1)[0];
              activity.push(item);
            }
            activity = activity?.sort((a, b) => a.sequence - b.sequence);

            this.items = activity;
          }
        });
    },
  },
  data: () => ({
    items: [],
    width: window.innerWidth,
  }),
  mounted() {
    window.addEventListener("resize", this.onResize);
    let path = this.$route.fullPath;
    let tmp = path.substr(path.indexOf("=") + 1, path.length);
    this.item = this.items[parseInt(tmp)];
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" scoped>
.limit-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.limit-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.limit-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
