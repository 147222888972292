export const cybercrime = {
  title: {
    en: "Cybercrime",
    tc: "網絡罪案",
    sc: "网络罪案",
  },
  img: "hh2.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Cybercrime",
        tc: "網絡罪案",
        sc: "网络罪案",
      },
      title: {
        en: "Cybercrime",
        tc: "網絡罪案",
        sc: "网络罪案",
      },
      img: "witness-kids-4.png",
      aspectRatio: 336 / 271,
      showTips: true,
      points: [
        {
          en: "Tell a trusted adult!",
          tc: "把事情告知你信任的成年人。",
          sc: "把事情告知你信任的成年人。",
        },
        {
          en: "Do not share your photos or videos online with any strangers.",
          tc: "不要在網上分享相片或影片予任何陌生人。",
          sc: "不要在网上分享相片或影片予任何陌生人。",
        },
        {
          en:
            "Protect your data privacy. Do not disclose your personal particulars or sensitive data to untrusted person!",
          tc: "保障個人資料私隱。不要向你不信任的人士披露個人資料或敏感資料。",
          sc: "保障个人资料私隐。不要向你不信任的人士披露个人资料或敏感资料。",
        },
        {
          en:
            "Always secure your applications/ online accounts with strong passwords and change them regularly.",
          tc:
            "時刻使用高強度密碼，並定期更改，以確保應用程式／網上帳戶的安全。",
          sc:
            "时刻使用高强度密码，并定期更改，以确保应用程式／网上账户的安全。",
        },
        {
          en:
            "If becomes a victim, trusted adult will report the case to police with you.",
          tc: "如果不幸成為受害人，你信任的成年人會陪同你向警方舉報。",
          sc: "如果不幸成为受害人，你信任的成年人会陪同你向警方举报。",
        },
      ],
    },
  ],
};
