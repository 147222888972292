import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueYoutube from 'vue-youtube'
import i18n from './language/i18n'
import VueSnip from 'vue-snip'
import VuePdfApp from "vue-pdf-app";
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import VueMeta from 'vue-meta'
// import Chat from 'vue-beautiful-chat'
import Chat from './lib/vue-beautiful-chat.umd.min.js'
import vmodal from 'vue-js-modal'

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(VueSnip)
Vue.component("vue-pdf-app", VuePdfApp);
Vue.use(VueVideoPlayer)
Vue.use(VueMeta)
Vue.config.silent = true
Vue.use(Chat)
Vue.use(vmodal, {dialog: true})

// Global Mixins
import { utilityMixins } from './utils/Utility.js'
Vue.mixin(utilityMixins)

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

if(module.hot){
  if(store.state.lang != null){
    i18n.locale = store.state.lang
  } else {
    i18n.locale = 'tc'
  }
}
