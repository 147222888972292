<template>
  <v-container>
    <div>
      <!--  -->
      <v-row>
      <v-col :cols="isMobile ? 12 : 4" v-for="(i, index) in pdf" :key="index">
        <div>
          <v-card class="mt-4 d-flex flex-column" height="100%">
            <v-img :src="filterValue(i.image)" cover></v-img>

            <v-card-title class="justify-center">
              {{ filterValue(i.title) }}
            </v-card-title>

            <v-spacer></v-spacer>
            <v-card-actions class="justify-end text-right pt-1">
              <v-btn
                text
                style="color: blue"
                class="btn"
                @click="popUp(filterValue(i.title), filterValue(i.filePath))"
              >
                <div style="font-size: 14px">
                  {{ $t("common.more") }}
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" scrollable :width="isMobile ? '90vw' : '85vw'">
      <v-card :height="isMobile ? '50vh' : '85vh'">
        <v-card-title>
          <v-row>
            <v-col cols="10">
              {{ title }}
            </v-col>
            <v-col cols="2">
              <v-btn
                absolute
                top
                right
                icon
                color="red"
                class="btn"
                @click="closeDialog()"
              >
                <v-icon> mdi-close-circle </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <vue-pdf-app style="height: 100%;" :pdf="filePath"></vue-pdf-app>
      </v-card>
    </v-dialog>
    </div>
  </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import Card from "@/components/organisms/Card.vue";
import BackButton from "@/components/atoms/BackButton.vue";
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "VideoPage",
  components: {
    BackButton,
    Card,
  },
  computed: {},
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    selectedItem: null,
    dialog: false,
    title: "",
    pdf: [],
  }),
  created() {
    console.log(this.item)
    this.pdf = this.item.pdf
    // this.data = this.getData(this.$route.params.id);
  },
  updated() {
    // this.data = this.getData(this.$route.params.id);
  },
  methods: {
    popUp(title, filePath) {
      this.dialog = !this.dialog;
      this.title = title;
      this.filePath = filePath;
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    getText(item) {
      if (this.$i18n.locale == "en") {
        return item.en;
      } else if (this.$i18n.locale == "tc") {
        return item.tc;
      } else {
        return item.sc;
      }
    },
  },
};
</script>
