<template>
  <div class="home">
    <!-- <BackButton class="mb-3" /> -->
    <ExpansionPanels
      :items="items"
      :open="open"
      :recommend="recommend"
      :showDescn="false"
      v-if="open != null"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-card {
  border-radius: 20px;
  justify-content: center;
}
</style>

<script>
// @ is an alias to /src
import BackButton from "@/components/atoms/BackButton.vue";
import ExpansionPanels from "@/components/atoms/ExpansionPanels.vue";
import config from "../../../../config";
import axios from "axios";

export default {
  name: "TeensHome",
  components: {
    BackButton,
    ExpansionPanels,
  },

  methods: {
    setData(val) {
      this.open = val == undefined ? -1 : val;
    },
    fetchCategories(url, title) {
      axios.get(config.wordpressUrl + url).then((response) => {
        const data = response.data;

        if (data) {
          let categories = [];

          data.map((item) => {
            categories.push(this.mapDataToChildren(item));
          });
          this.items[this.getTitleIndex(title)].children = categories;
        }
      });
    },

    getTitleIndex(title) {
      let i = undefined;
      this.items.forEach((item, _i) => {
        if (item.title == title) {
          i = _i;
        }
      });
      return i;
    },
    mapDataToChildren(item) {
      return {
        title: this.getFieldFromResp(item, "title"),
        image: this.getImageFromResp(item),
        id: item.id,
      };
    },
  },
  beforeRouteEnter(to, from, next) {
    // var path = from.path;
    next((vm) => {
      vm.setData(from.meta.openItem);
    });
  },
  created() {
    this.fetchCategories("/teens_video_category?order=asc&per_page=100", "common.stayAlert");
    this.fetchCategories("/teens_stayinfo_cat?order=asc&per_page=100", "common.legally");

  },
  data: () => ({
    open: null,
    items: [
      {
        title: "common.stayAlert",
        image: require("@/assets/images/smart_guard.png"),
        content: "Smart Guard...",
        route: "TeensVideoPage",
        children: [
          {
            title: "stayAlert.cybercrime",
            image: require("@/assets/images/teens/cybercrime.png"),
            id: 1,
          },
          {
            title: "stayAlert.fraud",
            image: require("@/assets/images/teens/fraud.png"),
            id: 2,
          },
          {
            title: "stayAlert.physical_harm",
            image: require("@/assets/images/teens/physical_harm.png"),
            id: 3,
          },
          {
            title: "stayAlert.sexual_violence",
            image: require("@/assets/images/teens/sexual_violence.png"),
            id: 4,
          },
          {
            title: "stayAlert.media_interview",
            image: require("@/assets/images/teens/media_interview.png"),
            id: 5,
          },
        ],
      },
      {
        title: "common.legally",
        image: require("@/assets/images/stay_informed_legally.png"),
        content: "Stay Informed Legally...",
        route: "TeensStayInformed",
        children: [
          {
            title: "legally.cruelty_child",
            image: require("@/assets/images/teens/cruelty_child.png"),
            id: 1,
          },
          {
            title: "legally.triad",
            image: require("@/assets/images/teens/triad.png"),
            id: 2,
          },
          {
            title: "legally.child_porn",
            image: require("@/assets/images/teens/child _porn.png"),
            id: 3,
          },
          // {
          //   title: "legally.drug",
          //   image: require("@/assets/images/teens/drug.png"),
          // },
          {
            title: "legally.sexual_offences",
            image: require("@/assets/images/teens/sex_abuse.png"),
            id: 4,
          },
        ],
      },
      {
        title: "common.chill",
        image: require("@/assets/images/chill_out_corner.png"),
        content: "Chill Out...",
        route: "TeensPhotoFrame",
        children: [
          {
            title: "chill.photoFrame",
            image: require("@/assets/images/photo_frame.png"),
            btnText: "common.playNow",
          },
        ],
      },
    ],
    recommend: [
      {
        image: require("@/assets/images/linking/teens/netiquette.jpg"),
        desc: {
          en: "Netiquette",
          tc: "網絡禮儀",
          sc: "网络礼仪",
        },
        url: {
          en:
            "https://cyberdefender.hk/en-us/it-basics/?playlist=32f76ab&video=35e41a9",
          tc:
            "https://cyberdefender.hk/it-basics?playlist=32f76ab&video=35e41a9",
          sc:
            "https://cyberdefender.hk/zh-cn/it-basics/?playlist=32f76ab&video=35e41a9",
        },
      },
      {
        image: require("@/assets/images/linking/teens/literacy.jpg"),
        desc: {
          en: "【Digital Literacy Series】（English）七. 互相尊重",
          tc: "【數碼素養系列】（廣東話）七. 互相尊重",
          sc: "【数字素养系列】（普通话）七.互相尊重",
        },
        url: {
          en: "https://www.youtube.com/watch?v=K2A9kwZE5lQ&t=1s",
          tc: "https://www.youtube.com/watch?v=stpUhE39cWQ&t=1s",
          sc: "https://www.youtube.com/watch?v=k-bJhxVKvl8&t=5s",
        },
      },
      {
        image: require("@/assets/images/linking/teens/scam.jpg"),
        desc: {
          en: "What is compensated dating scam?",
          tc: "什麼是援交騙案？",
          sc: "什么是援交骗案？",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/compensated_dating/",
          tc: "https://cyberdefender.hk/compensated_dating/",
          sc: "https://cyberdefender.hk/zh-cn/compensated_dating/",
        },
      },
      {
        image: require("@/assets/images/linking/teens/guidelines.jpg"),
        desc: {
          en: "Internet Guidelines for teenagers",
          tc: "給年青人和兒童的上網指引",
          sc: "给年青人和儿童的上网指引",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/guideline_for_teens/",
          tc: "https://cyberdefender.hk/guideline_for_teens/",
          sc: "https://cyberdefender.hk/zh-cn/guideline_for_teens/",
        },
      },
      {
        image: require("@/assets/images/linking/teens/cyber-bullying.jpg"),
        desc: {
          en: "Cyber-bullying",
          tc: "網絡欺淩",
          sc: "网络欺凌",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/cyberbullying/",
          tc: "https://cyberdefender.hk/cyberbullying/",
          sc: "https://cyberdefender.hk/zh-cn/cyberbullying/",
        },
      },
      {
        image: require("@/assets/images/linking/teens/online_doxxing.png"),
        desc: {
          en: "What is “online doxxing”?",
          tc: "什麼是「網上起底」？",
          sc: "什么是「网上起底」？",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/doxxing/",
          tc: "https://cyberdefender.hk/doxxing/",
          sc: "https://cyberdefender.hk/zh-cn/doxxing/",
        },
      },
      {
        image: require("@/assets/images/linking/teens/device.jpg"),
        desc: {
          en: "Secure Your Device",
          tc: "保護你的裝置",
          sc: "保护你的装置",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/secure-your-device/",
          tc: "https://cyberdefender.hk/secure-your-device/",
          sc: "https://cyberdefender.hk/zh-cn/secure-your-device/",
        },
      },
      {
        image: require("@/assets/images/linking/teens/naked_chat.jpg"),
        desc: {
          en: "What is Naked Chat Blackmail？",
          tc: "什麼是裸聊勒索？",
          sc: "什么是裸聊勒索？",
        },
        url: {
          en:
            "https://cyberdefender.hk/naked_chat/?playlist=f13d8ba&video=a358f20",
          tc:
            "https://cyberdefender.hk/en-us/naked_chat/?playlist=f13d8ba&video=a358f20",
          sc:
            "https://cyberdefender.hk/zh-cn/naked_chat/?playlist=f13d8ba&video=a358f20",
        },
      },
      {
        image: require("@/assets/images/linking/teens/employ_fraud.jpg"),
        desc: {
          en: "What is Online Employment Fraud？",
          tc: "什麼是網上求職騙案？",
          sc: "什么是网上求职骗案？",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/online_employment_fraud/",
          tc: "https://cyberdefender.hk/online_employment_fraud/",
          sc: "https://cyberdefender.hk/zh-cn/online_employment_fraud/",
        },
      },
      {
        image: require("@/assets/images/linking/teens/employ_fraud.jpg"),
        desc: {
          en: "Employment Fraud",
          tc: "求職騙案",
          sc: "求职骗案",
        },
        url: {
          en: "https://www.adcc.gov.hk/zh-hk/videos-detail.php?post=v00051",
          tc: "https://www.adcc.gov.hk/zh-hk/videos-detail.php?post=v00051",
          sc: "https://www.adcc.gov.hk/zh-hk/videos-detail.php?post=v00051",
        },
      },
      {
        image: require("@/assets/images/linking/teens/mind.jpg"),
        desc: {
          en: "Victims' mind",
          tc: "受害者心態",
          sc: "受害者心态",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/victim_mentality/",
          tc: "https://cyberdefender.hk/victim_mentality/",
          sc: "https://cyberdefender.hk/zh-cn/victim_mentality/",
        },
      },
      {
        image: require("@/assets/images/linking/teens/sexual_violence.png"),
        desc: {
          en: "What's Sexual Violence ?",
          tc: "何謂性暴力?",
          sc: "何谓性暴力？",
        },
        url: {
          en: "https://www.police.gov.hk/svi/en/definitions.html",
          tc: "https://www.police.gov.hk/svi/tc/definitions.html",
          sc: "https://www.police.gov.hk/svi/sc/definitions.html",
        },
      },
    ],
  }),
};
</script>
