<template>
  <p
    :class="isMobile ? 'text-h6' : 'text-h5'"
    class="text-left mb-0 c-title"
    :style="{ color: color != undefined ? color : '#175082' }"
  >
    {{ text }}
  </p>
</template>

<script>
// @ is an alias to /src
export default {
  name: "SessionTitle",
  props: {
    text: String,
    color: String,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({
    //
  }),
};
</script>
