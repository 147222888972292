<template>
  <div class="splash" :class="{desktop: !isMobile, mobile: isMobile}">
    <div class="overlay"></div>
    <div class="wrapper" :style="wrapperStyle" >
      <div class="header-img-container">
        <v-img 
          v-if="headerText"
          :src="require(success? '../../../assets/images/game/levelComplete.png':'../../../assets/images/game/timesUp.png')"
          class="box-header-img text-center align-center"
          contain>
          <div :class="success? 'success-header-text':'fail-header-text'">{{$t(headerText)}}</div>
        </v-img>
      </div>
      <v-img :src="require('../../../assets/images/game/Box.png')">
        <div class="content">
          <div class="title-container" :style="!headerText?'top:0':''">
            <div style="display: flex; flex-direction: column; width:100%; height:100%">
              <div v-if="title" class="align-center title">
                {{$t(title)}}
              </div>
              <div class="align-center subtitle">
                {{$t(subtitle)}}
              </div>
            </div>
          </div> 
          <v-row>
            <v-col cols="6" class="align-center">
              <v-img class="imageButton align-center text-center" :src="require('../../../assets/images/game/green_button.png')" @click="leftButtonAction" contain>
                <div class="button-text">{{$t(leftButtonText)}}</div>
              </v-img>
            </v-col>
            <v-col cols="6" class="align-center">          
              <v-img class="imageButton align-center text-center" :src="require('../../../assets/images/game/red_button.png')" @click="rightButtonAction" contain>
                <div class="button-text">{{$t(rightButtonText)}}</div>
              </v-img>
            </v-col>
          </v-row>
        </div>
      </v-img>
    </div>
  </div>
</template>

<script>

export default {
  name: "GameDialog",
  props: {
    title: String,
    subtitle: String,
    headerText: String,
    success: Boolean,
    leftButtonText: String,
    rightButtonText: String,
    leftButtonAction: Function,
    rightButtonAction: Function,
    wrapperStyle: {
      type: Object,
      default: ()=>{}
    }
  },
  data:{}
}
</script>
<style lang="scss" scoped>
  // UI
  .splash .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    //background-color: rgba(0, 0, 0, 0.6);
  }

  .splash .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 80%;
    height: 80%;
    margin: auto;
    color: #fff;
    text-align: center; // 置中
  }

  .wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    text-align: center; // 置中
    //padding: 2em;
    z-index: 100;
  }

  .desktop .wrapper{
    width: 90%;
  }

  .mobile .wrapper{
    width: 90%;
    top: 9%;
  }


  .splash .content .title {
    //padding: 0.3em;
    margin: auto;
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 2rem;
  }

  .splash .content .subtitle {
    //padding: 0.3em;
    margin: auto;
    // position: absolute;
    // top: 35%;
    // left: 0;
    // right: 0;
  }

  .splash .content .score {
    padding: 0.5em;
  }

  .imageButton {
    width: 45%;
    bottom: 0;
    position: absolute;
    color: white;
  }


  .box-header-img{
    z-index: 1;
    left: 50%;
    width: 50%;
    position: absolute;
    color: white;
    transform: translate(-50%, -30%);
  }

  .header-img-container{
    position: relative;
  }

  .fail-header-text{
    padding-left: 5%;
    font-size: 2rem
  }

  .success-header-text{
    padding-top: 9%;
    font-size: 2rem
  }

  .bg{
    position: relative;
  }

  .mobile .button-text{
    //font-size: 1.4rem;
    font-size: 3vw;
  }

  .desktop .button-text{
    font-size: 2rem;
  }

  .title{
    font-size: 2rem !important;
    font-weight: 400;
  }

  .subtitle{
    font-size: 1.2rem !important;
    font-weight: 400;
  }

  .mobile .title{
    font-size: 3.5vw !important;
  }

  .mobile .subtitle{
    font-size: 2.5vw !important;
  }

  .title-container{
    position: absolute;
    left: 0;
    right: 0;
    top: 11.5%;
    height: 50%;
  }

  .mobile .button-text{
    font-size: 3vw;
  }


  .mobile .success-header-text{
      font-size: 3vw
    }  
    
  .mobile .fail-header-text{
      font-size: 3vw
    }

</style>