import { physicalAbuse } from "./physicalAbuse";
import { cybercrime } from "./cybercrime";
import { sexualAbuse } from "./sexualAbuse";
import { cyberbullying } from "./cyberbullying";
import { victim } from "./victim";
import { witness } from "./witness";

const flowMap = [
  physicalAbuse,
  cybercrime,
  sexualAbuse,
  cyberbullying,
  {
    title: {
      en: "What to do?",
      tc: "點算好?",
      sc: "怎么办",
    },
    img: "whatToDo.png",
    col: 6,
    btns: [
      {
        tx: "common.go",
      },
    ],
    children: [
      {
        style: { display: "none" },
      },
      victim,
      witness,
    ],
  },
];

export default flowMap;
