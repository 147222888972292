import { render, staticRenderFns } from "./ImageAndDescWithShowMoreBtnPage.vue?vue&type=template&id=8626fe58&scoped=true&"
import script from "./ImageAndDescWithShowMoreBtnPage.vue?vue&type=script&lang=js&"
export * from "./ImageAndDescWithShowMoreBtnPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8626fe58",
  null
  
)

export default component.exports