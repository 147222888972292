var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BackButton'),_c('SessionTitle',{staticClass:"mb-6",attrs:{"text":_vm.$t('home.activities'),"color":"#005385"}}),_c('div',[_c('v-row',_vm._l((_vm.items),function(item){return _c('v-col',{key:item.id,staticClass:"d-flex flex-column mb-4",attrs:{"cols":_vm.isMobile ? 12 : 4}},[_c('v-card',{staticClass:"text-left flex d-flex flex-column",staticStyle:{"border-radius":"0 !important","height":"100%"},attrs:{"tile":true}},[_c('div',{staticStyle:{"height":"200px"}},[_c('v-img',{staticClass:"card-image",attrs:{"src":_vm.getImage(item.img),"height":"200px","cover":""}})],1),_c('v-card-title',{staticClass:"text-wrap text-center h-title px-8"},_vm._l(((_vm.$i18n.locale == 'en'
                ? item.title.en
                : _vm.$i18n.locale == 'tc'
                ? item.title.tc
                : item.title.sc
              ).split('<br>')),function(title){return _c('div',{staticClass:"h-title",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(title)+" ")])}),0),_c('v-card-text',{staticClass:"h-body text-wrap limit-line-3"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? item.content.en : _vm.$i18n.locale == "tc" ? item.content.tc : item.content.sc)+" ")]),_c('v-spacer'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"c-font-family",staticStyle:{"border-width":"3px"},attrs:{"rounded":"","small":"","outlined":"","color":"black","dark":""},on:{"click":function($event){_vm.$router.push({
                  name:
                    item.title.en?.includes(
                      'Review for Child Protection Campaign 2021 and 2022'
                    ) || item?.id == 501
                      ? 'VideoGallery'
                      : 'ActivityDetails',
                  query: { id: item.id },
                })}}},[_c('div',{staticClass:"c-font-family"},[_vm._v(_vm._s(_vm.$t("common.more"))+" >>")])])],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }