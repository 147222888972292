<template>
  <div class="mb-3">
    <v-row>
      <v-col>
        <v-btn outlined rounded color="#000" @click="onBackBtnClick" small>
          <v-icon size="16">mdi-chevron-left</v-icon>
          {{ $t("common.back") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
button {
  background-color: #fff;
}
</style>

<script>
// @ is an alias to /src
export default {
  name: "BackButton",
  props: {
    text: {
      type: String,
      default: "Back",
    },
    color: String,
    onBtnClick: {
      default: null,
      type: Function | null,
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({}),
  methods: {
    onBackBtnClick() {
      if (this.onBtnClick == null) {
        this.$router.go(-1);
      } else {
        this.onBtnClick();
      }
    },
  },
};
</script>
