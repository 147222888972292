var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"d-flex flex-column mt-4",attrs:{"height":"100%"}},[_c('v-img',{attrs:{"aspect-ratio":1.7,"src":_vm.filterValue(_vm.items.image),"width":"100%","cover":""}}),_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"min-height":"70px"}},[_c('v-card-title',{staticClass:"text-center py-0",style:({
        'font-size': _vm.isMobile ? '0.875rem' : '1.25rem',
        'line-height': _vm.isMobile ? '1.25rem' : '1.35rem',
      })},[_vm._v(" "+_vm._s(_vm.$t(_vm.filterValue(_vm.items.title)))+" ")])],1),(_vm.isShowMore)?_c('v-card-actions',{staticClass:"justify-end text-right pt-1"},[_c('v-btn',{staticClass:"btn",staticStyle:{"color":"blue"},style:({ color: _vm.btnColor }),attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
          name: _vm.items.route,
          query: { id: _vm.items.id, item: _vm.items },
        })}}},[_c('div',{style:({ 'font-size': '14px' })},[_vm._v(" "+_vm._s(_vm.items.btnText != undefined ? _vm.$t(_vm.items.btnText) : _vm.$t("common.more"))+" ")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }