<template>
  <div>
    <!-- <BackButton class="mb-3" /> -->
    <ExpansionPanels
      :items="items"
      :recommend="recommend"
      :open="open"
      :showDescn="false"
      v-if="open != null"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-card {
  border-radius: 20px;
  justify-content: center;
}
</style>

<script>
// @ is an alias to /src
import BackButton from "@/components/atoms/BackButton.vue";
import ExpansionPanels from "@/components/atoms/ExpansionPanels.vue";
import config from "../../../../config";
import axios from "axios";
import _ from "lodash";

export default {
  name: "KidsHome",
  components: {
    BackButton,
    ExpansionPanels,
  },
  methods: {
    setData(val) {
      this.open = val == undefined ? -1 : val;
    },
    mapDataSetToChildren(dataSet) {
      let children = [];
      if (dataSet) {
        dataSet.map((item) => {
          children.push({
            title: this.getFieldFromResp(item, "title"),
            image: this.getImageFromResp(item),
            id: item.id,
          });
        });
      }
      return children;
    },
    fetchVideoCategories() {
      axios
        .get(
          config.wordpressUrl + "/kids_video_category?order=asc&per_page=100"
        )
        .then((response) => {
          const data = response.data;
          this.items[0].children = this.mapDataSetToChildren(data);
        });
    },
    fetchLawCategories() {
      axios
        .get(config.wordpressUrl + "/kids_learnmore_cat?order=asc&per_page=100")
        .then((response) => {
          const data = response.data;
          // move others to the end of the list
          let _data = _.cloneDeep(data)
          _data = [..._data.filter( x => !x?.acf?.title_en?.includes("Other") ), ..._data.filter( x => x?.acf?.title_en?.includes("Other") )]

          this.items[2].children = this.mapDataSetToChildren(_data);
        });
    },
    fetchMenuData() {
      this.fetchAdditionalMenus((catIndex, ageIndex, catItems, category) => {
        //
        console.log("catItems", catItems);
        // check is kids
        if (ageIndex == 0) {
          //
          let isNew = false;
          let itemsId = 0;
          let additionalCat = this.items.filter((item, i) => {
            if (item.additionalCatId == catIndex) {
              itemsId = i;
              return true;
            }
            return false;
          })[0];
          if (additionalCat == null) {
            isNew = true;
            additionalCat = {
              title: "additional cat " + (catIndex + 1),
              image: require("@/assets/images/learn_more.png"),
              content: "Learn More...",
              route: "KidsContentPage",
              children: [],
              additionalCatId: catIndex,
            };
          }

          let children = [...additionalCat.children];

          children = [...children, ...this.mapDataSetToChildren([category])];

          if (isNew) {
            additionalCat.children = children;
            this.items.push(additionalCat);
          } else {
            this.items[itemsId].children = children;
          }
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    // var path = from.path;
    next((vm) => {
      vm.setData(from.meta.openItem);
    });
  },
  created() {
    this.fetchVideoCategories();
    // this.fetchRecommend();
    this.fetchLawCategories();
    this.fetchMenuData();
  },
  data: () => ({
    open: null,
    items: [
      {
        title: "common.watchVideos",
        image: require("@/assets/images/smart_guard.png"),
        content: "Smart Guard...",
        route: "KidsVideoPage",
        children: [],
      },
      {
        title: "common.letsPlay",
        image: require("@/assets/images/chill_out_corner.png"),
        content: "Chill Out...",
        children: [
          {
            title: "chill.game1",
            content: "common.tbc",
            image: require("@/assets/images/game1.png"),
            btnText: "common.playNow",
            route: "KidsGame1",
          },
          {
            title: "chill.game2",
            content: "common.tbc",
            image: require("@/assets/images/game2.png"),
            btnText: "common.playNow",
            route: "KidsGame2",
          },
          {
            title: "chill.photoFrame",
            content: "common.tbc",
            image: require("@/assets/images/photo_frame.png"),
            btnText: "common.playNow",
            route: "KidsPhotoTheme",
          },
        ],
      },
      {
        title: "common.learnMore",
        image: require("@/assets/images/learn_more.png"),
        content: "Learn More...",
        route: "KidsLearnMore",
        children: [],
      },
    ],
    recommend: [],
  }),
};
</script>
