const Game1Data = [
  //level 1
  {
    showSplash: false,
    time: 120,
    cardWidthDivider: 7,
    containerWidth: "80%",
    cardPadding: "0 4% 4% 0",
    pairs: 5,
  },
  //level 2
  {
    showSplash: false,
    time: 120,
    containerWidth: "80%",
    cardWidthDivider: 9,
    cardPadding: "0 1% 1% 0",
    pairs: 10,
  },
  //level 3
  {
    showSplash: false,
    time: 120,
    containerWidth: "90%",
    cardWidthDivider: 12,
    cardPadding: "0 1% 1% 0",
    pairs: 15,
  },
];

export default Game1Data;
