<template>
  <div>
    <v-card v-for="(v, index) in videoList" :key="index" style="margin:20px;text-align: left;">

      <v-card-title style="font-size:24px;">
        {{v.title}}
      </v-card-title>

       <youtube :video-id="v.videoId" ref="youtube" width="100%" height="auto"/>

      <v-card-text style="font-size:14px; color:rgb(146, 142, 142);">
        {{v.desc}}
      </v-card-text>

       <v-card-actions style="justify-content: flex-end;" v-if="isShowMore">
          <v-btn
            text
            style="color:blue"
            class="btn"
            @click="$router.push('ContentPage')"
          >
          <p style="font-size:14px;">
            More...
          </p>
            
        </v-btn>
      </v-card-actions>
    
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
// .v-card__title {
//   justify-content: center !important;
// }
// .v-sheet.v-card:not(.v-sheet--outlined) {
//   box-shadow: none;
// }
// .v-card__subtitle,
// .v-card__text,
// .v-card__title {
//   padding-left: 0px;
// }
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
</style>

<script>
// @ is an alias to /src

export default {
  name: "YoutubeBanner",
  components: {
  },
  props:['videoList', 'isShowMore'],
  data: () => ({
     
  }),
};
</script>
