<template>
  <div>
    <BackButton />
    <SessionTitle class="mb-6" :text="$t('home.activities')" color="#757575" />
    <v-container v-if="item" id="act-details">
      <v-row>
        <v-col cols="12" class="d-flex flex-column">
          <v-img :src="item.img.en" max-width="800" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-h5">
          {{
            $i18n.locale == "en"
              ? item.title.en
              : $i18n.locale == "tc"
              ? item.title.tc
              : item.title.sc
          }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-body-1" style="text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);" >
          <span
            v-html="
              $i18n.locale == 'en'
                ? item.html.en
                : $i18n.locale == 'tc'
                ? item.html.tc
                : item.html.sc
            "
          ></span>
        </v-col>
      </v-row>
    </v-container>
    <div
      :class="isMobile ? '' : 'container'"
      class="mx-0 px-0 mt-3"
      fluid
      style="min-height: 100px; text-align: center"
      v-if="id == 0"
    >
      <DynamicMarquee />
    </div>
  </div>
</template>

<style>
.act-details img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}
</style>
<script>
// @ is an alias to /src
import BackButton from "@/components/atoms/BackButton.vue";
import SessionTitle from "../atoms/SessionTitle.vue";
import DynamicMarquee from "@/components/organisms/DynamicMarquee.vue";
import config from "/config";
import axios from "axios";
export default {
  name: "ActivityDetails",
  props: {},
  components: {
    BackButton,
    SessionTitle,
    DynamicMarquee,
  },
  created() {
    this.fetchData();
  },
  methods: {
    getText(item) {
      if (this.$i18n.locale == "en") {
        return item.en;
      } else if (this.$i18n.locale == "tc") {
        return item.tc;
      } else {
        return item.sc;
      }
    },
    fetchData() {
      axios
        .get(config.wordpressUrl + "/activity_and_event?order=asc&per_page=100")
        .then((response) => {
          console.log("activity_and_event", response.data);
          const id = this.$route.query.id;
          let activity = [];

          const data = response.data;
          if (data) {
            data.map((item, index) => {
              if (index == id)
                activity.push({
                  title: this.getFieldFromResp(item, "title"),
                  content: this.getFieldFromResp(item, "description"),
                  img: this.getImageFromResp(item, "image"),
                  html: this.getFieldFromResp(item, "html"),
                });
            });

            this.item = activity[0];
            console.log(this.item);
          }
        });
    },
  },
  data: () => ({
    dialog: false,
    id: -1,
    items: [],
    item: null,
  }),
  mounted() {
    let path = this.$route.fullPath;
  },
};
</script>
