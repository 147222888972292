var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$i18n.locale == 'en'
      ? 'c-en'
      : _vm.$i18n.locale == 'tc'
      ? 'c-tc'
      : _vm.$i18n.locale == 'sc'
      ? 'c-sc'
      : 'c-en'},[(_vm.$route.name != 'health')?_c('v-app',{style:({ 'background-color': _vm.bkgColor })},[_c('Header'),_c('v-container',[_c('v-row',[_c('v-col',[_c('router-view',{style:({ 'margin-bottom': _vm.isMobile ? '20px' : '0' })})],1)],1)],1),_c('Chatbot'),(_vm.isMobile)?_c('v-card',{staticClass:"overflow-hidden mx-auto",attrs:{"height":_vm.windowWidth * (_vm.isMobile ? 0.15 : 0.1)}},[_c('BottomNavigation')],1):_vm._e()],1):_c('p',[_vm._v("TEST")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }