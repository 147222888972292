<template>
  <HelpingHand :flowMap="flowMap"/>
</template>

<script>
// @ is an alias to /src
import HelpingHand from "@/components/templates/HelpingHand.vue"
import flowMap from "./flowMap/index";

// console.log('flowMap', flowMap)

export default {
  name: "TeensHelpingHand",
  components: {
    HelpingHand
  },
  data: () => ({
    flowMap: flowMap,
  }),
  watch: {
    locale: function (newLocale, oldLocale) {
      this.updateTitle();
    },
  },
};
</script>
