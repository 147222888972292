<template>
  <div>
    <v-main class="pb-0">
      <AgeBar />
      <ContentHeader />

      <v-container
        v-if="!['Home'].includes(currentRouteName)"
        :style="{
          position: [
            'KidsHelpingHand',
            'TeensHelpingHand',
            'AdultHelpingHand',
          ].includes(currentRouteName)
            ? 'absolute'
            : 'relative',
          'z-index': '5',
        }"
      >
        <div class="text-right">
          <v-btn
            color="blue-grey darken-1"
            class="white--text"
            @click="share()"
          >
            {{ $t("common.share") }}
            <v-icon right dark> mdi-share </v-icon>
          </v-btn>
        </div>
        <v-snackbar v-model="snackbar" :timeout="2000">
          {{ $t("common.copiedUrl") }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              {{ $t("common.close") }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>

      <div v-if="['Home'].includes(currentRouteName)">
        <v-img
          v-if="isMobile && isRunningEventEnd()"
          :src="require('@/assets/images/home_header_bkg.png')"
          class="d-flex align-center justify-center"
          style="margin: auto"
          cover        
        />
        <BaseCarousel
          v-if="showCarousel"
          :isMobile="!isMobile"
          :desktopItems="desktoplItems"
          :mobileItems="mobileItems"
          :interval="4000"
        />
        <template v-if="!showCarousel">
          <v-img
            v-if="isMobile && !isRunningEventEnd()"
            :src="bannerBg[2].text"
            width="100%"
            :aspect-ratio="4 / 6"
            style="margin: auto; margin-bottom: -20px"
            cover
            aria-label="img"
          >
            <!-- <div
              class="d-flex justify-center"
              style="position: absolute; bottom: 15px; width: 100%; left: 0"
              @click=""
            ></div> -->
            <v-container fluid fill-height pa-0 style="margin-top: 13.8%">
              <v-layout fill-height wrap column>
                <!-- <v-btn
                  v-for="(item, i) in events"
                  :height="item.height"
                  @click="handleClick(item.url)"
                  :ripple="false"
                  style="background-color: transparent"
                  class="elevation-0"
                  aria-label="button"
                ></v-btn> -->
              </v-layout>
            </v-container>
          </v-img>

          <v-img
            :src="getBannerBackground()"
            v-if="!isMobile"
            style="margin: auto"
            contain
          >
            <v-container fluid fill-height pa-0>
              <v-layout fill-height row wrap align-end>
                <!-- <v-btn
                  v-for="(item, i) in events"
                  :width="item.width"
                  height="77%"
                  @click="handleClick(item.url, i)"
                  :ripple="false"
                  style="background-color: transparent"
                  class="elevation-0"        
                  aria-label="activities_item"
                ></v-btn> -->
              </v-layout>
              <!-- <v-row v-if="isRunningEventEnd()">
                <v-col cols="4" class="d-flex align-center justify-center">
                  <v-img
                    :src="
                      require('@/assets/images/4Banner Logo_Desktop_512 x 768.png')
                    "
                    style="margin: auto"
                    contain
                  />
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="7" class="d-flex align-center justify-center">
                  <Banner
                    v-if="!isMobile"
                    :bannerDesc="bannerDesc"
                    :banners="banners"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="4" class="d-flex align-center justify-center pa-0">
                  <v-img
                    :src="
                      require('@/assets/images/4Banner Logo_Desktop_512 x 768.png')
                    "
                    contain
                    style="margin: auto; visibility: hidden"
                  />
                </v-col>
                <v-col cols="4" class="d-flex justify-center" style="flex: 1">
                  <div class="d-flex" style="flex: 0.8">
                    <v-img
                      :src="require('@/assets/images/activity/4P3_button.png')"
                      contain
                      max-width="300"
                      width="50%"
                      style="margin-top: auto; position: absolute; bottom: 0"
                      @click="openNewWindow()"
                    />
                  </div>
                </v-col>
                <v-col cols="4" class="pa-0"> </v-col>
              </v-row> -->

              <!-- <div
                class="d-flex justify-center"
                style="position: absolute; bottom: 0; width: 100%; left: 0"
              >
                <v-img
                  :src="require('@/assets/images/activity/dress_casual_button.jpg')"
                  contain
                  max-width="300"
                  width="50%"
                  @click="openNewWindow()"
                />
              </div> -->
            </v-container>
          </v-img>
        </template>
      </div>
    </v-main>
  </div>
</template>

<script>
import NavigationDrawer from "@/components/molecules/NavigationDrawer.vue";
import AgeBar from "@/components/molecules/AgeBar.vue";
import Banner from "@/components/organisms/Banner.vue";
import BaseCarousel from "@/components/organisms/BaseCarousel.vue";
import ContentHeader from "@/components/organisms/ContentHeader.vue";
import config from "../../../config";
import axios from "axios";
import moment from "moment";

export default {
  name: "ImageButton",
  components: {
    NavigationDrawer,
    AgeBar,
    Banner,
    BaseCarousel,
    ContentHeader,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.fetchData();
    this.checkIsActivityExtended();
    this.getBackgroundPhoto();
  },
  methods: {
    openNewWindow() {
      if (this.$i18n.locale == "en") {
        window.open("/#/ActivityDetails?id=4", "_blank");
      } else {
        window.open("/#/ActivityDetails?id=4", "_blank");
      }
    },
    handleClick(url, i) {
      if (url !== "/") {
          if(i == 4 && this.$i18n.locale == "en"){
            window.open(url + "en", "_blank");
          }else{
            window.open(url , "_blank");
          }
      }
    },
    isRunningEventEnd() {
      const today = moment();
      const november31st = moment("11/31", "MM/DD");
      return today.isAfter(november31st);
    },
    getBannerBackground() {
      if (this.isRunningEventEnd()) {
        return this.bannerBg[0].text;
      } else {
        return this.bannerBg[1].text;
      }
    },
    fetchData() {
      if (["Home"].includes(this.currentRouteName)) {
        axios
          .get(config.wordpressUrl + `/configs?order=asc&per_page=100`)
          .then((response) => {
            let data = response.data[0];
            console.log(data);

            if (data != null) {
              this.bannerDesc = this.getFieldFromResp(
                { acf: data.acf.home_page_banner_description },
                "description"
              );
              this.banners = data.acf.banners.map((banner) => {
                return {
                  image: this.getFieldFromResp({ acf: banner }, "image"),
                  type: banner.banner_type,
                  video: this.getFieldFromResp({ acf: banner }, "video"),
                  youtube: this.getFieldFromResp(
                    { acf: banner },
                    "youtube_link"
                  ),
                };
              });

              console.log("bannerDesc", this.bannerDesc);
            }
          });
      }
    },
    share() {
      if (!!navigator.share) {
        navigator.share({
          title: "童行。同心 Let’s T.A.L.K. • 保護兒童 Child Protection",
          // text: 'Text to be shared',
          url: window.location.href,
        });
      } else {
        this.snackbar = true;
        let textArea = document.createElement("textarea");
        textArea.value = window.location.href;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    checkIsActivityExtended() {
      const currentDate = moment(new Date());
      const isBefore = currentDate.isBefore(this.extendAcitvityDate);

      if (isBefore && this.extendActivity) {
        this.showCarousel = true;
      } else {
        this.showCarousel = false;
      }
    },
    getBackgroundPhoto(){
      axios
        .get(config.wordpressUrl + `/configs?order=asc&per_page=100`) //mainpagearray
        .then((response) => {

          if(response != null){
            let data = response.data[0];

            if(data.acf.mainpagearray != null){
              this.bannerBg = data.acf.mainpagearray;
            }

            // console.log("my testing!!", data.acf.mainpagearray);

          }
        });
    }
  },
  data: () => ({
    bannerBg: ["","",""],

    // bannerBg: [
    //   require("@/assets/images/home_header_bkg_desktop.png"),
    //   require("@/assets/images/activity/2024_activities.jpeg"),
    //   // require("@/assets/images/activity/run2023.jpg"),
    //   // require("@/assets/images/activity/run2023_pending.jpg"),
    //   require("@/assets/images/activity/2024_activities_moblie.jpeg"),
    //   // require("@/assets/images/activity/run2023_mobile.jpg"),
    //   // require("@/assets/images/activity/run2023_mobile_pending.jpg"),
    // ],
    links: ["Home", "About Us", "Team", "Services", "Blog", "Contact Us"],
    showSlider: false,
    bannerDesc: {},
    banners: [],
    events: [
      {
        width: "15.2%",
        height: "13.6%",
        url: "/",
      },
      {
        width: "13.9%",
        height: "12.7%",
        url: "https://space.o2meta.io/cpcmetaverse2023/",
      },
      {
        width: "13.9%",
        height: "12.7%",
        url: "https://www.childprotection.gov.hk/#/ActivityDetails?id=4",
      },
      {
        width: "13.7%",
        height: "12.7%",
        url: "/",
      },
      {
        width: "13.9%",
        height: "12.7%",
        url: "/#/ActivityDetails?id=5",
      },
      {
        width: "13.9%",
        height: "12.7%",
        url: "/",
      },
      {
        width: "14.9%",
        height: "13.6%",
        url: "https://www.police.gov.hk/offbeat/1237/chi/9014.html",
      },
    ],
    desktoplItems: [
      {
        src: require("@/assets/images/activity/run2023.jpg"),
        href: "https://cpc2023virtualrun.com/zh",
        hrefEn: "https://cpc2023virtualrun.com/en",
        btnSrc: require("@/assets/images/activity/4P3_button.png"),
      },
      {
        src: require("@/assets/images/activity/dress_casual_pending.jpg"),
        href: "/#/ActivityDetails?id=4",
        hrefEn: "/#/ActivityDetails?id=4",
        btnSrc: null,
      },
    ],
    mobileItems: [
      {
        src: require("@/assets/images/activity/run2023_mobile.jpg"),
        href: "https://cpc2023virtualrun.com/zh",
        hrefEn: "https://cpc2023virtualrun.com/en",
        btnSrc: require("@/assets/images/activity/4P3_button.png"),
      },
      {
        src: require("@/assets/images/activity/dress_casual_mobile_pending.jpg"),
        href: "/#/ActivityDetails?id=4",
        hrefEn: "/#/ActivityDetails?id=4",
        btnSrc: null,
      },
    ],
    extendActivity: true,
    extendAcitvityDate: moment("2023-10-28", "YYYY-MM-DD"),
    showCarousel: false,
    snackbar: false,
  }),
};
</script>
