<template>
  <div>
    <BackButton />
    <VideoPage :data="data" v-if="data" />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import VideoPage from "@/components/templates/VideoPage.vue";
import BackButton from "@/components/atoms/BackButton.vue";
import config from "/config";
import axios from "axios";

export default {
  name: "KidsVideoPage",
  components: {
    VideoPage,
    BackButton,
  },
  computed: {},
  data: () => ({
    data: [],
    test: null,
  }),
  mounted() {
    let path = this.$route.fullPath;
    let tmp = path.substr(path.indexOf("=") + 1, path.length);

    // axios
    //   .get(config.wordpressUrl + "/kids_video?order=asc&per_page=100")
    //   .then((response) => {
    //     this.test = response.data;
    //     this.data = this.getData(parseInt(tmp));
    //   });
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get(config.wordpressUrl + "/kids_video?order=asc&per_page=100")
        .then((response) => {
          console.log(response.data);
          const id = this.$route.query.id;
          console.log("query.id", id);

          console.log("item", this.$route.query.item);

          let videoData = {
            info: [this.$route.query.item],
            videoList: [],
          };

          const data = response.data;
          if (data) {
            data.map((item) => {
              let category = item.acf.category;

              if (category != null && category.ID == id) {
                videoData.videoList.push({
                  title: this.getFieldFromResp(item, "title"),
                  desc: this.getFieldFromResp(item, "description"),
                  category: this.getFieldFromResp(item, "category"),
                  image: item.acf.image,
                  videoId: this.$youtube.getIdFromUrl(item.acf.link),
                  order: parseFloat(item.acf.order),
                });
              }
            });

            videoData.videoList = videoData.videoList.sort((a, b) => {
              if (a.order > b.order) {
                return -1;
              }
              if (a.order < b.order) {
                return 1;
              }
              return 0;
            });

            this.data = videoData;
          }
        });
    },
    getImageUrl(img) {
      return require(`@/assets/images/kids/${img}`);
    },
    getRImageUrl(img) {
      return require(`@/assets/images/linking/kids/${img}`);
    },
    map(data) {
      return {
        title: {
          en: data.title_en,
          tc: data.title_tc,
          sc: data.title_sc,
        },
        category: {
          en: data.category_en,
          tc: data.category_tc,
          sc: data.category_sc,
        },
        desc: {
          en: data.description_en,
          tc: data.description_tc,
          sc: data.description_sc,
        },
        videoId: data.link.substr(
          data.link.lastIndexOf("?v=") + 3,
          data.link.length
        ),
        image: data.image,
      };
    },
    getData(id) {
      switch (id) {
        case 1: {
          var tmp = {
            info: [
              {
                title: "stayAlert.physical_harm",
                image: this.getImageUrl("physical_harm.png"),
                content: "common.tbc",
              },
            ],
            videoList: [],
          };
          this.test.forEach((element) => {
            if (element.acf.type == "Cybercrime") {
              tmp.videoList.push(this.map(element.acf));
            }
          });
          return tmp;
        }
        case 2: {
          var tmp = {
            info: [
              {
                title: "stayAlert.physical_harm",
                image: this.getImageUrl("physical_harm.png"),
                content: "common.tbc",
              },
            ],
            videoList: [],
          };
          this.test.forEach((element) => {
            if (element.acf.type == "Physical Harm") {
              tmp.videoList.push(this.map(element.acf));
            }
          });
          return tmp;
        }
        case 3: {
          var tmp = {
            info: [
              {
                title: "stayAlert.sexual_violence",
                image: this.getImageUrl("sexual_violence.png"),
                content: "common.tbc",
              },
            ],
            videoList: [],
          };
          this.test.forEach((element) => {
            if (element.acf.type == "Sexual Offences") {
              tmp.videoList.push(this.map(element.acf));
            }
          });
          return tmp;
        }
        case 4: {
          var tmp = {
            info: [
              {
                title: "stayAlert.others",
                image: this.getImageUrl("other_info.png"),
                content: "common.tbc",
              },
            ],
            videoList: [],
          };
          this.test.forEach((element) => {
            if (element.acf.type == "Others") {
              tmp.videoList.push(this.map(element.acf));
            }
          });
          return tmp;
        }
        default:
          return {
            info: [],
            videoList: [],
          };
      }
    },
  },
};
</script>
