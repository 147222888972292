<script>
import { Pie } from "vue-chartjs";

export default {
  props: ["chartData", "options"],
  mixins: [Pie],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale: function (newLocale, oldLocale) {
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>