<template>
  <div>
    <v-row class="d-flex flex-row"  id="panel">
      <v-col :cols="isMobile ? 12 : 6">
        <v-expansion-panels
          class="mt-3"
          v-for="(i, index) in items"
          :key="index"
          accordion
          :value="isOpen[index] ? 0 : -1"
        >
          <v-expansion-panel @click="changeIcon(index)" :aria-expanded="false">
            <v-expansion-panel-header class="elevation-3" disable-icon-rotate >
              <v-row>
                <v-col cols="2">
                  <v-img
                    :src="i.image"
                    height="60px"
                    width="60px"
                    contain
                    class="ml-0"
                  ></v-img>
                </v-col>
                <v-col
                  cols="10"
                  class="d-flex align-center justify-space-between"
                >
                  <div v-if="typeof i.title == 'string'" >{{ $t(i.title) }}</div>
                  <div v-else>
                    {{ i.title }}
                  </div>
                </v-col>
              </v-row>
              <template v-slot:actions>
                <v-icon x-large>
                  {{ isOpen[index] == true ? "mdi-minus" : "mdi-plus" }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <v-row class="mb-2 mt-2">
                <v-col
                  :cols="breakpointName == 'md' ? 12 : 6"
                  v-for="(j, jindex) in i.children"
                  :key="jindex"
                >
                  <Card
                    :items="{ route: i.route, ...j }"
                    :isShowMore="true"
                    :showDescn="showDescn"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col :cols="isMobile ? 12 : 6">
        <Recommend :datalist="recommend"></Recommend>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.v-expansion-panel-header {
  border-radius: 50px;
  font-size: 16px;
  padding: 7px 16px;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0 !important;
}
.v-expansion-panel {
  margin-bottom: 20px;
}
.v-expansion-panels {
  z-index: auto;
}
.v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}
.v-expansion-panel::before {
  bottom: inherit;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.v-expansion-panel-header {
  background-color: white;
}
</style>

<script>
// @ is an alias to /src
import Card from "@/components/organisms/Card.vue";
import Recommend from "@/components/organisms/Recommend.vue";

export default {
  name: "ExpansionPanels",
  components: {
    Card,
    Recommend,
  },
  props: ["items", "showDescn", "open", "recommend"],
  computed: {},
  mounted() {
    this.removeAriaExpanded();
  },
  watch: {
    "$route.params.id"(val) {
      console.log("here");
      this.default = val;
      this.isOpen = [];
      for (var i = 0, len = this.items.length; i < len; i++) {
        this.isOpen.push(false);
      }
      if (this.default != undefined) {
        this.isOpen[this.default] = !this.isOpen[this.default];
      }
    },
  },
  data: () => ({
    isOpen: [],
    default: -1,
    test: 1,
    imageList: [
      {
        title: "1",
        image: require("@/assets/images/campaign.jpg"),
      },
      {
        title: "2",
        image: require("@/assets/images/jumping_jack.png"),
      },
      {
        title: "3",
        image: require("@/assets/images/campaign.jpg"),
      },
    ],
  }),
  created() {
    this.default =
      this.$route.params.id != undefined || this.$route.params.id != null
        ? this.$route.params.id
        : this.open
        ? this.open
        : 0;
    for (var i = 0, len = this.items.length; i < len; i++) {
      this.isOpen.push(false);
    }
    if (this.default != undefined) {
      this.isOpen[this.default] = !this.isOpen[this.default];
    }
  },
  methods: {
    changeIcon(num) {
      this.isOpen[num] = !this.isOpen[num];
    },
    removeAriaExpanded() {
      const elements = document.querySelectorAll('[aria-expanded]');
      elements.forEach(element => {
        element.removeAttribute('aria-expanded');
      });
    },
    // getImage(){
    //   var random = Math.floor(Math.random() * this.imageList.length)
    //   return this.recommend[random]
    // }
  },
};
</script>
