export const physicalAbuse = {
  title: {
    en: "Physical Abuse",
    tc: "身體虐待",
    sc: "身体虐待",
  },
  img: "hh1.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
      color: "#367399",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Physical Abuse",
        tc: "身體虐待",
        sc: "身体虐待",
      },
      title: {
        en: "Physical Abuse",
        tc: "身體虐待",
        sc: "身体虐待",
      },
      img: "teens_physicalAbuse.png",
      aspectRatio: 336 / 273,
      showTips: true,
      points: [
        {
          en: "Shout for help!",
          tc: "高聲呼救！",
          sc: "高声呼救！",
        },
        {
          en: "Do not keep it to yourself.",
          tc: "不要啞忍！",
          sc: "不要哑忍！",
        },
        {
          en: "Tell a trusted person!",
          tc: "把事情告知你信任的成年人。",
          sc: "把事情告知你信任的成年人。",
        },
        {
          en: "Seek medical attention if injured.",
          tc: "如有受傷，前往求診。",
          sc: "如有受伤，前往求诊。",
        },
        {
          en: "Remember the details (when, where, who) of the incident.",
          tc: "記下事發經過的詳細資料（時間、地點、人物）。",
          sc: "记下事发经过的详细资料（时间、地点、人物）。",
        },
        {
          en: "Report to police as soon as possible.",
          tc: "盡快向警方舉報。",
          sc: "尽快向警方举报。",
        },
      ],
    },
  ],
};
