<template>
  <v-app-bar color="#042851" elevate-on-scroll app dark>
    <v-app-bar-nav-icon
      @click="$refs.navigationDrawer.toggleDrawer()"
      v-if="isMobile"
      aria-label="Menu"
    >
      <v-icon :x-large="!isMobile"> mdi-menu </v-icon>
    </v-app-bar-nav-icon>

    <v-container class="pa-0" v-if="isMobile">
      <v-row>
        <v-col class="d-flex align-center justify-space-between">
          <v-col
            v-for="(b, index) in desktopBtn"
            :key="index"
            cols="4"
            class="pa-1 d-flex justify-center"
          >
            <v-img
              :src="b.image"
              class="d-flex align-center text-center px-1"
              @click="$router.push(b.route).catch((err) => {})"
              contain
              :height="isMobile ? '25px' : '30px'"
              width="80px"
            >
              <div
                class="p noselect c-font-family"
                style="font-size: 14px; color: white"
              >
                {{ $t(b.title) }}
              </div>
            </v-img>
          </v-col>
        </v-col>
      </v-row>
    </v-container>

    <v-menu offset-y :close-on-click="true" v-if="isMobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            value="lang"
            v-bind="attrs"
            v-on="on"
            style="background-color: transparent"
            elevation="0"
            icon
            v-if="isMobile"
          >
            <div class="d-flex justify-center align-center flex-column">
              <v-icon color="white">mdi-web</v-icon>
              <span>{{ $t("common.language") }}</span>
            </div>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in lang"
            :key="index"
            @click="changeLang(item.value)"
          >
            <v-list-item-title class="text-center">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
    <!-- <v-container class="pa-0" v-else-if="isTablet">
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <NavigationDrawer style="position: absolute; left: 16px; top: 4px" />
          <div v-for="(b, index) in desktopBtn" :key="index">
            <v-img
              :src="b.image"
              class="d-flex align-center text-center px-1"
              @click="$router.push(b.route).catch((err) => {})"
              contain
              :height="isMobile ? '25px' : '30px'"
              width="100px"
            >
              <div class="p noselect" style="font-size: '14px'">
                {{ $t(b.title) }}
              </div>
            </v-img>
          </div>
        </v-col>
      </v-row>
    </v-container> -->

    <v-container class="pa-0" v-else>
      <v-row>
        <v-col class="d-flex align-center justify-start">
          <v-app-bar-nav-icon @click="$refs.navigationDrawer.toggleDrawer()" aria-label="Menu">
            <v-icon :x-large="!isMobile"> mdi-menu </v-icon>
          </v-app-bar-nav-icon>
          <!-- <NavigationDrawer /> -->

          <v-btn
            rounded
            color="white"
            class="d-flex align-center text-center px-0 pr-2 ml-3"
            @click="handleHelpingHandNavigation()"
            x-large
            height="36px"
            v-if="!isMobile"
          >
            <v-img
              :src="require('@/assets/images/helping_hand_btn.png')"
              width="38px"
              class="mr-1"
            />
            <p class="p noselect" style="font-size: 14px; color: #d60e00">
              {{ $t("common.needHelp") }}
            </p>
          </v-btn>

          <v-btn
            rounded
            color="white"
            class="d-flex align-center text-center px-0 pr-2 ml-3"
            @click="openHotlineDialog()"
            x-large
            height="36px"
            v-if="!isMobile"
          >
            <v-avatar left color="#ff2c00" size="30" class="mx-1">
              <v-icon color="white" size="20">mdi-phone</v-icon>
            </v-avatar>
            <p class="p noselect" style="font-size: 14px; color: #d60e00">
              {{ $t("common.letstalk") }}
            </p>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col class="d-flex align-center">
          <div v-for="(b, index) in desktopBtn" :key="index">
            <v-img
              :src="b.image"
              class="d-flex align-center text-center px-1"
              @click="$router.push(b.route).catch((err) => {})"
              contain
              width="100"
            >
              <div
                class="p noselect c-font-family"
                style="font-size: 16px; color: white"
              >
                {{ $t(b.title) }}
              </div>
            </v-img>
          </div>
        </v-col>

        <v-spacer></v-spacer>

        <v-col class="d-flex align-center justify-end">
          <h1 class="v-toolbar__title">Let's T.A.L.K.</h1>

          <v-btn
            value="lang"
            style="background-color: transparent"
            elevation="0"
            @click="$router.push('/').catch((err) => {})"
          >
            <v-icon color="white" class="pr-1">mdi-home</v-icon>
            <span class="text-subtitle-1 white--text">{{
              $t("common.home")
            }}</span>
          </v-btn>
          <v-menu offset-y :close-on-click="true">
            <v-list>
              <v-list-item
                v-for="(item, index) in lang"
                :key="index"
                @click="changeLang(item.value)"
              >
                <v-list-item-title class="text-center">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                value="lang"
                v-bind="attrs"
                v-on="on"
                style="background-color: transparent"
                elevation="0"
              >
                <v-icon color="white" class="pr-1">mdi-web</v-icon>
                <span class="text-subtitle-1 white--text">{{
                  getLocale()
                }}</span>
              </v-btn>
            </template>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <NavigationDrawer ref="navigationDrawer" />
    <HotlineDialogVue />
  </v-app-bar>
</template>

<style lang="scss" scoped>
.v-toolbar__content {
  padding: 0 !important;
}
.btn-container {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p {
  color: #fff;
  margin-bottom: 0;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.v-image:hover {
  cursor: pointer;
}
</style>

<script>
// @ is an alias to /src
import NavigationDrawer from "../molecules/NavigationDrawer.vue";
import HotlineDialogVue from "../organisms/HotlineDialog.vue";
import $ from "jquery";

export default {
  name: "AgeBar",
  props: {
    fixed: Boolean,
  },
  components: {
    NavigationDrawer,
    HotlineDialogVue,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({
    desktopBtn: [
      {
        title: "common.kids",
        route: "/Kids",
        image: require("@/assets/images/kids/btn_bg.png"),
      },
      {
        title: "common.teens",
        route: "/Teens",
        image: require("@/assets/images/teens/btn_bg.png"),
      },
      // {
      //   title : "common.needHelp",
      //   route: "HelpingHand",
      //   image: require('@/assets/images/helping_hand_btn.png'),
      // },
      {
        title: "common.adults",
        route: "/Adult",
        image: require("@/assets/images/parents/btn_bg.png"),
      },
      // {
      //   title : "common.schools",
      //   route: "Adult",
      //   image: require('@/assets/images/schools/btn_bg.png'),
      // }
    ],
    btn: [
      {
        title: "common.kids",
        route: "Kids",
        image: require("@/assets/images/kids/btn_bg.png"),
      },
      {
        title: "common.teens",
        route: "Teens",
        image: require("@/assets/images/teens/btn_bg.png"),
      },
      {
        title: "common.adults",
        route: "Adult",
        image: require("@/assets/images/parents/btn_bg.png"),
      },
      // {
      //   title: "common.schools",
      //   route: "Adult",
      //   image: require("@/assets/images/schools/btn_bg.png"),
      // },
    ],
    lang: {
      tc: {
        title: "繁",
        value: "tc",
      },
      sc: {
        title: "简",
        value: "sc",
      },
      en: {
        title: "EN",
        value: "en",
      },
    },
  }),
  methods: {
    openHotlineDialog() {
      $("#hotline-dialog-btn").click();
    },
    changeLang(value) {
      this.$store.commit("changeLang", value);
      this.$i18n.locale = value;
      console.log(this.$i18n.locale);
    },
    getLocale() {
      switch (this.$i18n.locale) {
        case "tc":
          return "繁";
        case "sc":
          return "简";
        case "en":
          return "Eng";
      }
    },
    handleHelpingHandNavigation() {
      const route = this.$route;
      if (route.fullPath.includes("Kids")) {
        this.navigate("KidsHelpingHand");
      } else if (route.fullPath.includes("Teens")) {
        this.navigate("TeensHelpingHand");
      } else if (route.fullPath.includes("Adult")) {
        this.navigate("AdultHelpingHand");
      } else {
        this.navigate("HelpingHand");
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    navigate(route) {
      if (this.currentRouteName == route) {
        window.location.reload();
        // this.$router.push({ name: route, query: { t: this.uuidv4() } });
      } else {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>
