import _ from "lodash";
import config from "../../config";
import axios from "axios";
import { apiServiceMixins } from "../service/APIService";

const ytApiKey = "AIzaSyBTK9fn0KeDOmSW2Sb4_5TeKiZwqDp15fA";

export const utilityMixins = {
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return true;
        case "md":
        case "lg":
        case "xl":
          return false;
        default:
          return false;
      }
    },
    isMobilePhone() {
      return this.$vuetify.breakpoint.name == "xs";
    },
    isTablet() {
      return this.$vuetify.breakpoint.name == "sm";
    },
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },
  methods: {
    youtubeApi(params, onSuccess, onFailure) {
      axios
        .get("https://www.googleapis.com/youtube/v3/videos", {
          params: {
            // id: videoId,
            // part: "statistics",
            key: ytApiKey,
            ...params,
          },
        })
        .then((response) => {
          onSuccess && onSuccess(response);
        })
        .catch((e) => {
          onFailure && onFailure(e);
        });
    },
    fixWordpressUrl(str) {
      return str.replaceAll(
        "http://192.168.1.175/wordpress",
        config.wordpressUrl.replace("/wp-json/wp/v2", "")
      );
    },
    filterValue(val) {
      if (typeof val == "string") {
        return val;
      } else {
        return val[this.$i18n.locale];
      }
    },
    // for wordpress
    getFieldFromResp(data, path, defaultValue = "common.tbc") {
      if (data && data.acf) {
        return {
          en: _.get(data.acf, path + "_en", defaultValue),
          tc: _.get(data.acf, path + "_tc", defaultValue),
          sc: _.get(data.acf, path + "_sc", defaultValue),
        };
      } else {
        return defaultValue;
      }
    },
    getImageFromResp(data) {
      if (data && data.acf) {
        return {
          en: _.get(data.acf, "image_en"), //data.acf.image_en.sizes["1536x1536"],
          tc: _.get(data.acf, "image_tc"), //data.acf.image_tc.sizes["1536x1536"],
          sc: _.get(data.acf, "image_sc"), //data.acf.image_sc.sizes["1536x1536"],
        };
      } else {
        return {
          en: require("@/assets/images/sponsor/sup_logo_3.png"),
          tc: require("@/assets/images/sponsor/sup_logo_3.png"),
          sc: require("@/assets/images/sponsor/sup_logo_3.png"),
        };
      }
    },
    fetchAdditionalMenus(onSuccess, onFailure = () => {}) {
      if (config.showAdditionalMenus) {
        try {
          axios
            .get(
              config.wordpressUrl +
                `/additional_menu_item?order=asc&per_page=100`
            )
            .then((res) => {
              const meunItems = res.data;
              if (meunItems != null && meunItems.length > 0) {
                axios
                  .get(
                    config.wordpressUrl +
                      `/additional_menu_cat?order=asc&per_page=100`
                  )
                  .then((response) => {
                    //
                    let menuCategories = response.data;
                    menuCategories.forEach((category) => {
                      let items = meunItems.filter(
                        (i) => i.acf.category.ID == category.id
                      );

                      if (items && items.length > 0) {
                        let catIndex = null;
                        switch (category.acf.menu_category) {
                          case "cat1":
                            catIndex = 0;
                            break;
                          case "cat2":
                            catIndex = 1;
                            break;
                        }
                        let ageIndex = null;
                        switch (category.acf.age_group) {
                          case "kids":
                            ageIndex = 0;
                            break;
                          case "teens":
                            ageIndex = 1;
                            break;
                          case "adults":
                            ageIndex = 2;
                            break;
                        }

                        if (catIndex != null && ageIndex != null) {
                          onSuccess &&
                            onSuccess(catIndex, ageIndex, items, category);
                        }
                      }
                    });
                  });
              }
            });
        } catch (e) {
          onFailure && onFailure(e);
        }
      }
    },
    ...apiServiceMixins.methods,
  },
};
