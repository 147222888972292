<template>
  <div class="text-center">
    <v-dialog v-model="dialog" v-if="!isMobile" max-width="900px">
      <v-card class="overflow-hidden">
        <a :href="urlEn" target="_blank" aria-label="link" style="width:100%">
          <v-img
            :src="backgroundUrl"
            contain
            style="position: relative"
            class="d-flex justify-center overflow-hidden"
            width="100%"
            height="100%"
          >
          </v-img>
          <!-- <v-btn
            style="position: absolute; right: 20px; top: 20px"
            icon
            @click="dialog = false"
            aria-label="close"
          >
            <v-icon large color="white">mdi-close</v-icon>
          </v-btn> -->
          <!-- 
          <a
            :href="
              isEn
                ? urlEn
                : urlCh
            "
            target="_blank"
            aria-label="link"
          > -->
          <!-- <v-img
              :src="btnUrl"
              width="290px"
              style="margin: auto; top: 70%"
            >
            </v-img> -->
          <!-- </a> -->
        </a>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" v-if="isMobile" width="unset">
      <v-card>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters align="center" justify="center">
              <a :href="urlEn" target="_blank" aria-label="link" style="width:100%">
                <v-img
                  :src="backgroundMoblieUrl"
                  style="position: relative"
                  contain
                  width="100%"
                  class="d-flex justify-center"
                >
                  <!-- <v-btn
                    style="position: absolute; right: 20px; top: 20px"
                    icon
                    @click="dialog = false"
                    aria-label="button"
                  >
                    <v-icon large color="white">mdi-close</v-icon>
                  </v-btn> -->

                  <!-- <a
                    :href="
                      isEn
                        ? urlEn
                        : urlCh
                    "
                    target="_blank"
                    style="position: absolute; top: 54%; left: 50%; transform: translate(-50%, -50%);"
                    aria-label="link"
                  >
                    <v-img
                      :src="btnUrl"
                      min-width="200px"
                      max-width="220px"
                    >
                    </v-img>
                  </a>  -->
                </v-img>
              </a>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../../config";

export default {
  name: "HomePopup",
  data() {
    return {
      dialog: true,
      backgroundUrl: "",
      backgroundMoblieUrl: "",
      btnUrl: "",
      urlEn: "",
      urlCh: "",
    };
  },
  created() {
    this.getPopup();
  },
  methods: {
    getPopup() {
      axios
        .get(config.wordpressUrl + `/configs?order=asc&per_page=100`)
        .then((response) => {
          let data = response.data[0];

          console.log("my testing!!", data.acf.isopenpopup);

          if (
            data.acf.isopenpopup.background != "" &&
            data.acf.isopenpopup.background != null
          ) {
            this.backgroundUrl = data.acf.isopenpopup.background;
          }
          if (
            data.acf.isopenpopup.background_moblie != "" &&
            data.acf.isopenpopup.background_moblie != null
          ) {
            this.backgroundMoblieUrl = data.acf.isopenpopup.background_moblie;
          }
          if (
            data.acf.isopenpopup.button != "" &&
            data.acf.isopenpopup.button != null
          ) {
            this.btnUrl = data.acf.isopenpopup.button;
          }
          if (
            data.acf.isopenpopup.urlen != "" &&
            data.acf.isopenpopup.urlen != null
          ) {
            this.urlEn = data.acf.isopenpopup.urlen;
          }
          if (
            data.acf.isopenpopup.urlch != "" &&
            data.acf.isopenpopup.urlch != null
          ) {
            this.urlCh = data.acf.isopenpopup.urlch;
          }
        });
    },
  },
  props: {
    isMobile: { type: Boolean, default: false },
  },
  computed: {
    isEn() {
      return this.$i18n.locale == "en";
    },
  },
};
</script>

<style lang="scss" scoped></style>
