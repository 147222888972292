<template>
  <v-container class="mt-0 pt-0">
    <v-img
      :src="getImageUrl('helpingHandBackground1.png')"
      class="align-center justify-center d-flex"
      :height="isMobilePhone ? '80px' : '120px'"
      cover
      id="bg"
    >
      <v-container
        class="align-center justify-space-between d-flex"
        style="flex: 1"
      >
        <span
          id="header-tx"
          :class="isMobilePhone ? 'header-tx-xs black--text' : 'black--text'"
          >{{ $t("common.helpingHand") }}</span
        >
        <div>
          <v-img
            :src="require('@/assets/images/TT-1.png')"
            :width="isMobilePhone ? 60 : 80"
          />
        </div>
      </v-container>
    </v-img>

    <div :style="{ height: isMobilePhone ? '100px' : '140px' }" />

    <v-row dense>
      <v-col
        :cols="isMobilePhone ? 12 : 4"
        v-for="(b, index) in btn"
        :key="index"
      >
        <div
          @click="$router.push({ name: b.route })"
          style="
            align-items: center;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            display: flex;
            font-size: 0.9375rem;
            line-height: 1;
            min-height: 48px;
            outline: none;
            position: relative;
            transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
            width: 100%;
          "
        >
          <v-img
            v-if="isMobilePhone"
            :src="b.image"
            class="d-flex align-center py-3"
            style="position: relative"
            contain
            @click="changeIcon(index)"
          >
            <div
              class="d-flex align-center justify-end"
              style="padding-right: 8%"
            >
              <div style="padding-right: 7%">
                <p
                  class="text-center mb-4 m-title"
                  :style="{
                    'text-shadow': '2px 2px 3px rgba(0, 0, 0, 1)', 
                  }"
                >
                  {{ filterValue($t(b.title)) }}
                </p>
                <p
                  class="text-center mb-0 m-sub-title"
                  :style="{
                    'text-shadow': '2px 2px 3px rgba(0, 0, 0, 1)', 
                  }"
                >
                  {{ filterValue($t(b.subtitle))  }}
                </p>
              </div>

              <v-btn
                :color="'black'"
                style="background-color: white; border-width: 2px"
                fab
                disabled
                depressed
                x-small
                outlined
                aria-label="button"
              >
                <v-icon color="black" style="font-weight: bold">
                  {{ b.isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-btn>
            </div>
          </v-img>

          <v-img
            v-if="isMobilePhone"
            :src="b.icon"
            contain
            @click="changeIcon(index)"
            style="width: 38%; position: absolute; bottom: 20px; left: 3%"
            :height="index == 2 ? '' : '100%'"
          >
          </v-img>

          <v-card color="transparent" elevation="0" v-else>
            <v-img
              :src="isMobilePhone ? b.image : b.desktop_image"
              class="d-flex align-end"
              contain
              @click="changeIcon(index)"
            >
              <div class="c-title-container">
                <div class="c-title" :style="{'background-color': `${b.outputColor}` }" >
                  {{ $t(b.title) }}
                </div>
                <div class="c-sub-title" :style="{'background-color': `${b.outputColor}` }" >
                  {{ $t(b.subtitle) }}
                </div>
              </div>

              <div class="float-right mb-4 mr-4">
                <v-btn
                  small
                  rounded
                  outlined
                  color="black"
                  dark
                  class="c-font-family"
                  style="
                    border-width: 2px;
                    font-size: 12px;
                    background-color: white;
                  "
                >
                  <div class="c-font-family">{{ $t("common.more") }} >></div>
                </v-btn>
              </div>
            </v-img>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
#bg {
  width: 100vw;
  position: absolute;
  margin-top: -12px;
  left: 0;
}
#header-tx {
  color: white;
  font-size: 50px;
  text-shadow-: -1px 0 #afafaf, 0 1px #afafaf, 1px 0 #afafaf, 0 -1px #afafaf;
}
.header-tx-xs {
  font-size: 35px !important;
}
.v-expansion-panel-header {
  padding: 0px;
}
// .v-expansion-panel-content {
//   border-radius: 50px 50px 0 0;
// }
.v-expansion-panel-content__wrap {
  padding: 0px 0px 0px !important;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0 !important;
}
.v-expansion-panel {
  margin-bottom: 20px;
  background-color: transparent !important;
}
.v-expansion-panels {
  z-index: auto;
}
.v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}

.v-expansion-panel::before {
  bottom: auto;
}
.v-image:hover {
  cursor: pointer;
}
.v-hidden {
  visibility: hidden;
}
</style>

<script>
// @ is an alias to /src
import RibbonTitle from "@/components/molecules/RibbonTitle.vue";

export default {
  name: "HelpingHand",
  components: {
    RibbonTitle,
  },
  methods: {
    getImageUrl(img) {
      return require(`@/assets/images/helping_hand/${img}`);
    },
  },
  data: () => ({
    //
    flowStacks: [],
    showRibbonHeader: true,
    showNext: true,
    showBack: true,
    btn: [
      {
        image: require("@/assets/images/kids/bkg.png"),
        icon: require("@/assets/images/kids/bkg_icon.png"),
        desktop_image: require("@/assets/images/kids/desktop_bkg.png"),
        isOpen: false,
        route: "KidsHelpingHand",
        title: "common.kids",
        subtitle: "common.kidsAge",
        color: "#FF8940",
        darkColor: "#D03A03",
        cardColor: "#FF8E24",
        outputColor: "#FE2C45"
      },
      {
        image: require("@/assets/images/teens/bkg.png"),
        icon: require("@/assets/images/teens/bkg_icon.png"),
        desktop_image: require("@/assets/images/teens/desktop_bkg.png"),
        isOpen: false,
        route: "TeensHelpingHand",
        title: "common.teens",
        subtitle: "common.teensAge",
        color: "#3D9AF8",
        darkColor: "#1FD568",
        cardColor: "#6BEABA",
        outputColor: "#00AEDD"
      },
      {
        image: require("@/assets/images/parents/bkg.png"),
        icon: require("@/assets/images/parents/bkg_icon.png"),
        desktop_image: require("@/assets/images/parents/desktop_bkg.png"),
        isOpen: false,
        route: "AdultHelpingHand",
        title: "common.adults",
        subtitle: "",
        color: "#7AEB27",
        darkColor: "#00B1FF",
        cardColor: "#5DC1FF",
        outputColor: "#F19601"
      },
    ],
  }),
};
</script>
