<template>
  <div>
    <v-row>
      <v-col
        :cols="isMobile ? 12 : 4"
        v-for="(v, index) in videoList"
        :key="index"
      >
        <v-card
          style="
            margin-top: 10px;
            text-align: left;
            display: flex;
            flex-direction: column;
            height: 100%;
          "
        >
          <v-card-subtitle
            :style="{
              'background-color': currentRouteName.includes('Adult')
                ? '#61c2ff'
                : currentRouteName.includes('Teens')
                ? '#77d525'
                : '#ffa111',
              color: '#000',
            }"
            v-if="filterValue(v.category)"
          >
            {{ filterValue(v.category) }}
          </v-card-subtitle>

          <!-- <v-card-title v-snip="2" class="px-0 mx-2" style="font-size: 18px; height: 70px; line-height: 20px">
            {{ $i18n.locale == "tc" ? v.title : $t("common.tbc") }}
          </v-card-title> -->

          <!-- <div
            
            class="d-flex align-center justify-space-between"
            style="min-height: 100px; margin: auto; padding: 0 16px; font-size: 18px"
            
          >
            {{ $i18n.locale  == "en" ? v.title.en : $i18n.locale  == "tc" ? v.title.tc : v.title.sc }}
          </div> -->

          <v-img
            class="d-flex justify-center align-center text-center"
            :src="v.image"
            contain
            style="background-color: black"
            height="150"
            max-height="150"
          >
            <v-btn
              v-if="v.videoId"
              color="white"
              fab
              dark
              :x-large="!isMobile"
              @click="popUpDialog(v.local, v.videoId, v.title)"
              aria-label="video"
            >
              <v-icon
                color="grey"
                :size="windowWidth * (isMobile ? 0.07 : 0.03)"
              >
                mdi-play
              </v-icon>
            </v-btn>
          </v-img>

          <v-card-text
            class="d-flex justify-end mt-3 text-caption py-0"
            v-if="v.videoId"
          >
            <div>( {{ $t("common.cantoneseOnly") }} )</div>
          </v-card-text>

          <v-card-title class="justify-center text-center">
            {{
              $i18n.locale == "en"
                ? v.title.en
                : $i18n.locale == "tc"
                ? v.title.tc
                : v.title.sc
            }}
          </v-card-title>

          <v-card-text class="text-caption" style="color: #000;">
            {{
              $i18n.locale == "en"
                ? v.desc.en
                : $i18n.locale == "tc"
                ? v.desc.tc
                : v.desc.sc
            }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      v-model="dialog"
      scrollable
      :width="isMobile ? '90vw' : '85vw'"
    >
      <v-card :height="isMobile ? '50vh' : '85vh'">
        <v-card-title>
          <v-row>
            <v-col cols="10">
              {{
                $i18n.locale == "en"
                  ? title.en
                  : $i18n.locale == "tc"
                  ? title.tc
                  : title.sc
              }}
            </v-col>
            <v-col cols="2">
              <v-btn
                absolute
                top
                right
                icon
                color="red"
                class="btn"
                @click="closeVideo()"
              >
                <v-icon> mdi-close-circle </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <youtube
          :video-id="videoId"
          ref="youtube"
          :player-vars="{ autoplay: 1 }"
          :width="local ? '0%' : '100%'"
          :height="local ? '0%' : '100%'"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal !important;
}
// .v-sheet.v-card:not(.v-sheet--outlined) {
//   box-shadow: none;
// }
// .v-card__subtitle,
// .v-card__text,
// .v-card__title {
//   padding-left: 0px;
// }
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-dialog {
  overflow-y: inherit;
}
</style>

<script>
// @ is an alias to /src

export default {
  name: "YoutubePopUp",
  components: {},
  props: ["videoList"],
  data() {
    return {
      dialog: false,
      videoId: "",
      title: "",
      local: false,
      playerOptions: {},
    };
  },
  mounted: function() {},
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    popUpDialog(local, vid, title) {
      this.dialog = !this.dialog;
      this.local = local == undefined ? false : true;
      this.videoId = vid;
      this.title = title;
    },
    closeVideo() {
      this.dialog = !this.dialog;
      if (this.local) {
        this.videoPlayer.pause();
        this.videoPlayer.currentTime(0);
      } else {
        this.player.stopVideo();
      }
    },
  },
};
</script>
