<template>
  <div class="justify-center align-center mb-6">
    <span v-html="fixWordpressUrl(filterValue(item.html))" />
    <!-- <iframe :srcdoc="filterValue(item.html)" /> -->
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import BackButton from "@/components/atoms/BackButton.vue";

export default {
  components: {
    BackButton,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.removeRole();
  },
  methods: {
    removeRole(){
      const elements = document.querySelectorAll('[role="listitem"]');
      elements.forEach(element => {
        element.removeAttribute('role');
      });

    }
  },
};
</script>
