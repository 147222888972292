export const witness = {
  title: {
    en: "As a Witness…",
    tc: "作為證人…",
    sc: "common.tbc",
  },
  subTitle: {
    en: "Help your friend or someone you know!",
    tc: "幫助你身邊的人",
    sc: "common.tbc",
  },
  img: "witness-teens.png",
  aspectRatio: 364 / 121,
  onCardClick: true,
  children: [
    {
      contentHeader: {
        en: "As a Witness…",
        tc: "作為證人…",
        sc: "common.tbc",
      },
      img: "witness-teens-1.png",
      aspectRatio: 367 / 274,
      // content: "Seek help\nYou are not alone",
      showTips: true,
      points: [
        {
          en: "Listen to him/ her. Your support is very important",
          tc: "你的聆聽是最大的鼓舞",
          sc: "common.tbc",
        },
        {
          en: "Encourage him/ her to seek help",
          tc: "鼓勵他們求助",
          sc: "common.tbc",
        },
        {
          en: "Give him/ her reassurance",
          tc: "給予安慰及信任",
          sc: "common.tbc",
        },
        {
          en: "If the problem cannot be solved, seek help!",
          tc: "若果不能解決問題，盡快求助",
          sc: "common.tbc",
        },
      ],
      children: [
        {
          img: "witness-teens-2.png",
          aspectRatio: 366 / 200,
          content: {
            en: "Don’t burden yourself",
            tc: "別獨自負起重担",
            sc: "common.tbc",
          },
          children: [
            {
              img: "witness-teens-3.png",
              aspectRatio: 366 / 200,
              content: {
                en:
                  "Offer to accompany your friend to share with an adult you trust as soon as possible, who can be a parent, teacher or social worker…",
                tc: "可以一起去告訴給信任的成人聽，例如父母、老師或社工",
                sc: "common.tbc",
              },
              children: [
                {
                  img: "witness-teens-4.png",
                  aspectRatio: 366 / 200,
                  content: {
                    en:
                      "After the adult has understood the situation, the case may be reported to police if necessary!",
                    tc: "成人會了解情況，有需要時便會向警察求助",
                    sc: "common.tbc",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
