<template>
  <div class="read-more-container" v-if="details">
    <div
      v-if="!showDetails"
      class="btn"
      @click="showDetails = true"
      style="color: #1569bc"
    >
      {{ $t("common.more") }}
    </div>

    <transition name="slide-fade">
      <div v-if="showDetails">
        <div
          v-for="(detail, j) in details"
          :key="j"
          :class="(j == 0 ? 'mt-2' : '') + ' ' + detail.class"
          style="color: #1569bc"
        >
          <div
            :class="
              !detail.contents || detail.contents.length == 0 ? 'mb-2' : ''
            "
            v-html="detail.title"
          />

          <div
            v-for="(content, k) in detail.contents"
            :key="k"
            class="d-flex align-baseline justify-start"
            :class="k == detail.contents.length - 1 ? 'mb-2' : ''"
          >
            <!-- hide if it is sub-items -->
            <v-icon
              :style="{
                visibility: typeof content == 'string' ? 'visible' : 'hidden',
              }"
              size="10"
              class="mr-2"
              color="#a9a9a9"
              >mdi-star</v-icon
            >
            <!-- Normal case -->
            <div v-if="typeof content == 'string'">
              {{ content }}
            </div>

            <!-- Sub-items case -->
            <div v-if="typeof content == 'object'">
              <div
                v-for="(item, n) in content"
                :key="n"
                class="d-flex align-baseline justify-start"
                :class="k == detail.contents.length - 1 ? 'mb-2' : ''"
              >
                <span class="mr-2">-</span>
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div
      v-if="showDetails"
      class="btn"
      @click="showDetails = false"
      style="color: #1569bc"
    >
      {{ $t("common.less") }}
    </div>
  </div>
</template>

<style lang="scss">
.read-more-container {
  font-size: 14px;
  color: cornflowerblue;
}
.bullet-points-tx {
  color: #565656;
  font-size: 14px;
  width: 100%;
}
.btn {
  text-align: right;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
  max-height: 10000px;
}
.slide-fade-leave-active {
  transition: all 0.8s ease;
  max-height: 100000px;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>

<script>
// @ is an alias to /src

export default {
  name: "",
  props: {
    details: {
      type: Array,
    },
  },
  created() {
    //
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({
    //
    showDetails: false,
  }),
  methods: {
    getImageUrl(img) {
      if (img) {
        return require(`@/assets/images/helping_hand/${img}`);
      }
    },
  },
};
</script>
