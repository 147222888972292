<template>
  <div class="pb-3">
    <!-- <div v-for="i in items" :key="i.name">
      <div style="font-size: 34px;" >
        {{i.name}}
      </div>
    </div> -->

    <!-- <p>{{ $t("common.tbc") }}</p> -->

    <!-- {{ item.chart_options }} -->
    <!-- <v-text-field> {{ JSON.stringify(chartData, null, 2) }}</v-text-field> -->
    <!-- {{ mapChartOptionToChartData(item.chart_options) }} -->
    <BarChart
      v-if="item.chart_options.chart_type == 'bar'"
      :chartData="chartData.data"
      :options="chartData.options"
    />
    <LineChart
      v-if="item.chart_options.chart_type == 'line'"
      :chartData="chartData.data"
      :options="chartData.options"
    />
    <PieChart
      v-if="item.chart_options.chart_type == 'pie'"
      :chartData="chartData.data"
      :options="chartData.options"
    />
    <div
      class="pt-3"
      v-html="
        $t(
          fixWordpressUrl(
            filterValue(
              getFieldFromResp({ acf: item.chart_options }, 'descriptions')
            )
          )
        )
      "
    />

    <!-- <BarChart
      :chartData="{
        labels: ['2020/01 - 2020/06', '2021/01 - 2021/06'],
        datasets: [
          {
            label: '10 - 15' + this.$t('learnMore.years'),
            data: [707, 542],
            backgroundColor: '#5b9bd5',
            datalabels: {
              color: 'black',
            },
          },
          {
            label: '16 - 20' + this.$t('learnMore.years'),
            data: [1599, 977],
            backgroundColor: '#ed7d31',
            datalabels: {
              color: 'black',
            },
          },
        ],
      }"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          labels: {
            padding: 20,
          },
          display: true,
        },
        title: {
          display: true,
          text: this.$t('chart.teens_total'),
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              scaleLabel: {
                labelString: this.$t('chart.no_of_people'),
                display: true,
              },
            },
          ],
        },
      }"
    /> -->

    <!-- <div style="text-align: left;">
      <div style="font-size: 24px; margin-bottom: 10px; padding:24px;" :style="{color: items.color}">
        {{items.titleEN}}
      </div>

      <div style="font-size: 14px; padding: 36px 24px;" v-for="(c, index) in items.childrenEN" :key="index" :style="{backgroundColor: index % 2 ? '#fff' : '#f7f8f9' }">
        <div style="font-weight: bold; font-size:24px ; margin-bottom: 10px;;">{{c.subHeader}}</div>
        <div style="line-height: 28px;color: #928e8e; font-size:16px;">{{c.content}}</div>
      </div>
    </div> -->

    <!-- <youtube :video-id="items.videoId" ref="youtube" width="90%" height="90%" v-if="items.videoId != null"/> -->
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import BarChart from "@/components/atoms/chart/BarChart";
import PieChart from "@/components/atoms/chart/PieChart";
import LineChart from "@/components/atoms/chart/LineChart";
import BackButton from "@/components/atoms/BackButton.vue";

export default {
  name: "ContentPage",
  components: {
    BackButton,
    BarChart,
    PieChart,
    LineChart,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    chartData: {},
  }),
  created() {
    this.setCharData();
  },
  mounted() {
    this.setCharData();
  },
  watch: {
    locale: function(newLocale, oldLocale) {
      this.setCharData();
    },
  },
  methods: {
    setCharData() {
      //
      this.chartData = {
        data: this.mapChartOptionToChartData(this.item.chart_options),
        options: this.mapChartOptionToChartOption(this.item.chart_options),
      };
    },
    mapChartOptionToChartData(option) {
      let chartData = {
        labels: [],
        datasets: [],
      };

      if (option.chart_type == "pie") {
        let total = 0;

        chartData.datasets.push({
          data: [],
          backgroundColor: [],
          datalabels: {
            align: "center",
            anchor: "center",
            formatter: null,
          },
        });

        console.log(this.item, "fd");

        option.data_sets_pie_chart.map((data) => {
          total += parseFloat(data.value);
          chartData.labels.push(
            this.filterValue(this.getFieldFromResp({ acf: data }, "label"))
          );
          chartData.datasets[0].data.push(data.value);
          chartData.datasets[0].backgroundColor.push(data.background_color);
        });

        console.log("option.value_display_format", option.value_display_format);

        if (option.value_display_format == 0) {
          chartData.datasets[0].datalabels.formatter = (value, context) => {
            console.log("total", context);
            return parseFloat((value / total) * 100).toFixed(1) + "%";
            // return parseInt((value / total) * 100) + "%";
          };
        }
      } else {
        option.horizontal_axis_labels.map((label) => {
          chartData.labels.push(
            this.filterValue(this.getFieldFromResp({ acf: label }, "label"))
          );
        });

        console.log("option.data_sets", option.data_sets);

        option.data_sets.map((set) => {
          let dataset = {
            backgroundColor: set.color,
            label: this.filterValue(
              this.getFieldFromResp({ acf: set }, "label")
            ),
            data: set.data.map((d) => d.value),
            datalabels: {
              color: set.label_color,
            },
            fill: false,
            borderColor: set.color,
            tension: 0,
          };
          if (option.chart_type == "line" || option.stack_bar_chart == 1) {
            dataset.datalabels = {
              align: "end",
              anchor: "end",
              color: set.label_color,
            };
          }
          chartData.datasets.push(dataset);
        });
      }

      console.log("chartData", chartData);
      return chartData;
    },
    mapChartOptionToChartOption(option) {
      console.log("option.stack_bar_chart", option.stack_bar_chart);
      if (option.chart_type == "bar") {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "bottom",
            labels: {
              padding: 20,
            },
            display: true,
          },
          title: {
            display: true,
            text: this.filterValue(
              this.getFieldFromResp({ acf: option }, "title")
            ),
          },
          scales: {
            xAxes: [
              {
                stacked: option.stack_bar_chart == 0,
              },
            ],
            yAxes: [
              {
                stacked: option.stack_bar_chart == 0,
                scaleLabel: {
                  labelString: this.filterValue(
                    this.getFieldFromResp(
                      { acf: option.vertical_axis_labels },
                      "label"
                    )
                  ),
                  display: true,
                },
              },
            ],
          },
        };
      } else if (option.chart_type == "line") {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "bottom",
            labels: {
              padding: 20,
            },
            display: true,
          },
          title: {
            display: true,
            text: this.filterValue(
              this.getFieldFromResp({ acf: option }, "title")
            ),
          },
        };
      } else if (option.chart_type == "pie") {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "bottom",
            labels: {
              padding: 20,
            },
            display: true,
          },
          title: {
            display: true,
            text: this.filterValue(
              this.getFieldFromResp({ acf: option }, "title")
            ),
          },
        };
      }
    },
  },
};
</script>
