<template>
  <div>
    <BackButton />

    <ContentTemplate contentUrlPath="adults_chill_out1" />

  </div>
</template>

<style lang="scss" scoped></style>

<script>
import Banner from "@/components/organisms/Banner.vue";
import Card from "@/components/organisms/Card.vue";
import VuePdfApp from "vue-pdf-app";
import BackButton from "@/components/atoms/BackButton.vue";
import "vue-pdf-app/dist/icons/main.css";
import SessionTitle from "@/components/atoms/SessionTitle.vue";
import config from "/config";
import axios from "axios";

import ContentTemplate from "@/components/templates/ContentTemplate";

// @ is an alias to /src
export default {
  name: "Articles",
  components: {
    VuePdfApp,
    BackButton,
    Card,
    SessionTitle,
    ContentTemplate,
  },
  
};
</script>
