import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import Home from "../components/pages/Home.vue";
import AdultHome from "../components/pages/Adult/AdultHome.vue";
import AdultsVideoPage from "../components/pages/Adult/VideoPage.vue";
import Partnership from "../components/pages/Adult/Partnership.vue";
import AdultLearnMore from "../components/pages/Adult/LearnMore.vue";
import AdultStayInformed from "../components/pages/Adult/StayInformed.vue";
import Articles from "../components/pages/Adult/Articles.vue";
import Materials from "../components/pages/Adult/Materials.vue";

import KidsHome from "../components/pages/Kids/KidsHome.vue";
import KidsVideoPage from "../components/pages/Kids/VideoPage.vue";
import KidsLearnMore from "../components/pages/Kids/LearnMore.vue";

import TeensHome from "../components/pages/Teens/TeensHome.vue";
import TeensVideoPage from "../components/pages/Teens/VideoPage.vue";
import TeensStayInformed from "../components/pages/Teens/StayInformed.vue";

// import ChillOutCorner from "../components/pages/ChillOutCorner/ChillOutCorner.vue";
import Game1 from "../components/pages/ChillOutCorner/Game1.vue";
import Game2 from "../components/pages/ChillOutCorner/Game2.vue";
import PhotoFrame from "../components/pages/ChillOutCorner/PhotoFrame.vue";
// import TeensPhotoFrame from "../components/pages/Teens/PhotoFrame.vue"

import AboutUs from "../components/pages/AboutUs.vue";

// Helping Hand
import HelpingHand from "../components/pages/HelpingHand.vue";
import KidsHelpingHand from "../components/pages/Kids/HelpingHand.vue";
import TeensHelpingHand from "../components/pages/Teens/HelpingHand.vue";
import AdultHelpingHand from "../components/pages/Adult/HelpingHand.vue";

//Activity
import ActivityDetails from "../components/pages/ActivityDetails.vue";
import AllActivities from "../components/pages/AllActivities.vue";

import ContentPage from "../components/pages/ContentDetails.vue";

import VideoGallery from "../components/pages/VideoGallery.vue";

import login from "../components/pages/login.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/HelpingHand",
    name: "HelpingHand",
    component: HelpingHand,
  },
  {
    path: "/KidsHelpingHand",
    name: "KidsHelpingHand",
    component: KidsHelpingHand,
  },
  {
    path: "/TeensHelpingHand",
    name: "TeensHelpingHand",
    component: TeensHelpingHand,
  },
  {
    path: "/AdultHelpingHand",
    name: "AdultHelpingHand",
    component: AdultHelpingHand,
  },

  //Adult
  {
    path: "/Adult",
    name: "AdultHome",
    component: AdultHome,
  },
  {
    path: "/AdultStayAlert",
    name: "AdultsVideoPage",
    component: AdultsVideoPage,
    meta: { openItem: 0 },
  },
  {
    path: "/AdultStayInformed",
    name: "AdultStayInformed",
    component: AdultStayInformed,
    meta: { openItem: 1 },
  },
  {
    path: "/AdultArticles",
    name: "Articles",
    component: Articles,
    meta: { openItem: 2 },
  },
  {
    path: "/AdultLearnMore",
    name: "LearnMore",
    component: AdultLearnMore,
    meta: { openItem: 3 },
  },
  {
    path: "/AdultMaterials",
    name: "Materials",
    component: Materials,
    meta: { openItem: 4 },
  },
  {
    path: "/AdultFriendlyLinks",
    name: "Partnership",
    component: Partnership,
    meta: { openItem: 5 },
  },
  {
    path: "/AdultsContentPage",
    name: "AdultsContentPage",
    component: ContentPage,
  },

  //Kids
  {
    path: "/Kids",
    name: "KidsHome",
    component: KidsHome,
  },
  {
    path: "/KidsStayAlert",
    name: "KidsVideoPage",
    component: KidsVideoPage,
    meta: { openItem: 0 },
  },
  {
    path: "/ChillOutCornerGame1",
    name: "KidsGame1",
    component: Game1,
    meta: { openItem: 1 },
  },
  {
    path: "/ChillOutCornerGame2",
    name: "KidsGame2",
    component: Game2,
    meta: { openItem: 1 },
  },
  {
    path: "/KidsPhotoFrame",
    name: "KidsPhotoTheme",
    component: PhotoFrame,
    meta: { openItem: 1 },
  },
  {
    path: "/KidsLearnMore",
    name: "KidsLearnMore",
    component: KidsLearnMore,
    meta: { openItem: 2 },
  },
  {
    path: "/KidsContentPage",
    name: "KidsContentPage",
    component: ContentPage,
  },

  // {
  //   path: "/ChillOutCorner",
  //   name: "KidsChillOutCorner",
  //   component: ChillOutCorner,
  // },

  //Teens
  {
    path: "/Teens",
    name: "TeensHome",
    component: TeensHome,
  },
  {
    path: "/TeensStayAlert",
    name: "TeensVideoPage",
    component: TeensVideoPage,
    meta: { openItem: 0 },
  },
  {
    path: "/TeensStayInformed",
    name: "TeensStayInformed",
    component: TeensStayInformed,
    meta: { openItem: 1 },
  },
  {
    path: "/TeensPhotoFrame",
    name: "TeensPhotoFrame",
    component: PhotoFrame,
    meta: { openItem: 2 },
  },
  {
    path: "/TeensContentPage",
    name: "TeensContentPage",
    component: ContentPage,
  },

  {
    path: "/AboutUs",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/ActivityDetails",
    name: "ActivityDetails",
    component: ActivityDetails,
  },
  {
    path: "/AllActivities",
    name: "AllActivities",
    component: AllActivities,
  },
  {
    path: "/VideoGallery",
    name: "VideoGallery",
    component: VideoGallery,
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

import { LETS_TALK_PW, password } from "../../credential";
import config from "../../config";

router.beforeEach((to, from, next) => {
  if (config.requirePassword) {
    let pwd = sessionStorage.getItem(LETS_TALK_PW);
    if (to.name !== "Login" && pwd != password) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
