<template>
  <div>
    <v-carousel
      v-show="isMobile"
      cycle
      height="auto"
      hide-delimiters
      :interval="interval"
    >
      <v-carousel-item
        v-for="(item, i) in mobileItems"
        :key="i"
        :href="$i18n.locale == 'en' ? item.hrefEn : item.href"
        target="blank"
      >
        <v-img :src="item.src" min-height="auto">
          <div
            v-if="item.btnSrc !== null"
            class="d-flex"
            style="flex: 0.7; align-items: center; justify-content: center"
          >
            <v-img
              :src="item.btnSrc"
              contain
              max-width="300"
              width="50%"
              style="margin-top: auto; position: absolute; bottom: 0"
            />
          </div>
        </v-img>
      </v-carousel-item>
    </v-carousel>

    <v-carousel
      v-show="!isMobile"
      cycle
      height="650"
      hide-delimiters
      :interval="interval"
    >
      <v-carousel-item
        v-for="(item, i) in desktopItems"
        :key="i"
        :href="$i18n.locale == 'en' ? item.hrefEn : item.href"
        target="blank"
      >
        <v-img :src="item.src" height="650">
          <div
            v-if="item.btnSrc !== null"
            class="d-flex"
            style="flex: 0.7; align-items: center; justify-content: center"
          >
            <v-img
              :src="item.btnSrc"
              contain
              max-width="300"
              width="50%"
              style="margin-top: auto; position: absolute; bottom: 0"
            />
          </div>
        </v-img>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: { type: Boolean, default: false },
    interval: { type: Number, default: 6000 },
    desktopItems: {
      type: Array,
      default: () => [{ src: "", href: "", btnSrc: null }],
    },
    mobileItems: {
      type: Array,
      default: () => [{ src: "", href: "", btnSrc: null }],
    },
  },
};
</script>

<style lang="scss" scoped></style>
