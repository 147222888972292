<template>
  <div
    :class="isMobile ? 'mt-3' : 'pa-0'"
    style="position: relative; margin-top: -12px"
  >
    <div
      class="bg"
      :style="{
        width: isMobile ? '100vw' : '300vw',
        left: isMobile ? '-12px' : '-125vw',
      }"
    />
    <v-row dense class="pt-12 pb-8" id="panel">
      <v-col
        :cols="isMobilePhone ? 12 : 4"
        v-for="(b, index) in btn"
        :key="index"
      >
        <v-expansion-panels accordion multiple>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon=""  >
              <v-img
                v-if="isMobilePhone"
                :src="b.image"
                class="d-flex align-center py-3"
                style="position: relative"
                contain
                @click="changeIcon(index)"
              >
                <div
                  class="d-flex align-center justify-end"
                  style="padding-right: 8%"
                >
                  <div style="padding-right: 7%">
                    <p
                      class="text-center mb-4 m-title"
                      :style="{
                        'text-shadow-': `-1px 0 ${b.darkColor}, 0 1px ${b.darkColor}, 1px 0 ${b.darkColor}, 0 -1px ${b.darkColor}`,
                      }"
                    >
                      {{ filterValue($t(b.title)) }}
                    </p>
                    <p
                      class="text-center mb-0 m-sub-title"
                      :style="{
                        'text-shadow-': `-1px 0 ${b.darkColor}, 0 1px ${b.darkColor}, 1px 0 ${b.darkColor}, 0 -1px ${b.darkColor}`,
                      }"
                    >
                      {{ filterValue($t(b.subtitle)) }}
                    </p>
                  </div>

                  <v-btn
                    :color="'black'"
                    style="background-color: white; border-width: 2px"
                    fab
                    
                    depressed
                    x-small
                    outlined
                    disabled
                    aria-label="button"
                  >
                    <v-icon color="black" style="font-weight: bold">
                      {{ b.isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-img>

              <v-img
                v-if="isMobilePhone"
                :src="b.icon"
                contain
                @click="changeIcon(index)"
                style="width: 38%; position: absolute; bottom: 20px; left: 3%"
                :height="index == 2 ? '' : '100%'"
              >
              </v-img>

              <v-card color="transparent" elevation="0" v-else>
                <v-img
                  :src="isMobilePhone ? b.image : b.desktop_image"
                  class="d-flex align-end"
                  contain
                  @click="changeIcon(index)"
                >
                  <div class="c-title-container">
                    <div class="c-title">
                      {{ $t(b.title) }}
                    </div>
                    <div class="c-sub-title">
                      {{ $t(b.subtitle) }}
                    </div>
                  </div>

                  <div class="float-right mb-4 mr-4">
                    <v-btn
                      small
                      rounded
                      outlined
                      color="black"
                      disabled
                      class="c-font-family"
                      style="
                        border-width: 2px;
                        font-size: 12px;
                        background-color: white;
                      "                  
                    >
                      <div class="c-font-family" aria-label="panels">
                        {{ $t("common.more") }} >>
                      </div>                     
                    </v-btn>
                  </div>
                </v-img>
              </v-card>
            </v-expansion-panel-header>

            <v-expansion-panel-content
              :style="{ 'margin-top': isMobilePhone ? '' : '40px' }"
            >
              <v-row>
                <v-col
                  @click="$router.push({ name: j.route, params: { id: j.id } })"
                  :cols="isMobilePhone ? 6 : 12"
                  v-for="(j, jindex) in b.children"
                  :key="jindex"
                >
                  <div style="position: relative" v-if="j.hidden != true" >
                    <v-img :src="j.image" class="d-flex align-center" contain >
                      <div
                        class="black--text"
                        style="padding-left: 45%; padding-right: 5%"
                        :style="{
                          'font-size': isMobilePhone ? '12px' : '16px',
                          'line-height': isMobilePhone ? '15px' : '19px',
                          'text-shadow': '2px 2px 4px white'
                        }"
                      >
                        {{ $t(j.title) }}
                      </div>
                    </v-img>

                    <v-img
                      :src="j.icon"
                      contain
                      style="
                        position: absolute;
                        width: 30%;
                        bottom: 15%;
                        left: 7%;
                      "
                    />
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.v-expansion-panel-header {
  padding: 0px;
}
// .v-expansion-panel-content {
//   border-radius: 50px 50px 0 0;
// }
.v-expansion-panel-content__wrap {
  padding: 0px 0px 0px !important;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0 !important;
}
.v-expansion-panel {
  margin-bottom: 20px;
  background-color: transparent !important;
}
.v-expansion-panels {
  z-index: auto;
}
.v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}

.v-expansion-panel::before {
  bottom: auto;
}
.v-image:hover {
  cursor: pointer;
}
</style>

<script>
// @ is an alias to /src
import Card from "@/components/organisms/Card.vue";
import config from "../../../config";
import axios from "axios";

const additionalMenus = [
  {
    type: "kids",
    items: [
      {
        title: "additional cat 1",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#FF8940",
        route: "KidsHome",
        id: 3,
      },
      {
        title: "additional cat 2",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#FF8940",
        route: "KidsHome",
        id: 4,
      },
    ],
  },
  {
    type: "teens",
    items: [
      {
        title: "additional cat 1",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#9AF2D0",
        route: "TeensHome",
        id: 3,
      },
      {
        title: "additional cat 2",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#9AF2D0",
        route: "TeensHome",
        id: 4,
      },
    ],
  },
  {
    type: "adults",
    items: [
      {
        title: "additional cat 1",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#7CE1FF",
        route: "AdultHome",
        id: 6,
      },
      {
        title: "additional cat 2",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#7CE1FF",
        route: "AdultHome",
        id: 7,
      },
    ],
  },
];

export default {
  name: "ExpansionPanels",
  components: {
    Card,
  },
  props: [],
  created() {
    this.fetchData();
  },
  mounted() {
    this.removeAriaExpanded();
  },
  methods: {
    fetchData() {
      this.fetchAdditionalMenus((catIndex, ageIndex) => {
        if (catIndex != null && ageIndex != null) {
          // add additional menu items
          let children = this.btn[ageIndex].children;

          let additionalMenuItem = additionalMenus[ageIndex].items[catIndex];
          if (children.indexOf(additionalMenuItem) == -1) {
            children.push(additionalMenuItem);
          }
          this.btn[ageIndex].children = children;
        }
      });
    },
    changeIcon(num) {
      this.btn[num].isOpen = !this.btn[num].isOpen;
      this.removeAriaExpanded()
    },
    removeAriaExpanded() {
      const elements = document.querySelectorAll('[aria-expanded]');
      elements.forEach(element => {
        element.removeAttribute('aria-expanded');
      });
    },
  },
  data: () => ({
    btn: [
      {
        image: require("@/assets/images/kids/bkg.png"),
        icon: require("@/assets/images/kids/bkg_icon.png"),
        desktop_image: require("@/assets/images/kids/desktop_bkg.png"),
        isOpen: false,
        route: "",
        title: "common.kids",
        subtitle: "common.kidsAge",
        color: "#FF8940",
        darkColor: "#D03A03",
        cardColor: "#FF8E24",
        children: [
          {
            title: "common.watchVideos",
            image: require("@/assets/images/kids/smart_guard.png"),
            icon: require("@/assets/images/kids/smart_guard_icon.png"),
            content: "Watch Films...",
            route: "KidsHome",
            id: 0,
          },
          {
            title: "common.letsPlay",
            image: require("@/assets/images/kids/fun_corner.png"),
            icon: require("@/assets/images/kids/fun_corner_icon.png"),
            content: "Fun Corner...",
            route: "KidsHome",
            id: 1,
          },
          {
            title: "common.learnMore",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            route: "KidsHome",
            id: 2,
          },
          {
            title: "additional cat 1",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            route: "KidsHome",
            id: 3,
            hidden: true,
          },
          {
            title: "additional cat 2",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            route: "KidsHome",
            id: 4,
            hidden: true,
          },
        ],
      },
      {
        image: require("@/assets/images/teens/bkg.png"),
        icon: require("@/assets/images/teens/bkg_icon.png"),
        desktop_image: require("@/assets/images/teens/desktop_bkg.png"),
        isOpen: false,
        route: "",
        title: "common.teens",
        subtitle: "common.teensAge",
        color: "#3D9AF8",
        darkColor: "#1FD568",
        cardColor: "#6BEABA",
        children: [
          {
            title: "common.stayAlert",
            image: require("@/assets/images/teens/smart_guard.png"),
            icon: require("@/assets/images/teens/smart_guard_icon.png"),
            content: "Smart Guard...",
            route: "TeensHome",
            id: 0,
          },
          {
            title: "common.legally",
            image: require("@/assets/images/teens/stay_informed_legally.png"),
            icon: require("@/assets/images/teens/stay_informed_legally_icon.png"),
            content: "Stay Informed Legally...",
            route: "TeensHome",
            id: 1,
          },
          {
            title: "common.chill",
            image: require("@/assets/images/teens/chill_out_corner.png"),
            icon: require("@/assets/images/teens/chill_out_corner_icon.png"),
            content: "Chill Out...",
            route: "TeensHome",
            id: 2,
          },
          {
            title: "additional cat 1",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            route: "TeensHome",
            id: 3,
            hidden: true,
          },
          {
            title: "additional cat 2",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            route: "TeensHome",
            id: 4,
            hidden: true,
          },
        ],
      },
      {
        image: require("@/assets/images/parents/bkg.png"),
        icon: require("@/assets/images/parents/bkg_icon.png"),
        desktop_image: require("@/assets/images/parents/desktop_bkg.png"),
        isOpen: false,
        route: "/Adult",
        title: "common.adults",
        subtitle: "",
        color: "#7AEB27",
        darkColor: "#00B1FF",
        cardColor: "#5DC1FF",
        children: [
          {
            title: "common.stayAlert",
            image: require("@/assets/images/parents/smart_guard.png"),
            icon: require("@/assets/images/parents/smart_guard_icon.png"),
            content: "Smart Guard...",
            route: "AdultHome",
            id: 0,
          },
          {
            title: "common.legally",
            image: require("@/assets/images/parents/stay_informed_legally.png"),
            icon: require("@/assets/images/parents/stay_informed_legally_icon.png"),
            content: "Stay Informed Legally...",
            route: "AdultHome",
            id: 1,
          },
          {
            title: "common.chill",
            image: require("@/assets/images/parents/chill_out_corner.png"),
            icon: require("@/assets/images/parents/chill_out_corner_icon.png"),
            content: "Chill Out...",
            route: "AdultHome",
            id: 2,
          },
          {
            title: "common.learnMore",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            route: "AdultHome",
            id: 3,
          },
          {
            title: "common.teaching_materials",
            image: require("@/assets/images/parents/teaching_materials.png"),
            icon: require("@/assets/images/parents/teaching_materials_icon.png"),
            route: "AdultHome",
            id: 4,
          },
          {
            title: "common.link",
            image: require("@/assets/images/parents/partner_org.png"),
            icon: require("@/assets/images/parents/partner_org_icon.png"),
            content: "Partner Organizations...",
            route: "Partnership",
            id: 5,
          },
          {
            title: "additional cat 1",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            route: "Partnership",
            id: 6,
            hidden: true,
          },
          {
            title: "additional cat 2",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            route: "Partnership",
            id: 7,
            hidden: true,
          },
        ],
      },
    ],
  }),
};
</script>
