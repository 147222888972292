<template>
  <div>
    <v-img
      :src="getImageUrl('helping_hand/helpingHandHeader.png')"
      contain
      width="100%"
      class="d-flex justify-center"
    >
      <div
        class="text-center"
        style="
          margin-top: 0.5%;
          color: white;
          font-size: 4vw;
          font-weight: bold;
        "
      >
        {{ title }}
      </div>
    </v-img>
  </div>
</template>

<style lang="scss">
</style>

<script>
// @ is an alias to /src

export default {
  name: "RibbonTitle",
  components: {},
  props: {
    title: { type: String, default: "" },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    getImageUrl(img) {
      return require(`@/assets/images/${img}`);
    },
  },
  data: () => ({
    //
  }),
};
</script>
