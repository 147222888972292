export const physicalAbuse = {
  title: {
    en: "Physical Abuse",
    tc: "身體虐待",
    sc: "身体虐待"
  },
  img: "hh1.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
      color: "#367399",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Physical Abuse",
        tc: "身體虐待",
        sc: "身体虐待"
      },
      title: {
        en: "Physical Abuse",
        tc: "身體虐待",
        sc: "身体虐待"
      },
      img: "witness-kids-2.png",
      aspectRatio: 336 / 273,
      showTips: true,
      points: [
        {
          en: "Observe more about the behaviour of your children.",
          tc: "多留意子女的行為。",
          sc: "多留意子女的行为。"
        },
        {
          en: "Empower your children to speak out.",
          tc: "鼓勵子女坦率說出他們的事情。",
          sc: "鼓励子女坦率说出他们的事情。"
        },
        {
          en: "Modelling - Parents are to be the models of what you teach your children.",
          tc: "樹立榜樣 – 父母是孩子的學習榜樣。",
          sc: "树立榜样 – 父母是孩子的学习榜样。"
        },
        {
          en: "Your duty to respect children when they say “no”.",
          tc: "當兒童說「不」的時候，你有責任尊重他們的意願。",
          sc: "当儿童说「不」的时候，你有责任尊重他们的意愿。"
        },
        {
          en: "Violence cannot solve issues.",
          tc: "暴力不能解決問題。",
          sc: "暴力不能解决问题。"
        },
        {
          en: "If you found out your children were victim of physical abuse, report the case to police immediately.",
          tc: "如你發現子女是身體虐待的受害者，應該立即報警。",
          sc: "如你发现子女是身体虐待的受害者，应该立即报警。"
        },
      ],
    }
  ],
};
