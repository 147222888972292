export const victim = {
  title: {
    en: "As a Victim…",
    tc: "作為受害人…",
    sc: "common.tbc",
  },
  subTitle: {
    en: "We know how it feels.",
    tc: "我們明白你的感覺",
    sc: "common.tbc",
  },
  img: "victim-teens.png",
  aspectRatio: 364 / 121,
  onCardClick: true,
  children: [
    {
      contentHeader: {
        en: "As a Victim…",
        tc: "作為受害人…",
        sc: "common.tbc",
      },
      content: {
        en: "Seek help\nYou are not alone",
        tc: "求助\n你並不孤單",
        sc: "common.tbc",
      },
      img: "victim-teens-1.png",
      aspectRatio: 366 / 200,
      children: [
        {
          content: {
            en: "Stay calm.\nTell an adult you trust as soon as possible",
            tc: "不用怕\n盡快告訴你信任的成人",
            sc: "common.tbc",
          },
          img: "victim-teens-2.png",
          aspectRatio: 366 / 200,
          children: [
            {
              content: {
                en: "Anyone!\nSuch as parents, teacher, social worker…",
                tc: "他們可以是你的：\n父母、老師或社工",
                sc: "common.tbc",
              },
              img: "victim-teens-3.png",
              aspectRatio: 366 / 200,
              children: [
                {
                  content: {
                    en:
                      "After the adult’s assessment, report the case to police if necessary.\nWe are here to help.",
                    tc:
                      "成人會了解情況，如有需要，會向警方求助，警察會樂意協肋的",
                    sc: "common.tbc",
                  },
                  img: "victim-teens-4.png",
                  aspectRatio: 366 / 200,
                  children: [
                    {
                      content: {
                        en: "We care about your welfare",
                        tc: "我們關心你的福祉",
                        sc: "common.tbc",
                      },
                      img: "victim-teens-5.png",
                      aspectRatio: 366 / 200,
                      points: [
                        {
                          en: "Arrange police of same sex for enquiry",
                          tc: "安排同一性別的警務人員了解情況",
                          sc: "common.tbc",
                        },
                        {
                          en: "Conduct interview at an individual room",
                          tc: "在獨立房間會面",
                          sc: "common.tbc",
                        },
                        {
                          en: "Arrange an appropriate adult",
                          tc: "安排合適成人陪伴",
                          sc: "common.tbc",
                        },
                        {
                          en: "Conduct forensic examination",
                          tc: "安排法醫檢查",
                          sc: "common.tbc",
                        },
                        {
                          en: "Arrange immediate medical treatment",
                          tc: "即時接受治療",
                          sc: "common.tbc",
                        },
                      ],
                      children: [
                        {
                          content: {
                            en: "Police Procedures",
                            tc: "警隊程序",
                            sc: "common.tbc",
                          },
                          img: "victim-teens-6.png",
                          aspectRatio: 366 / 200,
                          points: [
                            {
                              en:
                                "Conduct Video Recorded Interview in a suite with confidential address",
                              tc: "在地點保密的家居錄影會面室進行",
                              sc: "common.tbc",
                            },
                            {
                              en:
                                "Refer the case to SWD/ relevant service organization",
                              tc: "轉介社會福利署或相關服務機構跟進",
                              sc: "common.tbc",
                            },
                            {
                              en: "Join Multi Disciplinary Case Conference",
                              tc: "多專業個案會議",
                              sc: "common.tbc",
                            },
                          ],
                          children: [
                            {
                              img: "victim-teens-7.png",
                              aspectRatio: 366 / 200,
                              content: {
                                en: "Court Procedures",
                                tc: "法庭程序",
                                sc: "common.tbc",
                              },
                              points: [
                                {
                                  en: "Give evidence through Live TV Link",
                                  tc: "透過電視直播進行",
                                  sc: "common.tbc",
                                },
                                {
                                  en:
                                    "Witness Support Programme  (Support Person)",
                                  tc: "支援者的支援證人計劃",
                                  sc: "common.tbc",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
