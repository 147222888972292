<template>
  <v-container class="mt-0 pt-0">
    <v-img
      :src="getImageUrl('helpingHandBackground1.png')"
      class="align-center justify-center d-flex"
      :height="isMobilePhone ? '80px' : '120px'"
      cover
      id="bg"
    >
      <v-container
        class="align-center justify-center d-flex"
        style="flex: 1; position: relative"
      >
        <div>
          <v-img
            :src="getImageUrl('helpingHandHeader.png')"
            contain
            width="80vw"
            max-width="650"
            class="d-flex justify-center"
          >
            <div
              class="text-center"
              style="color: #644d3f; font-size: 22px; font-weight: bold"
              :style="{
                'font-size': isMobilePhone ? '20px' : '40px',
              }"
            >
              {{ title != "" ? title : $t("common.needHelp") }}
            </div>
          </v-img>
        </div>

        <div style="position: absolute; bottom: 0; right: 0">
          <v-img
            :src="require('@/assets/images/GG-1.png')"
            :width="isMobilePhone ? 70 : 90"
            v-if="!isKids"
          />
          <v-img
            :src="require('@/assets/images/hh_gif.gif')"
            :width="isMobilePhone ? 90 : 140"
            v-else
          />
        </div>
      </v-container>
    </v-img>

    <div :style="{ height: isMobilePhone ? '80px' : '120px' }" />
    <v-col>
      <v-row class="justify-center">
        <!-- <v-col cols="6" v-if="!showRibbonHeader && showBack">
          <v-btn
            class="back_btn"
            @click="back"
            outlined
            rounded
            color="#367399"
          >
            <v-icon size="16">mdi-chevron-left</v-icon>
            {{ $t("common.back") }}</v-btn
          >
        </v-col>
        <v-spacer />
        <v-col
          v-if="!showRibbonHeader && showNext"
          style="text-align: end"
        >
          <v-btn
            class="back_btn"
            @click="next"
            outlined
            rounded
            color="#367399"
          >
            {{ $t("common.go") }}
            <v-icon size="16">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col> -->

        <v-col
          :class="isMobile ? 'pa-2' : 'pa-6'"
          v-for="(card, i) in flowStacks[flowStacks.length - 1]"
          :key="i"
          :cols="card.col ? card.col : 12"
          @click="onCardClick(card)"
        >
          <CommonImageCard
            :image="card.img"
            :btns="mapDataBtnsToProps(card.btns, card.children)"
            :title="t(card.title)"
            :content="t(card.content)"
            :showReadMore="card.showReadMore"
            :points="translatePoints(card.points)"
            :showTips="card.showTips"
            :subTitle="t(card.subTitle)"
            :contentMinHeight="flowStacks.length > 1"
            :aspectRatio="card.aspectRatio"
            :onCardClick="card.onCardClick"
            :containerStyle="card.style"
            :showAllContent="true"
          />
        </v-col>
      </v-row>
    </v-col>
    <div
      style="position: fixed; right: 0; top: 80%; width: 100%; pointer-events: none"
    >
      <v-container class="d-flex justify-space-between">
        <v-btn
          v-if="!showRibbonHeader && showBack"
          class="mx-2"
          fab
          dark
          color="primary"
          style="min-width: 25px !important; width: 45px; height: 45px; pointer-events: auto;"
          @click="back"
          aria-label="back"
        >
          <v-icon dark > mdi-chevron-left </v-icon>
        </v-btn>

        <v-btn
          v-if="!showRibbonHeader && showNext"
          class="mx-2"
          fab
          dark
          color="primary"
          style="min-width: 25px !important; width: 45px; height: 45px; pointer-events: auto;"
          @click="next"
          aria-label="next"
        >
          <v-icon dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-container>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
#bg {
  width: 100vw;
  position: absolute;
  margin-top: -12px;
  left: 0;
}
#header-tx {
  color: white;
  font-size: 50px;
  text-shadow: -1px 0 #afafaf, 0 1px #afafaf, 1px 0 #afafaf, 0 -1px #afafaf;
}
.header-tx-xs {
  font-size: 35px !important;
}
.v-btn {
  //   height: 24px !important;
  min-width: 100px !important;
}
.back_btn {
  background-color: #fff;
  font-size: 12px;
}
</style>

<script>
// @ is an alias to /src
import CommonImageCard from "@/components/organisms/CommonImageCard.vue";
import RibbonTitle from "@/components/molecules/RibbonTitle.vue";
// import flowMap from "./flowMap";
// import flowMap from "./flowMap/index";

// console.log('flowMap', flowMap)

export default {
  name: "TeensHelpingHand",
  components: {
    CommonImageCard,
    RibbonTitle,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    locale() {
      return this.$i18n.locale;
    },
    isKids() {
      const name = this.$route.name.toString().toLowerCase();
      return name.includes("kids");
    },
  },
  methods: {
    onCardClick(card) {
      if (card.onCardClick) {
        if (card.children) {
          this.flowStacks.push(card.children);
          this.updateShowNext(card.children);
        }
      }
    },
    translatePoints(points) {
      let _points = [];
      points &&
        points.map((point) => {
          _points.push(this.t(point));
        });
      return _points;
    },
    t(obj) {
      if (typeof obj == "object") {
        let tx = obj[this.$i18n.locale];
        if (tx == "common.tbc") {
          return this.$t("common.tbc");
        }
        return tx;
      } else if (typeof obj == "string") {
        return obj;
      }
      return "";
    },
    openUrl(url) {
      if (url) {
        // window.location.href = url;
        window.open(url, "_blank").focus();
      }
    },
    getImageUrl(img) {
      return require(`@/assets/images/helping_hand/${img}`);
    },
    back() {
      if (this.flowStacks.length > 1) {
        this.flowStacks.pop();
        this.updateShowNext(this.flowStacks[this.flowStacks.length - 1]);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      }
    },
    next() {
      const curFlow = this.flowStacks[this.flowStacks.length - 1];
      if (curFlow && curFlow.length > 0) {
        // console.log("curFlow.children", curFlow[0].children);
        this.flowStacks.push(curFlow[0].children);
        this.updateShowNext(curFlow[0].children);
        this.showBack = true;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      }
    },
    scrollTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    },
    updateTitle() {
      const flowStacks = this.flowStacks;
      if (flowStacks.length == 1) {
        this.title = "";
      } else if (flowStacks.length == 3) {
        this.title = this.t(flowStacks[flowStacks.length - 1][0].contentHeader);
      }
    },
    updateShowNext(children) {
      this.updateTitle();
      // console.log(children);
      let noOfChildren = 0;
      let containBtnChildren = false;
      children &&
        children.map((chi) => {
          if (chi.children) {
            noOfChildren++;
          }
          if (chi.btns) {
            chi.btns.map((btn) => {
              if (btn.children) {
                noOfChildren++;
                containBtnChildren = true;
              }
            });
          }
        });
      if (noOfChildren == 1 && !containBtnChildren) {
        this.showNext = true;
      } else {
        this.showNext = false;
      }
    },
    mapDataBtnsToProps(btns, children) {
      if (btns && btns.length > 0) {
        let _btns = [];

        btns.map((btn) => {
          _btns.push({
            ...btn,
            onClick: () => {
              // console.log("btn.children", btn.children);
              if (children) {
                this.flowStacks.push(children);
                // console.log(children);
                this.updateShowNext(children);
              } else if (btn.children) {
                this.flowStacks.push(btn.children);
                // console.log(btn.children);
                this.updateShowNext(btn.children);
              } else if (btn.href) {
                window.open(btn.href);
              } else if (btn.route) {
                this.$router.push({ name: btn.route });
              }
            },
          });
        });

        return _btns;
      }

      return [];
    },
  },
  props: {
    flowMap: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // console.log("flowMap", flowMap);
    this.flowStacks = [this.flowMap];

    // console.log("flowStacks", this.flowStacks);
    // console.log(this.flowStacks[0]);
  },
  data: () => ({
    //
    flowStacks: [],
    showRibbonHeader: true,
    showNext: true,
    showBack: true,
    title: "",
  }),
  watch: {
    flowStacks: function(cur, prev) {
      if (cur.length < 2) {
        this.showRibbonHeader = true;
      } else {
        this.showRibbonHeader = false;
      }
    },
    locale: function(newLocale, oldLocale) {
      this.updateTitle();
    },
    "$route.query.t"(val) {
      this.flowStacks = [this.flowMap];
    },
  },
};
</script>
