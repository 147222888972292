import { physicalAbuse } from "../../HelpingHand/flowMap/physicalAbuse"
import { cybercrime } from "../../HelpingHand/flowMap/cybercrime"
import { sexualAbuse } from "../../HelpingHand/flowMap/sexualAbuse"
import { cyberbullying } from "../../HelpingHand/flowMap/cyberbullying"
import { victim } from './victim'
import { witness } from './witness'

const flowMap = [
  physicalAbuse,
  cybercrime,
  sexualAbuse,
  cyberbullying,
  {
    title: {
      en: "What to do?",
      tc: "點算好?",
      sc: "怎么办"
    },
    img: "whatToDo.png",
    col: 6,
    btns: [
      {
        tx: "common.go",
      },
    ],
    children: [
      {
        style: { display: 'none' },
      },
      victim,
      witness
    ]
  },
]

export default flowMap