<template>
  <div class="login"></div>
</template>

<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: white;
}
</style>

<script>
// @ is an alias to /src
import { LETS_TALK_PW, password } from "../../../credential";

export default {
  name: "login",
  data: () => ({
    padless: false,
    variant: "default",
  }),
  created: function () {
    let pwd = sessionStorage.getItem(LETS_TALK_PW);
    // prompt("Private Website\nPlease Enter the Password:");
    const proptPassword = () => {
      if (pwd != password) {
        pwd = prompt("Private Website\nPlease Enter the Password:");
        proptPassword();
      } else {
        sessionStorage.setItem(LETS_TALK_PW, pwd);
        window.location.href = window.location.href.replace("login", "");
      }
    };

    proptPassword();
  },
};
</script>
