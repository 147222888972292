<template>
  <div
    :class="
      $i18n.locale == 'en'
        ? 'c-en'
        : $i18n.locale == 'tc'
        ? 'c-tc'
        : $i18n.locale == 'sc'
        ? 'c-sc'
        : 'c-en'
    "
  >
    <v-app
      :style="{ 'background-color': bkgColor }"
      v-if="$route.name != 'health'"
    >
      <Header />

      <v-container>
        <v-row>
          <v-col>
            <router-view
              :style="{ 'margin-bottom': isMobile ? '20px' : '0' }"
            />
          </v-col>
        </v-row>
      </v-container>

      <Chatbot />

      <v-card
        class="overflow-hidden mx-auto"
        :height="windowWidth * (isMobile ? 0.15 : 0.1)"
        v-if="isMobile"
      >
        <BottomNavigation />
      </v-card>
    </v-app>
    <p v-else>TEST</p>
  </div>
</template>

<style lang="scss">
.v-application {
  font-family: Kanit !important;
  background-size: 100% !important;
}
.v-btn:not(.v-btn--round).v-size--small {
  height: 36px;
  min-width: 100px;
  font-size: 12px;
}
</style>

<script>
import Header from "@/components/organisms/Header.vue";
import Banner from "./components/organisms/Banner.vue";
import JumpingJackBanner from "./components/organisms/JumpingJackBanner.vue";
import Breadcrumbs from "./components/molecules/Breadcrumbs.vue";
import BottomNavigation from "./components/molecules/BottomNavigation.vue";
import Footer from "./components/organisms/Footer.vue";
import Chatbot from "./components/organisms/Chatbot/Chatbot.vue";

export default {
  name: "App",
  metaInfo: {
    meta: [
      {
        name: "keywords",
        content:
          "childprotection, childprotections, childprotect, 保護兒童, 愛中成長, 保護兒童 愛中成長, fcsv, 家庭衝突及性暴力政策組, kidsprotection, protect our next generation with love, 保護兒童計劃, 童心同行, 幼兒, 幼童, 兒童, 小童, 保護, 少年, 年青人, 青少年, 傾計, 上網, 網絡, 刑事, 性, 性暴力, 身體虐待, 欺凌, 求助, 幫緊你, 戀童癖, 師生戀, 亂倫, 學生, 父母, 家長, child, children, kids, protection, youth, teens, protect, talk, T.A.L.K., online, cyber, crime, sexual, physical, bullying, bully, need help",
      },
      {
        name: "description",
        content:
          "童行‧同心 Let's T.A.L.K. 「童行‧同心」是一個以保護年輕一代為主題的計劃，致力提供一站式資訊從而提升全民對保護兒童的意識，同時期盼著兒童學會建立自己的安全網。",
      },
    ],
  },
  components: {
    Header,
    Banner,
    Breadcrumbs,
    BottomNavigation,
    JumpingJackBanner,
    Footer,
    Chatbot,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    bkgColor() {
      if (this.currentRouteName.includes("Kids")) {
        return "#ffebb3";
      }

      if (this.currentRouteName.includes("Teens")) {
        return "#e6ffd8";
      }

      return "#d8f4ff";
    },
  },
  methods: {
    changeLang(value) {
      this.$store.commit("changeLang", value);
      this.$i18n.locale = value;
      console.log(this.$i18n.locale);
    },
  },
  created() {
    console.log("lang", this.$route?.query?.lang);
    if (!!this.$route?.query?.lang) {
      this.changeLang(this.$route?.query?.lang?.toLowerCase());
      let query = Object.assign({}, this.$route.query);
      delete query.lang;
      this.$router.replace({ query });
    }
  },
  mounted() {
    this.$i18n.locale = this.$store.state.lang;
  },
};
</script>
