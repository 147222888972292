var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('BackButton'),_c('div',{staticClass:"unselectable",class:{ desktop: !_vm.isMobile, mobile: _vm.isMobile }},[_c('v-responsive',{attrs:{"id":"t1","aspect-ratio":1028 / 577,"width":"100%"}},[_c('v-img',{attrs:{"src":require('@/assets/images/game/game1/game1_bg1.png')}},[_c('v-row',{staticClass:"align-center pa-3"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{staticClass:"text-center align-center",attrs:{"id":"timer","src":require('@/assets/images/game/game1/timer.png'),"contain":""}},[_c('div',{staticClass:"timer-text"},[_vm._v(" "+_vm._s(_vm.time + _vm.$t("game1.s"))+" ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{staticClass:"align-center text-center",attrs:{"id":"header","src":require('@/assets/images/game/game1/game_header1.png'),"contain":"","width":"75%"}},[_c('div',{staticClass:"stage-text"},[_vm._v(" "+_vm._s(_vm.$t("game1.level"))+" "+_vm._s(_vm.currentLevel)+" ")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{attrs:{"id":"close_btn","src":require('@/assets/images/game/game1/game1_close_btn.png')},on:{"click":_vm.goToGameCenter}})],1)],1),_c('v-img',{style:({ display: _vm.displayTick ? 'block' : 'none' }),attrs:{"id":"tick","src":require('@/assets/images/game/game1/tick.png'),"contain":""}}),_c('v-img',{style:({ display: _vm.displayCross ? 'block' : 'none' }),attrs:{"id":"cross","src":require('@/assets/images/game/game1/cross.png'),"contain":""}}),_c('v-row',{staticClass:"cards",staticStyle:{"margin":"auto","height":"70%"},style:({ width: _vm.containerWidth }),attrs:{"id":"card-container"}},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","justify-content":"center","align-items":"center","margin":"auto"},attrs:{"id":"t2"}},_vm._l((_vm.cards),function(card,index){return _c('span',{key:index,staticClass:"card",style:({ padding: _vm.cardPadding })},[_c('div',{staticClass:"card",class:{ flipped: card.flipped, found: card.found },style:({
                  width: _vm.isMobile ? '50px' : '96px',
                  height: _vm.isMobile ? '70px' : '135px',
                  width: _vm.cardContainerWidth / _vm.cardWidthDivider + 'px',
                  height:
                    ((_vm.cardContainerWidth / _vm.cardWidthDivider) * 235) / 167 +
                    'px',
                }),on:{"click":function($event){return _vm.flipCard(card)}}},[_c('div',{staticClass:"back"}),_c('div',{staticClass:"front"},[_c('v-img',{attrs:{"src":card.image,"contain":"","aspect-ratio":167 / 235,"width":"200px"}})],1)])])}),0)]),(_vm.showSplash)?_c('GameDialog',{attrs:{"title":_vm.dialog.title,"subtitle":_vm.dialog.subtitle,"headerText":_vm.dialog.headerText,"success":_vm.dialog.success,"leftButtonText":_vm.dialog.leftButtonText,"rightButtonText":_vm.dialog.rightButtonText,"leftButtonAction":_vm.dialog.leftButtonAction,"rightButtonAction":_vm.dialog.rightButtonAction,"wrapperStyle":{ top: '17%' }}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }