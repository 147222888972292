<template>
  <div>
    <BackButton />
    <!-- 
    <div id="abc" class="d-flex justify-center align-center">
      <video-player
        class="video-player-box vjs-big-play-centered"
        ref="videoPlayer"
        :options="
          selectedItem == 1
            ? playerOptions
            : selectedItem == 2
            ? playerOptions2
            : selectedItem == 3
            ? playerOptions3
            : playerOptions4
        "
        :playsinline="true"
        @ready="playerReadied"
      >
      </video-player>
    </div>

    <div class="d-flex justify-end mt-3 text-caption">
      <div>( {{ $t("common.cantoneseOnly") }} )</div>
    </div>

    <div class="text-center" v-for="(i, index) in videoContent" :key="index">
      <div v-if="selectedItem == index + 1">
        <v-card-text class="text-h5">
          {{ getText(i.title) }}
        </v-card-text>

        <span v-if="selectedItem == index + 1">
          {{ getText(i.desc) }}
        </span>
      </div>
    </div> -->

    <div v-for="(i, index) in data" :key="index">
      <div v-html="fixWordpressUrl(getText(i.content))" style="margin-bottom:60px"></div>
      <div class="d-flex justify-end mt-3 text-caption">
        <div>( {{ $t("common.cantoneseOnly") }} )</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -1em;
  height: 3em;
}
</style>

<script>
import Banner from "@/components/organisms/Banner.vue";
import VuePdfApp from "vue-pdf-app";
import BackButton from "@/components/atoms/BackButton.vue";
import "vue-pdf-app/dist/icons/main.css";
import config from "/config";
import axios from "axios";

// @ is an alias to /src
export default {
  name: "Materials",
  components: {
    VuePdfApp,
    BackButton,
  },
  props: {
    text: String,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    let path = this.$route.fullPath;
    // let id = path.substr(path.indexOf("=") + 1, path.length).split("&")[0];
    // this.selectedItem = id;
  },
  methods: {
    playerReadied(player) {
      // player.play()
    },
    getText(item) {
      if (this.$i18n.locale == "en") {
        return item.en;
      } else if (this.$i18n.locale == "tc") {
        return item.tc;
      } else {
        return item.sc;
      }
    },
    getRImageUrl(img) {
      return require(`@/assets/images/linking/adults/${img}`);
    },
    fetchData() {
      axios
        .get(config.wordpressUrl + "/adults_kol_says?order=asc&per_page=100")
        .then((response) => {
          const id = this.$route.query.id;
          console.log("adults_kol_says", response.data);

          let materials = [];

          const data = response.data;
          if (data) {
            data.map((item) => {
              let category = item.acf.category;

              if (category != null && category.ID == id) {
                materials.push({
                  content: this.getFieldFromResp(item, "html"),
                });
              }
            });

            this.data = materials;
          }
        });
    },
  },
  data() {
    return {
      selectedItem: null,
      // playerOptions: {
      //   // videojs options
      //   muted: true,
      //   language: "en",
      //   playbackRates: [0.7, 1.0, 1.5, 2.0],
      //   sources: [
      //     {
      //       type: "video/mp4",
      //       src: "./materials_1.mp4",
      //     },
      //   ],
      //   width:
      //     window.innerWidth *
      //     (this.$vuetify.breakpoint.name == "xs" ||
      //     this.$vuetify.breakpoint.name == "sm"
      //       ? 0.9
      //       : 0.5),
      // },
      // playerOptions2: {
      //   // videojs options
      //   muted: true,
      //   language: "en",
      //   playbackRates: [0.7, 1.0, 1.5, 2.0],
      //   sources: [
      //     {
      //       type: "video/mp4",
      //       src: "./materials_2.mp4",
      //     },
      //   ],
      //   width:
      //     window.innerWidth *
      //     (this.$vuetify.breakpoint.name == "xs" ||
      //     this.$vuetify.breakpoint.name == "sm"
      //       ? 0.9
      //       : 0.5),
      // },
      // playerOptions3: {
      //   // videojs options
      //   muted: true,
      //   language: "en",
      //   playbackRates: [0.7, 1.0, 1.5, 2.0],
      //   sources: [
      //     {
      //       type: "video/mp4",
      //       src: "./materials_3.mp4",
      //     },
      //   ],
      //   width:
      //     window.innerWidth *
      //     (this.$vuetify.breakpoint.name == "xs" ||
      //     this.$vuetify.breakpoint.name == "sm"
      //       ? 0.9
      //       : 0.5),
      // },
      // playerOptions4: {
      //   // videojs options
      //   muted: true,
      //   language: "en",
      //   playbackRates: [0.7, 1.0, 1.5, 2.0],
      //   sources: [
      //     {
      //       type: "video/mp4",
      //       src: "./materials_4.mp4",
      //     },
      //   ],
      //   width:
      //     window.innerWidth *
      //     (this.$vuetify.breakpoint.name == "xs" ||
      //     this.$vuetify.breakpoint.name == "sm"
      //       ? 0.9
      //       : 0.5),
      // },
      data: {},
      // videoContent: [
      //   {
      //     title: {
      //       en: "Internet-initiated sexual and naked chat crimes of children",
      //       tc: "網絡引起的兒童性罪行及裸聊騙案簡報",
      //       sc: "网络引起的儿童性犯罪及裸聊骗案简报",
      //     },
      //     desc: {
      //       en:
      //         "People often fall for the same traps.  Culprits often use different excuses to win the trust of children, and wait for the opportunity to gain what they want…",
      //       tc:
      //         "橋唔怕舊，總是有人會受。不法之徒往往會利用不同的藉口去博取兒童的信任，然後伺機下手…",
      //       sc:
      //         "桥段不怕旧，总是有人会受。不法之徒往往会利用不同的借口去博取儿童的信任，然后伺机下手…",
      //     },
      //   },
      //   {
      //     title: {
      //       en: "How to protect children from online sexual crimes",
      //       tc: "如何保護兒童免受網絡性罪行威脅",
      //       sc: "如何保护儿童免受网络性犯罪威胁",
      //     },
      //     desc: {
      //       en:
      //         "Everyone has equal chance of being deceived.  Learn how to safeguard children in the cyber world.",
      //       tc: "人人受騙機會一樣有。讓孩子學習結識網友要留神！",
      //       sc: "人人受骗机会一样有。让孩子学习结识网友要留神！",
      //     },
      //   },
      //   {
      //     title: {
      //       en: "Learn more about Telephone Deception",
      //       tc: "「電話騙案」手法簡報",
      //       sc: "「电话骗案」手法简报",
      //     },
      //     desc: {
      //       en: "There is no difficulty to debunk fraudulent calls.",
      //       tc: "揭穿詐騙來電，其實唔難。",
      //       sc: "揭穿诈骗来电，其实不难。",
      //     },
      //   },
      //   {
      //     title: {
      //       en: "Learn more about Employment Fraud",
      //       tc: "「求職騙案」手法簡報",
      //       sc: "「求职骗案」手法简报",
      //     },
      //     desc: {
      //       en:
      //         "The Anti-Deception Coordination Center (ADCC) introduces the common types and methods of employment fraud to arouse everyone’s awareness.",
      //       tc:
      //         "反詐騙協調中心為你介紹常見的求職騙案類型及手法，希望求職者小心留意，提高警覺。",
      //       sc:
      //         "反诈骗协调中心为你介绍常见的求职骗案类型及手法，希望求职者小心留意，提高警觉。",
      //     },
      //   },
      // ],
    };
  },
};
</script>
