<template>
  <div>
    <div class="justify-center align-center mb-6">
      <v-img
        :src="filterValue(item.image)"
        :height="isMobile ? 'auto' : '80vh'"
        :width="isMobile ? 'auto' : '80vw'"
        contain
        style="margin-left: auto; margin-right: auto; margin-bottom: 30px"
      >
      </v-img>
      <span v-html="fixWordpressUrl(filterValue(item.desc))"></span><br />
      <BulletPointReadMore :details="[{ title: filterValue(item.more) }]" />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import BackButton from "@/components/atoms/BackButton.vue";
import BulletPointReadMore from "@/components/molecules/BulletPointReadMore.vue";

export default {
  components: {
    BackButton,
    BulletPointReadMore,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
};
</script>
