export const cybercrime = {
  title: {
    en: "Cybercrime",
    tc: "網絡罪案",
    sc: "网络罪案",
  },
  img: "hh2.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Cybercrime",
        tc: "網絡罪案",
        sc: "网络罪案",
      },
      title: {
        en: "Cybercrime",
        tc: "網絡罪案",
        sc: "网络罪案",
      },
      img: "teens_cybercrime.png",
      aspectRatio: 336 / 271,
      showTips: true,
      points: [
        {
          en: "Do not share your photos or videos online to strangers.",
          tc: "不要在網上分享相片或影片予任何陌生人。",
          sc: "不要在网上分享相片或影片予任何陌生人。",
        },
        {
          en:
            "Protect your data privacy.  Do not disclose your personal particulars or sensitive data to people you do not trust!",
          tc: "保障個人資料私隱。不要向你不信任的人士披露個人資料或敏感資料。",
          sc: "保障个人资料私隐。不要向你不信任的人士披露个人资料或敏感资料。",
        },
        {
          en:
            "Do not visit suspicious or unsecured websites or download unknown software/ applications.",
          tc: "不要登入可疑或不穩妥網頁或下載不明軟件／應用程式。",
          sc: "不要登入可疑或不稳妥网页或下载不明软件／应用程序。",
        },
        {
          en: "Change passwords regularly. Enhance your cyber security.",
          tc: "定期更改密碼，增強你的網絡安全性。",
          sc: "定期更改密码，增强你的网络安全性。",
        },
      ],
    },
  ],
};
