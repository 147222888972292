<script src="jquery-3.6.0.min.js"></script>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>

<template>
  <div class="home">
    <BackButton />
    <div :class="{ desktop: !isMobile, mobile: isMobile }" class="unselectable">
      <v-img :src="require('@/assets/images/game/game2/bg.png')">
        <v-row class="mt-1">
          <v-col cols="3">
            <v-img
              :src="require('@/assets/images/game/game2/timer.png')"
              class="timer text-center align-center"
              contain
            >
              <div class="timer-text">{{ time + $t("game2.s") }}</div>
            </v-img>
          </v-col>
          <v-col cols="6">
            <v-img
              :src="require('@/assets/images/game/game2/header.png')"
              class="text-center align-center"
              contain
            >
              <div class="stage-text">{{ $t("game2.level") }} {{ level }}</div>
            </v-img>
          </v-col>

          <v-col cols="3">
            <v-img
              :src="require('@/assets/images/game/game2/close_btn.png')"
              class="text-center align-center"
              width="30%"
              style="margin: auto"
              @click="goToHome"
            >
            </v-img>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="2" class="mt-6">
            <v-img
              :src="require('@/assets/images/game/game2/correct_box.png')"
              class="text-center align-center"
              contain
              style="width: 100%"
            >
              <div class="d-flex justify-center">
                <div style="display:inline-flex">
                  <v-icon class="score-icon" color="red">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </div>
                <div class="score">
                  {{ score }}/{{
                    this.levelData ? this.levelData.position.length : "-"
                  }}
                </div>
              </div>
            </v-img>
          </v-col>
          <v-col cols="9">
            <v-img
              :src="require('@/assets/images/game/game2/container.png')"
              class="text-center align-center"
            >
              <div id="photomap">
                <v-img
                  id="img-1"
                  :src="
                    levelData
                      ? require(`@/assets/images/game/game2/${levelData.img}`)
                      : ''
                  "
                  class="text-center align-center"
                  width="80%"
                  contain
                  style="margin: auto; position: relative"
                  @click="imageClick"
                >
                  <canvas
                    id="myCanvas"
                    style="
                      pointer-events: none;
                      position: absolute;
                      width: 50%;
                      height: 100%;
                      top: 0;
                      left: 50%;
                    "
                  />
                </v-img>
              </div>
            </v-img>
          </v-col>
        </v-row>

        <GameDialog
          v-if="showSplash"
          :title="dialog.title"
          :subtitle="dialog.subtitle"
          :headerText="dialog.headerText"
          :success="dialog.success"
          :leftButtonText="dialog.leftButtonText"
          :rightButtonText="dialog.rightButtonText"
          :leftButtonAction="dialog.leftButtonAction"
          :rightButtonAction="dialog.rightButtonAction"
          :wrapperStyle="{ top: '25%' }"
        />
      </v-img>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BackButton from "@/components/atoms/BackButton.vue";
import ExpansionPanels from "@/components/atoms/ExpansionPanels.vue";
import GameData from "@/components/pages/ChillOutCorner/GameData.json";
import GameDialog from "@/components/pages/ChillOutCorner/GameDialog.vue";
import _ from "lodash";
import moment from "moment";
var $ = require("jquery");

export default {
  name: "Game2",
  components: {
    BackButton,
    ExpansionPanels,
    GameDialog,
  },

  data: () => ({
    startTime: false,
    timer: null,
    time: 120,
    score: 0,
    level: 1,
    levelData: null,
    showSplash: true,
    dialog: {
      title: "",
      subtitle: "",
      headerText: "",
      success: true,
      leftButtonText: "",
      rightButtonText: "",
      leftButtonAction: () => {},
      rightButtonAction: () => {},
    },
  }),

  photo: [],

  photopool: [],

  created: function() {
    console.log("This is start");
  },
  created() {
    //window.addEventListener("resize", this.setCanvas);
  },
  destroyed() {
    //window.removeEventListener("resize", this.setCanvas);
  },
  mounted: function() {
    this.setCanvas();
    this.gameInit();
  },

  methods: {
    drawCircle(x, y, z) {
      var _w = $("#myCanvas").outerWidth();
      var _h = ($("#img-1").outerWidth() * 272) / 450;

      var c = document.getElementById("myCanvas");
      let w_ratio = _w / 274;
      let h_ratio = _h / 272.438;
      var ctx = c.getContext("2d");
      ctx.beginPath();
      ctx.arc(x * w_ratio, y * h_ratio, z * w_ratio, 0, 2 * Math.PI);
      ctx.strokeStyle = "#FF0000";
      ctx.stroke();
    },
    goToHome() {
      // this.$router.push("/ChillOutCorner");
      this.$router.go(-1);
    },
    scoreCounter() {
      this.score++;
      if (this.score == this.levelData.position.length) {
        this.levelComplete();
      }
    },
    gameInit() {
      this.dialog.headerText = null;
      this.dialog.title = "game2.welcome";
      this.dialog.subtitle = "game2.youhave120s";
      this.dialog.leftButtonText = "game2.play";
      this.dialog.leftButtonAction = this.startGame;
      this.dialog.rightButtonText = "game2.playlater";
      this.dialog.rightButtonAction = this.goToHome;
    },
    clearContext() {
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      ctx.clearRect(0, 0, c.width, c.height);
    },
    resetgame() {
      this.showSplash = false;
      this.stardrd = false;
      this.score = 0;
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          this.stopTimer();
          this.timeIsUp();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    timeIsUp() {
      this.stopTimer();
      (this.dialog.success = false), (this.dialog.title = null);
      this.dialog.headerText = "game2.timeup";
      this.dialog.subtitle = "game2.gamefail";
      this.dialog.leftButtonText = "game2.tryagain";
      this.dialog.leftButtonAction = this.startGame;
      this.dialog.rightButtonText = "game2.leave";
      this.dialog.rightButtonAction = this.goToHome;
      this.showSplash = true;
    },
    startGame() {
      this.time = 120;
      this.score = 0;
      this.showSplash = false;
      this.getLevelData(this.level);
      this.clearContext();
      this.startTime = moment();
      this.startTimer();
    },
    nextLevel() {
      if (this.level < 3) {
        this.level++;
      } else {
        this.level = 1;
      }
      this.startGame();
    },
    levelComplete() {
      this.stopTimer();
      this.dialog.title = null;

      this.dialog.headerText = "game2.welldone";
      this.dialog.subtitle = "game2.level" + this.level;
      this.dialog.leftButtonText = "game2.play";

      if (this.level == 3) {
        this.dialog.headerText = "game2.amazing";
        this.dialog.leftButtonText = "game2.playagain";
      }

      (this.dialog.success = true),
        (this.dialog.leftButtonAction = this.nextLevel);
      this.dialog.rightButtonText = "game2.leave";
      this.dialog.rightButtonAction = this.goToHome;
      setTimeout(() => {
        this.showSplash = true;
      }, 500);
    },
    imageClick(event) {
      var _w = $("#img-1").width();
      var _h = $("#img-1").height();
      let w_ratio = _w / 450;
      let h_ratio = _h / 272.44;
      var x = event.pageX - $("body").scrollLeft() - $("#img-1").offset().left;
      var y = event.pageY - $("body").scrollTop() - $("#img-1").offset().top; //event.clientY - $("#img-1").offset().top;

      console.log("x " + x / w_ratio);
      console.log("y " + y / h_ratio);
      this.levelData.position.every((set) => {
        if (
          set.click.x * w_ratio <= x &&
          x <= (set.click.x + set.click.z * w_ratio) * w_ratio
        ) {
          if (
            set.click.y * h_ratio <= y &&
            y <= (set.click.y + set.click.z * w_ratio) * h_ratio
          ) {
            if (!set.found) {
              this.drawCircle(set.draw.x, set.draw.y, set.draw.z);
              set.found = true;
              this.scoreCounter();
              return false;
            }
          }
        }
        return true;
      });
    },
    getLevelData(level) {
      let list = GameData["level" + level]; // ["level" + 1] = test level
      this.levelData = list[Math.floor(Math.random() * list.length)];
      this.levelData.position.forEach((item) => {
        item.found = false;
      });
    },
    setCanvas() {
      var _w = $("#myCanvas").outerWidth();
      var _h = ($("#img-1").outerWidth() * 272) / 450;

      var c = document.getElementById("myCanvas");
      c.width = _w;
      c.height = _h;
    },
  },
  computed: {
    levelImg() {
      return require(this.levelData.img);
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  font-family: "courier new";
  text-align: center;
  display: flex;
}

.unselectable {
  user-select: none;
}

.timer-text {
  color: #fff;
  margin-left: 20%;
  font-size: 1.5em;
  text-shadow: 0 0 2px rgba(0, 0, 0, 2);
}

.timer {
  width: "100%";
}
.mobile .timer-text {
  font-size: 3vw;
}

.stage-text {
  color: #fff;
  font-size: 1.5em;
  text-shadow: 0 0 2px rgba(0, 0, 0, 2);
}

.mobile .stage-text {
  font-size: 3vw;
}

.score {
  font-size: 1.8em;
  display: inline-flex;
}

.mobile .score {
  font-size: 2.5vw;
}

.score-icon {
  font-size: 2em;
}

.mobile .score-icon {
  font-size: 3vw;
}

.mobile [class^="col"] {
  padding: 0;
}
</style>
