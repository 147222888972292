<template>
  <v-card class="text-left mt-4">
    <v-row class="align-center">
      <v-col cols="5" class="pt-0 pb-0">
        <v-img :src="filterValue(items.image)" contain style="border-radius: 4px"></v-img>
      </v-col>
      <v-col cols="7" class="pt-0 pb-0">
        <div>
          <div
            class="d-flex justify-center"
            style="margin: auto; padding: 16px; font-size: 18px"
          >
            {{ $t(filterValue(items.title)) }}
          </div>
        </div>
        <div></div>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card__title {
  justify-content: center !important;
  word-break: normal !important;
}
// .v-sheet.v-card:not(.v-sheet--outlined) {
//   box-shadow: none;
// }
// .v-card__subtitle,
// .v-card__text,
// .v-card__title {
//   padding-left: 0px;
// }
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-sheet.v-card {
  border-radius: 10px !important;
}
</style>

<script>
import FastAverageColor from "fast-average-color";
let fac;
// @ is an alias to /src

export default {
  name: "Card",
  components: {},
  props: {
    items: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowMore: {
      type: Boolean,
      default: false,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    showDescn: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openUrl(url) {
      if (url) {
        // window.location.href = url;
        window.open(url, "_blank").focus();
      }
    },
    async getMainImageColor(img) {
      let mainColor = "#042851";
      fac = new FastAverageColor();

      await fac
        .getColorAsync(img, {
          ignoredColor: [
            [255, 255, 255, 255], // white
            [0, 0, 0, 255], // black
            [183, 183, 183, 255], // grey
            [221, 88, 74, 255], // red
            [140, 163, 223, 255], //blue
          ],
        })
        .then((color) => {
          // console.log("Average color", color);

          mainColor = color.hex;
          this.btnColor = color.hex;
        })
        .catch((e) => {
          // console.log(e);
        });

      console.log(mainColor);

      fac.destroy();
    },
  },
  async created() {
    // console.log(this.items.image)
    if (this.items && this.items.image) {
      await this.getMainImageColor(this.items.image);
    }
  },
  data: () => ({
    btnColor: "#042851",
  }),
};
</script>
