<template>
  <div class="pb-8">
    <v-row>
      <v-col
        :cols="isMobile ? 12 : 4"
        v-for="(i, index) in lawList"
        :key="index"
      >
        <LawCard :items="i"></LawCard>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import LawCard from "@/components/organisms/LawCard.vue";

export default {
  components: {
    LawCard,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  created() {
    this.mapItemToLawList();
  },
  methods: {
    mapItemToLawList() {
      if (this.item.law != null) {
        let lawList = [];
        this.item.law.forEach((_item) => {
          _item = _item.law;
          console.log(_item);

          lawList.push({
            law: _item.law,
            title: this.getFieldFromResp({ acf: _item }, "title"),
            example: this.getFieldFromResp({ acf: _item }, "example"),
            penalty: this.getFieldFromResp({ acf: _item }, "penalty"),
            image: this.getImageFromResp({ acf: _item }),
            url: this.getFieldFromResp({ acf: _item }, "url"),
            donts: _item.dont.map((dont) => {
              return this.getFieldFromResp({ acf: dont }, "dont");
            }),
          });
        });
        console.log("lawList", lawList)

        this.lawList = lawList;
      }
    },
  },
  data() {
    return {
      lawList: [],
    };
  },
};
</script>
