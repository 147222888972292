export const sexualAbuse = {
  title: {
    en: "Sexual Assault",
    tc: "性侵犯",
    sc: "性侵犯"
  },
  img: "hh3.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
      // color: "#E36666",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Sexual Assault",
        tc: "性侵犯",
        sc: "性侵犯"
      },
      title: {
        en: "Sexual Assault",
        tc: "性侵犯",
        sc: "性侵犯"
      },
      img: "witness-kids-3.png",
      aspectRatio: 315 / 277,
      showTips: true,
      points: [
        {
          en: "Observe more about the behaviour of your children.",
          tc: "多留意子女的行為。",
          sc: "多留意子女的行为。"
        },
        {
          en: "Empower your children to speak out.",
          tc: "鼓勵子女坦率說出他們的事情。",
          sc: "鼓励子女坦率说出他们的事情。"
        },
        {
          en: "Teach your child to set personal boundaries.",
          tc: "教導子女設定個人界限。",
          sc: "教导子女设定个人界限。"
        },
        {
          en: "For serious sexual offences such as rape, securing evidence is very crucial, e.g. retain clothing of victim, do not take a shower before medical examination.",
          tc: "在面對嚴重性罪行（如強姦）的時候，確保證據得以保存非常重要，例如保留有關衣物，在接受醫療檢驗前不要洗澡。",
          sc: "在面对严重性罪行（如强奸）的时候，确保证据得以保存非常重要，例如保留有关衣物，在接受医疗检验前不要洗澡。"
        },
        {
          en: "Report the case to the police immediately.",
          tc: "立即報警。",
          sc: "立即报警。"
        },
      ],
    }
  ],
};
