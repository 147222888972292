<template>
  <div>
    <BackButton />
    <VideoPage v-for="(i, index) in data" :key="index" :data="i" />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import VideoPage from "@/components/templates/VideoPage.vue";
import BackButton from "@/components/atoms/BackButton.vue";
import config from "/config";
import axios from "axios";

export default {
  name: "TeensVideoPage",
  components: {
    VideoPage,
    BackButton,
  },
  computed: {},
  props: [],
  data: () => ({
    data: [],
  }),
  mounted() {
    // let path = this.$route.fullPath;
    // let tmp = path.substr(path.indexOf("=") + 1, path.length);
    // this.data = this.getData(parseInt(tmp));
  },
  created() {
    const video_cat = this.$route.query.item;
    if (video_cat.title.en == "Media Interview") {
      this.fetchMediaInterviewData();
    } else {
      this.fetchData();
    }
  },
  methods: {
    fetchMediaInterviewData() {
      axios
        .get(config.wordpressUrl + "/teens_media_cat?order=asc&per_page=100")
        .then((response) => {
          axios
            .get(config.wordpressUrl + "/teens_media?order=asc&per_page=100")
            .then((resp) => {
              let dataset = [];

              response.data.map((item) => {
                dataset.push({
                  info: [
                    {
                      id: item.id,
                      image: this.getImageFromResp(item),
                      title: this.getFieldFromResp(item, "title"),
                    },
                  ],
                  videoList: resp.data
                    .filter((i) => {
                      return i.acf.category && i.acf.category.ID == item.id;
                    })
                    .map((i) => {
                      return this.mapDataToVideoData(i);
                    }),
                });
              });

              this.data = dataset;
            });
        });
    },
    fetchData() {
      axios
        .get(config.wordpressUrl + "/teens_video?order=asc&per_page=100")
        .then((response) => {
          console.log(response.data);
          const id = this.$route.query.id;
          console.log("query.id", id);

          console.log("item", this.$route.query.item);

          let videoData = {
            info: [this.$route.query.item],
            videoList: [],
          };

          const data = response.data;
          if (data) {
            data.map((item) => {
              let category = item.acf.category;

              if (category != null && category.ID == id) {
                videoData.videoList.push(this.mapDataToVideoData(item));
              }
            });

            videoData.videoList = videoData.videoList.sort((a, b) => {
              if (a.order > b.order) {
                return -1;
              }
              if (a.order < b.order) {
                return 1;
              }
              return 0;
            });

            this.data = [videoData];
            console.log("this.data", this.data);
          }
        });
    },
    mapDataToVideoData(item) {
      return {
        title: this.getFieldFromResp(item, "title"),
        desc: this.getFieldFromResp(item, "description"),
        category: this.getFieldFromResp(item, "category", null),
        image: item.acf.image,
        videoId: this.$youtube.getIdFromUrl(item.acf.link),
      };
    },
    getImageUrl(img) {
      return require(`@/assets/images/teens/${img}`);
    },
    getRImageUrl(img) {
      return require(`@/assets/images/linking/teens/${img}`);
    },
    getData(id) {
      switch (id) {
        case 1: {
          return [
            {
              info: [
                {
                  title: "stayAlert.cybercrime",
                  image: this.getImageUrl("cybercrime.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  title: {
                    en:
                      "Naked chat may look real but it’s NOT. Remain vigilant at all times.",
                    tc: "裸聊以假亂真 • 勒索時刻小心",
                    sc: "裸聊以假乱真 • 勒索时刻小心",
                  },
                  category: {
                    en: "Naked Chat. Blackmail Scam",
                    tc: "裸聊勒索案",
                    sc: "裸聊勒索案",
                  },
                  desc: {
                    en:
                      "Should I follow when you take it off?  Clinical psychologist and CSTCB officers are here to dismantle the myths of naked chat and the common tricks of ‘canned videos’.",
                    tc:
                      "你脫我又脫？ 臨床心理學家及網罪科人員逐一為你拆解裸聊嘅迷思，以及「罐頭片」嘅常用技倆。",
                    sc:
                      "你脱我又脱？ 临床心理学家及网罪科人员逐一为你拆解裸聊的迷思，以及「罐头片」的常用技俩。",
                  },
                  videoId: "F8VdyVrb2es",
                  image: require("@/assets/images/stayAlert/teens/cybercrime_1.png"),
                },
                {
                  title: {
                    en: "Beware of Naked Chat",
                    tc: "提防裸聊",
                    sc: "提防裸聊",
                  },
                  category: {
                    en: "Naked Chat. Blackmail Scam",
                    tc: "裸聊勒索案",
                    sc: "裸聊勒索案",
                  },
                  desc: {
                    en:
                      "Only nerds will fall into the trap of naked chat? No no no！Let the clinical psychologist explain to you why everyone can be a victim! ",
                    tc:
                      "裸聊係宅男專利？No no no！ 等臨床心理學家話你知原來每個人都有機會中招！",
                    sc:
                      "裸聊是宅男专利？No no no！ 让临床心理学家告诉你原来每个人都有机会中陷阱！",
                  },
                  videoId: "zvS7laXd-JI",
                  image: require("@/assets/images/stayAlert/teens/cybercrime_2.png"),
                },
                {
                  title: {
                    en: "Alertness Drama Series_Bid for love?",
                    tc: "警✔劇場_BID出一段情",
                    sc: "警✔剧场_竞标一段情",
                  },
                  category: {
                    en: "Online Romance Scam",
                    tc: "裸聊勒索案 ",
                    sc: "裸聊勒索案",
                  },
                  desc: {
                    en:
                      "You can really bid for true love? Let’s see how to bid!",
                    tc: "真愛真係可以BID出嚟？睇睇點BID 先！",
                    sc: "真爱真的可以竞标得来？先看看如何竞标！",
                  },
                  videoId: "U-eSHV2x7ho",
                  image: require("@/assets/images/stayAlert/teens/cybercrime_3.png"),
                },
                {
                  title: {
                    en: "Online Shopping Trap",
                    tc: "勞力「是」陷阱",
                    sc: "劳力「是」陷阱",
                  },
                  category: {
                    en: "Online Shopping Fraud",
                    tc: "網購騙案 ",
                    sc: "网购骗案",
                  },
                  desc: {
                    en:
                      "One shouldn't have the heart to harm others, but must be vigilant so as not to be harmed! Everyone knows how to shop online, but do you know the things to look out for?",
                    tc:
                      "害人之心不可有，防人之心不可無！網上購物人人都識，但係要注意嘅事你又識唔識呢？",
                    sc:
                      "害人之心不可有，防人之心不可无！网上购物人人都会，但是要注意的事你又会不会？",
                  },
                  videoId: "OjZmayUonkI",
                  image: require("@/assets/images/stayAlert/teens/cybercrime_4.png"),
                },
                {
                  title: {
                    en: "Do you still believe it’s that her?",
                    tc: "還相信你‧那個她?",
                    sc: "还相信你‧那个她?",
                  },
                  category: {
                    en: "Fraud",
                    tc: "課金騙案 ",
                    sc: "课金骗案",
                  },
                  desc: {
                    en:
                      "What you see in the virtual world might not be true. It is often different from what you think.",
                    tc: "虛擬世界，眼見亦未為真，很多時和你所想的不一樣。",
                    sc: "虚拟世界，眼见亦未为真，很多时和你所想的不一样。",
                  },
                  videoId: "QJwlJv2yjA0",
                  image: require("@/assets/images/stayAlert/teens/cybercrime_5.png"),
                },
              ],
            },
          ];
        }
        case 2: {
          return [
            {
              info: [
                {
                  title: "stayAlert.fraud",
                  image: this.getImageUrl("fraud.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  title: {
                    en: "ADCC Action!",
                    tc: "反詐時速",
                    sc: "反诈时速",
                  },
                  category: {
                    en: "Telephone Deception",
                    tc: "假冒內地官員電話騙案",
                    sc: "假冒内地官员电话骗案",
                  },
                  desc: {
                    en:
                      "“Hello, you’re receiving this call from the Mainland official. We suspect that you have violated the laws……” Commissioner of Police Siu Sir and Anti-Deception Coordination Centre will guide you through the truth!",
                    tc:
                      "喂，我是內地官員，依家懷疑你犯咗法……警務處處長蕭Sir 同反詐騙協調中心助你洞悉真相！",
                    sc:
                      "喂，我是内地官员，现在怀疑你犯了法……警务处处长萧Sir 和反诈骗协调中心助你洞悉真相！",
                  },
                  videoId: "r8TJjcIXWYc",
                  image: require("@/assets/images/stayAlert/teens/fraud_1.png"),
                },
                {
                  title: {
                    en: "Employment Fraud",
                    tc: "騙騙遇上你．求職騙案",
                    sc: "骗骗遇上你．求职骗案",
                  },
                  category: {
                    en: "Employment Fraud",
                    tc: "求職騙案",
                    sc: "求职骗案",
                  },
                  desc: {
                    en:
                      "Flexible working hours, no experience needed……and pay by cash? Have a closer look why it seems so “attractive”!",
                    tc: "彈性上班、無須經驗…… 仲現金出糧？睇吓點解咁筍先！",
                    sc:
                      "弹性上班、无须经验…… 还有现金付薪金？先看看为何如此划算！",
                  },
                  videoId: "rDVL7CpuXDI",
                  image: require("@/assets/images/stayAlert/teens/fraud_2.png"),
                },
              ],
            },
          ];
        }
        case 3: {
          return [
            {
              info: [
                {
                  title: "stayAlert.physical_harm",
                  image: this.getImageUrl("physical_harm.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  title: {
                    en: "School Bullying",
                    tc: "欺凌。希寧",
                    sc: "欺凌。希宁",
                  },
                  category: {
                    en: "School Bullying",
                    tc: "校園欺凌",
                    sc: "校园欺凌 ",
                  },
                  desc: {
                    en:
                      "Just playing for fun? Be careful it has gone too far! What will you do if you witness this happening?",
                    tc:
                      "諗住玩下啫？小心玩過火呀！如果你見到咁嘅事情發生，你又會點做呢？",
                    sc:
                      "只是玩玩而已？小心玩过了火！如果你见到这些事情发生，你又会怎么做呢？",
                  },
                  videoId: "ZcS0cXH7NEc",
                  image: require("@/assets/images/stayAlert/teens/physical_harm_1.png"),
                },
              ],
            },
          ];
        }
        case 4: {
          return [
            {
              info: [
                {
                  title: "stayAlert.sexual_violence",
                  image: this.getImageUrl("sexual_violence.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  title: {
                    en:
                      "Beware of online “traps”. Join hands in fighting against sexual violence.",
                    tc: "慎防網上「性陷阱」• 攜手撲滅性暴力",
                    sc: "慎防网上「性陷阱」• 携手扑灭性暴力",
                  },
                  category: {
                    en: "Online sexual violence traps",
                    tc: "上網交朋友要小心",
                    sc: "社交平台性陷阱 ",
                  },
                  desc: {
                    en:
                      "(Be vigilant when meeting new friends in the cyber world) X3. Important things have to be said three times, but mistake cannot be made even for once. Let’s see how to solve it.",
                    tc: "網上有好多陷阱 – “traps”，一定要小心，小心，小心！",
                    sc:
                      "(网上虚拟，交心不宜) X3。重要的事需要说三遍，错误的事一次都嫌多！破解有道，其实不难。",
                  },
                  videoId: "vwqZfDeC9gc",
                  image: require("@/assets/images/stayAlert/teens/sexual_violence_1.png"),
                },
              ],
            },
          ];
        }
        case 5: {
          return [
            {
              info: [
                {
                  title: "stayAlert.sexual_violence",
                  image: this.getImageUrl("sexual_violence.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「非禮12歲女童」案件",
                    tc: "拘捕。「非禮12歲女童」案件",
                    sc: "拘捕。「非礼12岁女童」案件",
                  },
                  videoId: "S-HiWq9VFjY",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「向兒童作猥褻行為及製作兒童色情物品」案件",
                    tc: "拘捕。「向兒童作猥褻行為及製作兒童色情物品」案件",
                    sc: "拘捕。「向儿童作猥亵行为及制作儿童色情物品」案件",
                  },
                  videoId: "71HxDQr4cvk",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「施用藥物以獲得非法性行為」案件",
                    tc: "拘捕。「施用藥物以獲得非法性行為」案件",
                    sc: "拘捕。「施用药物以获得非法性行为」案件",
                  },
                  videoId: "n1s2PZkk0wE",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「梯間非禮8歲女童及拍照」案件",
                    tc: "拘捕。「梯間非禮8歲女童及拍照」案件",
                    sc: "拘捕。「梯间非礼8岁女童及拍照」案件",
                  },
                  videoId: "SldHnARBT0Q",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「稱占卜轉運非禮女學生」案件",
                    tc: "拘捕。「稱占卜轉運非禮女學生」案件",
                    sc: "拘捕。「称占卜转运非礼女学生」案件",
                  },
                  videoId: "x54UfDjam0k",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「非禮14歲女學生」案件",
                    tc: "拘捕。「非禮14歲女學生」案件",
                    sc: "拘捕。「非礼14岁女学生」案件",
                  },
                  videoId: "OVlvJf0Ts",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「非法禁錮及管有兒童色情物品」案件",
                    tc: "拘捕。「非法禁錮及管有兒童色情物品」案件",
                    sc: "拘捕。「非法禁锢及管有儿童色情物品」案件",
                  },
                  videoId: "nObtujDhSPs",
                  image: require("@/assets/images/img_icon.png"),
                },
              ],
            },
            {
              info: [
                {
                  title: "helpingHand.physical_abuse",
                  image: this.getImageUrl("physical_harm.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「母親身體虐待嬰兒」案件",
                    tc: "拘捕。「母親身體虐待嬰兒」案件",
                    sc: "拘捕。「母亲身体虐待婴儿」案件",
                  },
                  videoId: "se_-fk1Y_1Q",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "謀殺及殘酷對待兒童罪判刑",
                    tc: "謀殺及殘酷對待兒童罪判刑",
                    sc: "谋杀及残酷对待儿童罪判刑",
                  },
                  videoId: "DW7oTWu03m8",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「童黨欺凌」案件",
                    tc: "拘捕。「童黨欺凌」案件",
                    sc: "拘捕。「童党欺凌」案件",
                  },
                  videoId: "FTgkKAF1B1s",
                  image: require("@/assets/images/img_icon.png"),
                },
              ],
            },
            {
              info: [
                {
                  title: "stayAlert.cybercrime",
                  image: this.getImageUrl("cybercrime.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「利用網絡遊戲武器誘使女童拍裸照」案件",
                    tc: "拘捕。「利用網絡遊戲武器誘使女童拍裸照」案件",
                    sc: "拘捕。「利用网络游戏武器诱使女童拍裸照」案件",
                  },
                  videoId: "hmxyJhqb_Qk",
                  image: require("@/assets/images/img_icon.png"),
                },
              ],
            },
            {
              info: [
                {
                  title: "legally.damage",
                  image: this.getImageUrl("cruelty_child.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「刑毁連鎖教育中心」案件",
                    tc: "拘捕。「刑毁連鎖教育中心」案件",
                    sc: "拘捕。「刑毁连锁教育中心」案件",
                  },
                  videoId: "4CzihE4qqNM",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「13及15歲非法收債、刑事毁壞及刑事恐嚇」案件",
                    tc: "拘捕。「13及15歲非法收債、刑事毁壞及刑事恐嚇」案件",
                    sc: "拘捕。「13及15岁非法收债、刑事毁坏及刑事恐吓」案件",
                  },
                  videoId: "zGjhD63gzVQ",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「非法放債及收數」案件",
                    tc: "拘捕。「非法放債及收數」案件",
                    sc: "拘捕。「非法放债及收数」案件",
                  },
                  videoId: "1FhycwhrftE",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「高利貸放債集團」案件",
                    tc: "拘捕。「高利貸放債集團」案件",
                    sc: "拘捕。「高利贷放债集团」案件",
                  },
                  videoId: "knWGBnH3Rf4",
                  image: require("@/assets/images/img_icon.png"),
                },
              ],
            },
          ];
        }
        default:
          return {
            info: [],
            videoList: [],
          };
      }
    },
  },
};
</script>
