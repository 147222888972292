<template>
  <div id="chatroom" :class="isMobile ? 'mobile' : ''">
    <beautiful-chat
      :language="displayLang"
      :title="title"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :close="closeChat"
      :colors="colors"
      :is-open="isChatOpen"
      :message-list="messageList"
      :message-styling="messageStyling"
      :new-messages-count="newMessagesCount"
      :on-message-was-sent="onMessageWasSent"
      :open="selectCharacter"
      :participants="participants"
      :show-close-button="true"
      :show-launcher="true"
      :show-emoji="false"
      :show-file="false"
      :show-typing-indicator="showTypingIndicator"
      :show-edition="true"
      :show-deletion="true"
      :logo="logo"
      :disable-user-list-toggle="true"
      :enable-user-input="enableUserInput"
      @onType="handleOnType"
      @edit="editMessage"
      @remove="removeMessage"
    >
      <template v-slot:text-message-body="scopedProps">
        <p
          class="sc-message--text-content"
          v-html="scopedProps.messageText"
        ></p>
      </template>
      <template v-slot:system-message-body="{ message }">
        {{ message.text }}
      </template>
    </beautiful-chat>
    
    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10"> 
              <span class="text-h5">
                {{ $t('common.pickCharacter') }} 
              </span>
            </v-col>
            <v-col cols="2">
              <v-btn
                absolute
                top
                right
                icon
                color="red"
                class="btn"
                @click="dialog = false"
                aria-label="close"
              >
                <v-icon> mdi-close-circle </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6" md="3" class="text-center" v-for="participant in chatParticipants" :key="participant.id" @click="afterSelectCharacter(participant)">
              <v-row>
                <v-col>
                  <v-img :src="participant.imageUrl"></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h4">{{ participant.name }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import messageHistory from "./messageHistory";
import chatParticipants from "./chatProfiles";
import availableColors from "./colors";

// const CHATBOT_API = "https://202.128.231.34/ChatbotWebService/Chatbot/SendMessage";
// const CHATBOT_API = "https://202.128.225.229/ChatbotWebService/Chatbot/SendMessage";
// const CHATBOT_API = "http://humane.nec.com.hk:8107/ChatbotWebService/Chatbot/SendMessage";
const CHATBOT_API = "/ChatbotWebService/Chatbot/SendMessage";

export default {
  name: "App",
  data() {
    return {
      language: "hk",
      participants: chatParticipants,
      chatParticipants: chatParticipants,
      randomCharacter: null,
      logo: require("@/assets/web_icon.png"),
      messageList: messageHistory,
      newMessagesCount: 0,
      isChatOpen: false,
      showTypingIndicator: "",
      colors: null,
      availableColors,
      chosenColor: null,
      alwaysScrollToBottom: true,
      messageStyling: true,
      userIsTyping: false,
      title: "Ted",
      enableUserInput: true,
      sessionId: Math.random()
        .toString(36)
        .substr(2, 9)
        .toUpperCase(),
      dialog: false,
      displayLang: this.$i18n.language,
    };
  },
  computed: {
    linkColor() {
      return this.chosenColor === "dark"
        ? this.colors.sentMessage.text
        : this.colors.launcher.bg;
    },
    backgroundColor() {
      return this.chosenColor === "dark" ? this.colors.messageList.bg : "#fff";
    },
  },
  created() {
    this.setColor("custom");
  },
  mounted() {
    // this.randomCharacter = this.chatParticipants[
    //   Math.floor(Math.random() * this.chatParticipants.length)
    // ];
    // this.participants = [];
    // this.participants.push(this.randomCharacter);
    // this.logo = this.randomCharacter.imageUrl;
    // this.title =
    //   this.language == "hk"
    //     ? this.randomCharacter.name_tc
    //     : this.randomCharacter.name;

    // this.messageList.forEach((x) => (x.liked = false));
  },
  methods: {
    handleTyping(text) {
      this.showTypingIndicator =
        text.length > 0
          ? this.participants[this.participants.length - 1].id
          : "";
    },
    onMessageWasSent(message) {
      var messageContent = message.data.text.trim();

      // For button
      if (message.data.title != undefined) {
        message.data.text = message.data.title;
      }

      if (
        message.data.donotdisplay == undefined ||
        !message.data.donotdisplay
      ) {
        this.messageList = [
          ...this.messageList,
          Object.assign({}, message, { id: this.messageList.length }),
        ];
      }
      this.sendToChatbot(messageContent);
    },
    sendToChatbot(messageContent) {
      // this.callApi('https://202.128.225.229/ChatbotWebService/Chatbot/SendMessage',
      this.callApi(
        CHATBOT_API,
        {
          session: this.sessionId,
          message: messageContent,
          lang: this.language,
        },
        async (response) => {
          // console.log(response)

          var apiResponse = JSON.parse(response.resultData.jsonResp);
          console.log(apiResponse);

          // this.showTypingIndicator = this.randomCharacter.id

          // setTimeout(() => {
          //   this.showTypingIndicator = ''

          for (var i in apiResponse) {
            var resp = apiResponse[i];
            console.log(resp);

            if (messageContent != "hi") {
              this.showTypingIndicator = this.randomCharacter.id;
              await this.sleep(
                resp.text != null
                  ? (Math.round(resp.text.length) / 8) * 100 + 1000
                  : 1000
              );
              this.showTypingIndicator = "";
            }

            var chatbotResp = {
              type: "text",
              author: this.randomCharacter.id,
            };

            this.enableUserInput = true;

            if (resp.text != null) {
              chatbotResp.data = {};
              chatbotResp.data.text = resp.text.replace(
                "Ted",
                this.randomCharacter.name
              );
            }

            if (resp.buttons != null) {
              chatbotResp.suggestions = [];

              for (var i in resp.buttons) {
                var button = resp.buttons[i];

                chatbotResp.suggestions[i] = button;
              }

              this.enableUserInput = false;
            }

            if (resp.image != null) {
              chatbotResp.type = "file";

              chatbotResp.data = {};
              chatbotResp.data.file = {};
              chatbotResp.data.file.url = resp.image;
            }

            if (resp.custom != null) {
              chatbotResp.type = "file";

              chatbotResp.data = {};
              if (resp.custom.title != undefined) {
                chatbotResp.data.title = resp.custom.title;
              }
              if (resp.custom.text != undefined) {
                chatbotResp.data.text = resp.custom.text;
              }
              chatbotResp.data.file = {};
              chatbotResp.data.file.url = resp.custom.image;
            }

            // console.log(chatbotResp)

            this.messageList = [
              ...this.messageList,
              Object.assign({}, chatbotResp, { id: this.messageList.length }),
            ];
          }

          if (response.resultData.isEnded) {
            var chatbotResp = {
              type: "system",
              data: {
                text: "Session Ended",
              },
            };

            this.messageList = [
              ...this.messageList,
              Object.assign({}, chatbotResp, { id: this.messageList.length }),
            ];
            this.enableUserInput = false;
          }
          // }, messageContent === 'hi' ? 0 : this.randomDelay(1000, 2500))
        },
        (error) => {
          console.log(error);
        }
      );
    },
    openChat() {
      this.isChatOpen = true;
      this.newMessagesCount = 0;

      // Send dummy message to start the conversation
      if (this.messageList.length == 0) {
        this.sendToChatbot("hi " + this.randomCharacter.name);
      }
    },
    closeChat() {
      this.isChatOpen = false;
    },
    selectCharacter() {
      this.dialog = true
    },
    afterSelectCharacter(character) {
      this.dialog = false

      this.randomCharacter = character;
      this.participants = [];
      this.participants.push(this.randomCharacter);
      this.logo = this.randomCharacter.imageUrl;
      this.title =
        this.language == "hk"
          ? this.randomCharacter.name_tc
          : this.randomCharacter.name;

      this.messageList.forEach((x) => (x.liked = false));

      this.openChat()
    },
    setColor(color) {
      this.colors = this.availableColors[color];
      this.chosenColor = color;
    },
    showStylingInfo() {
      this.$modal.show("dialog", {
        title: "Info",
        text:
          "You can use *word* to <strong>boldify</strong>, /word/ to <em>emphasize</em>, _word_ to <u>underline</u>, `code` to <code>write = code;</code>, ~this~ to <del>delete</del> and ^sup^ or ¡sub¡ to write <sup>sup</sup> and <sub>sub</sub>",
      });
    },
    messageStylingToggled(e) {
      this.messageStyling = e.target.checked;
    },
    handleOnType() {
      this.$root.$emit("onType");
      this.userIsTyping = true;
    },
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    removeMessage(message) {
      if (confirm("Delete?")) {
        const m = this.messageList.find((m) => m.id === message.id);
        m.type = "system";
        m.data.text = "This message has been removed";
      }
    },
    like(id) {
      const m = this.messageList.findIndex((m) => m.id === id);
      var msg = this.messageList[m];
      msg.liked = !msg.liked;
      this.$set(this.messageList, m, msg);
    },
    randomDelay(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style lang="scss">
/* body {
  padding: 0px;
  margin: 0px;
  height: 100vh;
  background: linear-gradient(0deg, rgba(17,99,169,1) 0%, rgba(255,255,255,1) 50%);
} */
@keyframes animation_d {
  0% {
    right: -250px;
    top: 125vh;
  }
  25% {
    right: 270px;
    top: 70vh;
    width: 150px;
    height: 150px;
  }
  50% {
    right: 270px;
    top: 70vh;
    width: 150px;
    height: 150px;
  }
  75% {
    right: 270px;
    top: 70vh;
    width: 150px;
    height: 150px;
  }
  100% {
    right: 90px;
    top: calc(100vh - 85px);
  }
}

@keyframes animation_m {
  0% {
    right: -250px;
    top: 125vh;
  }
  25% {
    right: 230px;
    top: 70vh;
    width: 150px;
    height: 150px;
  }
  50% {
    right: 230px;
    top: 70vh;
    width: 150px;
    height: 150px;
  }
  75% {
    right: 230px;
    top: 70vh;
    width: 150px;
    height: 150px;
  }
  100% {
    right: 90px;
    top: calc(100vh - 150px);
  }
}

// .sc-open-icon,
// .sc-launcher {
//   animation-name: animation_d;
//   animation-duration: 4s;
//   animation-delay: 2s;
// }

.mobile {
  .sc-launcher,
  .sc-open-icon {
    bottom: 90px !important;
  }

  // .sc-open-icon,
  // .sc-launcher {
  //   animation-name: animation_m;
  //   animation-duration: 4s;
  //   animation-delay: 2s;
  // }
}

#chatroom {
  position: absolute;
  z-index: 999;
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;

  .demo-description {
    max-width: 500px;
  }

  .demo-description img {
    max-width: 500px;
  }

  .demo-test-area {
    width: 300px;
    box-sizing: border-box;
  }

  .demo-test-area--text {
    box-sizing: border-box;
    width: 100%;
    margin: 0px;
    padding: 0px;
    resize: none;
    font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
    background: #fafbfc;
    color: #8da2b5;
    border: 1px solid #dde5ed;
    font-size: 16px;
    padding: 16px 15px 14px;
    margin: 0;
    border-radius: 6px;
    outline: none;
    height: 150px;
    margin-bottom: 10px;
  }

  .demo-monster-img {
    width: 400px;
    display: block;
    margin: 60px auto;
  }

  .text-center {
    text-align: center;
  }

  .colors a {
    color: #fff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 10px;
  }

  .toggle a {
    text-decoration: none;
  }

  .messageStyling {
    font-size: small;
  }
}
</style>
