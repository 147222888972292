var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',_vm._l((_vm.videoList),function(v,index){return _c('v-col',{key:index,attrs:{"cols":_vm.isMobile ? 12 : 4}},[_c('v-card',{staticStyle:{"margin-top":"10px","text-align":"left","display":"flex","flex-direction":"column","height":"100%"}},[(_vm.filterValue(v.category))?_c('v-card-subtitle',{style:({
            'background-color': _vm.currentRouteName.includes('Adult')
              ? '#61c2ff'
              : _vm.currentRouteName.includes('Teens')
              ? '#77d525'
              : '#ffa111',
            color: '#000',
          })},[_vm._v(" "+_vm._s(_vm.filterValue(v.category))+" ")]):_vm._e(),_c('v-img',{staticClass:"d-flex justify-center align-center text-center",staticStyle:{"background-color":"black"},attrs:{"src":v.image,"contain":"","height":"150","max-height":"150"}},[(v.videoId)?_c('v-btn',{attrs:{"color":"white","fab":"","dark":"","x-large":!_vm.isMobile,"aria-label":"video"},on:{"click":function($event){return _vm.popUpDialog(v.local, v.videoId, v.title)}}},[_c('v-icon',{attrs:{"color":"grey","size":_vm.windowWidth * (_vm.isMobile ? 0.07 : 0.03)}},[_vm._v(" mdi-play ")])],1):_vm._e()],1),(v.videoId)?_c('v-card-text',{staticClass:"d-flex justify-end mt-3 text-caption py-0"},[_c('div',[_vm._v("( "+_vm._s(_vm.$t("common.cantoneseOnly"))+" )")])]):_vm._e(),_c('v-card-title',{staticClass:"justify-center text-center"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? v.title.en : _vm.$i18n.locale == "tc" ? v.title.tc : v.title.sc)+" ")]),_c('v-card-text',{staticClass:"text-caption",staticStyle:{"color":"#000"}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? v.desc.en : _vm.$i18n.locale == "tc" ? v.desc.tc : v.desc.sc)+" ")])],1)],1)}),1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":_vm.isMobile ? '90vw' : '85vw'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"height":_vm.isMobile ? '50vh' : '85vh'}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? _vm.title.en : _vm.$i18n.locale == "tc" ? _vm.title.tc : _vm.title.sc)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"btn",attrs:{"absolute":"","top":"","right":"","icon":"","color":"red"},on:{"click":function($event){return _vm.closeVideo()}}},[_c('v-icon',[_vm._v(" mdi-close-circle ")])],1)],1)],1)],1),_c('youtube',{ref:"youtube",attrs:{"video-id":_vm.videoId,"player-vars":{ autoplay: 1 },"width":_vm.local ? '0%' : '100%',"height":_vm.local ? '0%' : '100%'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }