<template>
  <v-container class="mt-6" v-if="randomList != undefined">
    <span class="text-h5">{{ $t("common.recommend") }}</span>
    <div
      v-for="(i, index) in randomList.slice(0, numOfRecommendation)"
      :key="index"
    >
      <v-row class="my-2" v-if="!isMobilePhone">
        <v-col :class="isTablet ? 'py-2' : 'py-1'" :cols="isTablet ? 5 : 5">
          <v-img
            v-if="isTablet"
            :src="filterValue(i.image)"
            style="border-radius: 5px"
            height="150"
            cover
          >
          </v-img>
          <v-img
            v-else
            :src="filterValue(i.image)"
            style="border-radius: 5px"
            height="100"
            cover
          >
          </v-img>
        </v-col>

        <v-col
          cols="7"
          class="d-flex flex-column justify-space-between text-start pb-0 pl-0"
        >
          <span
            class="pr-4"
            color="grey"
            :class="isTablet ? 'body-3' : 'body-2'"
          >
            {{ filterValue(i.desc) }}
          </span>
          <div class="d-flex justify-end">
            <v-btn
              text
              style="color: #000"
              class="btn"
              @click="openUrl(filterValue(i.url))"
            >
              <div style="font-size: 14px">
                {{ $t("common.more") }}
              </div>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row class="my-4" v-if="isMobilePhone">
        <v-col>
          <v-img
            :src="filterValue(i.image)"
            style="border-radius: 5px"
            width="100%"
            height="200"
            cover
          >
          </v-img>
        </v-col>
      </v-row>

      <v-row v-if="isMobilePhone">
        <v-col class="text-center">
          <span class="body-2" color="grey">{{ filterValue(i.desc) }}</span>
        </v-col>
      </v-row>

      <v-card-actions class="justify-end text-right pt-1" v-if="isMobilePhone">
        <v-btn
          text
          style="color: #000"
          class="btn"
          @click="openUrl(filterValue(i.url))"
        >
          <div style="font-size: 14px">
            {{ $t("common.more") }}
          </div>
        </v-btn>
      </v-card-actions>

      <v-divider v-if="index != randomList.slice(0, 5).length - 1" />
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.span {
  word-break: normal !important;
}
</style>

<script>
import _ from "lodash";
import config from "../../../config";
import axios from "axios";

// @ is an alias to /src
export default {
  name: "Recommed",
  components: {},
  props: ["datalist"],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    // let dataList = _.cloneDeep(this.dataList);
    // this.randomList = dataList && dataList.sort(() => Math.random() - 0.5);
    // console.log("randomList", randomList);
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const _this = this;
      const name = this.$route.name.toString().toLowerCase();
      const fullPath = this.$route.fullPath.toString().toLowerCase();

      // get number of recommendation show
      axios
        .get(config.wordpressUrl + `/configs?order=asc&per_page=100`)
        .then((response) => {
          let data = response.data[0];

          if (data != null) {
            if (name.includes("kids") || fullPath.includes("kids")) {
              this.numOfRecommendation = data.acf.number_of_recommendation.kids;
            } else if (name.includes("teens") || fullPath.includes("teens")) {
              this.numOfRecommendation =
                data.acf.number_of_recommendation.teens;
            } else if (name.includes("adult") || fullPath.includes("adult")) {
              this.numOfRecommendation =
                data.acf.number_of_recommendation.adults;
            } else {
              this.numOfRecommendation = 5;
            }
          }
        });

      //get recommendation list
      let url = "";

      if (name.includes("kids") || fullPath.includes("kids")) {
        url = "/kids_recommend?order=asc&per_page=100";
      } else if (name.includes("teens") || fullPath.includes("teens")) {
        url = "/teens_recommend?order=asc&per_page=100";
      } else if (name.includes("adult") || fullPath.includes("adult")) {
        url = "/audlts_recommend?order=asc&per_page=100";
      }

      console.log("url", url);

      axios.get(config.wordpressUrl + url).then((response) => {
        const data = response.data;
        // console.log(data);

        if (data) {
          let recommend = [];

          data.map((item) => {
            recommend.push({
              desc: this.getFieldFromResp(item, "title"),
              image: this.getImageFromResp(item),
              url: this.getFieldFromResp(item, "link"),
              id: item.id,
            });
          });

          recommend = recommend.sort(() => Math.random() - 0.5);

          _this.randomList = recommend;
          console.log("randomList", _this.randomList);
        }
      });
    },
    getText(item) {
      if (this.$i18n.locale == "en") {
        return item.en;
      } else if (this.$i18n.locale == "tc") {
        return item.tc;
      } else {
        return item.sc;
      }
    },
    openUrl(url) {
      window.open(url);
    },
    getImageUrl(img) {
      return require(`@/assets/images/learnMore/kids/${img}`);
    },
  },
  data() {
    return {
      randomList: [],
      numOfRecommendation: 0,
    };
  },
};
</script>
