export const victim = {
  title: {
    en: "Child as a Victim…",
    tc: "兒童作為受害人…",
    sc: "common.tbc"
  },
  subTitle: {
    en: "We know how it feels.",
    tc: "我們明白你的感覺",
    sc: "common.tbc"
  },
  img: "victim-teens.png",
  aspectRatio: 364 / 121,
  onCardClick: true,
  children: [
    {
      contentHeader: {
        en: "Child as a Victim…",
        tc: "兒童作為受害人…",
        sc: "common.tbc"
      },
      content: {
        en: "Seek help\nYou are not alone\nLiesten to him/ her.\nYour reassurance and support are very important.",
        tc: "鼓勵求助\n你並不孤單",
        sc: "common.tbc"
      },
      img: "victim-teens-1.png",
      aspectRatio: 366 / 200,
      children: [
        {
          content: {
            en: "Empower the child to speak out.\nHelp them to develop the confidence to do so.",
            tc: "鼓勵孩子勇敢說出來，請給予他們足夠的信心",
            sc: "common.tbc"
          },
          img: "victim-teens-2.png",
          aspectRatio: 366 / 200,
          children: [
            {
              content: {
                en: "After your assessment, report the case to police if necessary.\nWe are here to help.",
                tc: "當你了解情況後，如有需要，向警方求助",
                sc: "common.tbc"
              },
              img: "victim-teens-3.png",
              aspectRatio: 366 / 200,
              children: [
                {
                  content: {
                    en: 'We care about every child’s welfare',
                    tc: "我們關心每位兒童的福祉",
                    sc: "common.tbc"
                  },
                  points: [
                    {
                      en: "Arrange police of same sex for enquiry",
                      tc: "安排同一性別的警務人員了解情況",
                      sc: "common.tbc"
                    },
                    {
                      en: "Conduct interview at an individual room",
                      tc: "在獨立房間會面",
                      sc: "common.tbc"
                    },
                    {
                      en: {
                        tx: "Arrange an appropriate adult",
                        details: [
                          {
                            title: 'An appropriate adult means any person who is 18 of age and willing to provide support to a person (eg. victim, witness, arrested person etc.) involved in police enquiries who is:',
                            contents: [
                              'aged under 16; or',
                              'a mentally incapacitated person (MIP).'
                            ]
                          },
                          {
                            title: 'An appropriate adult can be:',
                            contents: [
                              'A relative, guardian or other person responsible for care or custody of the child or MIP; or',
                              'Someone who has experience of handling a person with special needs; or',
                              'If the above persons cannot be located, a responsible adult who is not a police officer nor employed by the police.'
                            ]
                          },
                          {
                            title: 'The role as an appropriate adult is to:',
                            contents: [
                              'provide emotional support and assistance to the person assisting/under police enquiry. You may inform the police the welfare matters related to the person;',
                              'help the person understand his / her rights;',
                              'act as a witness to observe whether any police interview / investigative procedure is being conducted fairly;',
                              'inform police of his / her medication and medical needs, if any; and',
                              'facilitate communication between police and the person assisting/under police enquiry, including inform police of his / her communication needs, if any.'
                            ]
                          }
                        ]
                      },
                      tc: {
                        tx: "安排合適成人陪伴",
                        details: [
                          {
                            title: '合適成人是指任何18歲以上的人士並願意提供支援予下列正在協助或接受警方調查的人士(例如受害者、證人、被捕人等)：',
                            contents: [
                              '年齡在16歲以下的人士；或',
                              '精神上無行為能力人士(MIP)'
                            ]
                          },
                          {
                            title: '合適成人可以是：',
                            contents: [
                              '一名親人、監護人或其他負責照顧或看守該人的人士；或',
                              '一名對處理有特別需要的人有經驗的人士；或',
                              '如果未能安排上述人士在場的話，亦須有其他可負責的成人，但不能是警務人員或是受僱於警方的人士。'
                            ]
                          },
                          {
                            title: '合適成人的角色是：',
                            contents: [
                              '向該名協助或接受警方調查的人士提供協助及情緒支援。你可就該人士的福利事宜向警方提出建議；',
                              '幫助該名人士明白其享有的權利；',
                              '以證人身分觀察警方會面/調查的程序是否公平；',
                              '告知警方該名人士的藥物和醫療需要(如有)；以及',
                              '協助警方與該名人士溝通，包括告知警方該名人士的溝通需要(如有)。'
                            ]
                          }
                        ]
                      },
                      sc: "common.tbc"
                    },
                    {
                      en: "Conduct forensic examination",
                      tc: "安排法醫檢查",
                      sc: "common.tbc"
                    },
                    {
                      en: "Arrange immediate medical treatment",
                      tc: "即時接受治療",
                      sc: "common.tbc"
                    }
                  ],
                  // content: {
                  //   en: "Report the case to police.\nWe are here to help.",
                  //   tc: "common.tbc",
                  //   sc: "common.tbc"
                  // },
                  img: "victim-teens-4.png",
                  aspectRatio: 366 / 200,
                  children: [
                    {
                      content: {
                        en: "Police Procedures",
                        tc: "警隊程序",
                        sc: "common.tbc"
                      },
                      img: "victim-teens-5.png",
                      aspectRatio: 366 / 200,
                      points: [
                        {
                          en: "Conduct Video Recorded Interview in a suite with confidential address",
                          tc: "在地點保密的家居錄影會面室進行",
                          sc: "common.tbc"
                        },
                        {
                          en: "Refer the case to SWD/ relevant service organization",
                          tc: "轉介社會福利署或相關服務機構跟進",
                          sc: "common.tbc"
                        },
                        {
                          en: {
                            tx: "Join Multi Disciplinary Case Conference",
                            details: [
                              {
                                title: 'The MDCC is a forum by which professionals having a major role in the handling and investigation of a suspected child abuse case can share their professional knowledge, information and concern on the child health, development, functioning and his/her parents’/carers’ ability to ensure safety of the child.  These professionals may social workers, teachers, doctors, police officers etc.'
                              },
                              {
                                title: 'The focus of the MDCC is on protection and welfare planning of the child.  Family perspective would be adopted in reviewing safety of all the children and other members (e.g. parents) in the household and discuss how to help the child continue to stay in a safe environment without further harm.'
                              },
                              {
                                title: 'The major function of MDCC includes but not limited to:',
                                contents: [
                                  'To examine the cause for suspected child abuse and assess the level of risk.',
                                  'To decide the case nature from the child welfare point of view.',
                                  'To make recommendation on the welfare planning for the child (e.g. child care arrangement, schooling, etc.).',
                                  'To identify the key social worker and the roles of other helping professionals'
                                ]
                              }
                            ]
                          },
                          tc: {
                            tx: "多專業個案會議",
                            details: [
                              {
                                title: '多專業個案會議提供一個場合，讓主要負責處理和調查懷疑虐兒個案的專業人士，交流與個案有關的專業知識，資料及對有關兒童的關注，以及評估危機，為有關兒童及其家庭釐訂福利計劃。專業人士有機會包括社工、老師、醫生、警方等。'
                              },
                              {
                                title: '會議的目的是保護兒童和為他們釐訂福利計劃。會議也會從家庭角度分析危機及需要，檢視有關家庭的所有兒童及其他成員（例如父母）的安全，研究如何讓兒童繼續在安全及穩定的關係及環境中成長。'
                              },
                              {
                                title: '多專業個案會議的功能及工作包括但不限於：',
                                contents: [
                                  '檢視懷疑虐兒事件的成因並評估個案的危機程度。',
                                  '從兒童福利的角度去決定個案性質。',
                                  '建議適切的兒童福利計劃（如兒童的照顧及就學安排）。',
                                  '確定負責跟進服務的社工，並釐訂其他提供協助的專業人士應擔當的角色。'
                                ]
                              }
                            ]
                          },
                          sc: "common.tbc"
                        }
                      ],
                      children: [
                        {
                          content: {
                            en: "Court Procedures",
                            tc: "法庭程序",
                            sc: "common.tbc"
                          },
                          img: "victim-teens-6.png",
                          aspectRatio: 366 / 200,
                          points: [
                            {
                              en: {
                                tx: "Live Television Link",
                                details: [
                                  {
                                    title: 'According to section 79B of the Criminal Procedure Ordinance (Cap. 221), the court may permit a child (victim of a case) to give evidence in court by way of a live television link.',
                                  },
                                  {
                                    title: '“Live television link” means that the child witness will not be giving evidence in the same courtroom as the defendant, but will be in another room located in the same court premises as the court room. The courtroom and the other room will be linked by way of a closed circuit television system, so that persons at the courtroom can see and hear the child witness, whilst the child witness will be able to hear, or both see and hear, the persons in the court room subject to the conditions as the court considers appropriate in the circumstances.',
                                  },
                                ]
                              },
                              tc: {
                                tx: "電視直播聯繫",
                                details: [
                                  {
                                    title: '根據第221章《刑事訴訟程序條例》第79B條，法庭可以容許兒童透過電視直播聯繫作供。',
                                  },
                                  {
                                    title: '「電視直播聯繫」的意思是，兒童證人作供時，毋須與被告身處於同一個法庭，而是在同一座法院的另一間房間中作供，這間房間就如另一個法庭一樣。房間與進行聆訊的法庭，會由閉路電視系統聯繫，法庭內的人可以透過電視直播，看見和聽見兒童證人；在法庭認為合適的情況下，兒童證人亦可以聽見、或看見和聽見法庭內的人。',
                                  },
                                ]
                              },
                              sc: "common.tbc"
                            },
                            {
                              en: {
                                tx: "Witness Support Programme  (Support Person)",
                                details: [
                                  {
                                    title: 'To fortify support to the children serving as witnesses in criminal proceedings, the Police in cooperation with the Social Welfare Department has set up the Witness Support Programme in which support persons would be arranged to accompany the child witnesses who have to testify in court by way of a live television link.'
                                  },
                                  {
                                    title: 'However, when the use of live television link is not approved by the court or for some reasons has not been applied, these witnesses will be required to give their evidence-in-chief and be cross-examined in open court.  The sheer atmosphere of the court may add fear and anxiety to the children, thereby affecting their performance in giving evidence.  The use of support persons in these cases may give emotional support to the children, helping to reduce their trauma and stress when giving evidence.',
                                  },
                                ]
                              },
                              tc: {
                                tx: "支援者的支援證人計劃",
                                details: [
                                  {
                                    title: '為加強對兒童證人在刑事審訊程序中作供時的支援，社會福利署與警方成立支援證人計劃，為需要在法庭以電視直播聯繫系統作供的兒童證人提供支援者，陪伴其出席有關案件的聆訊。'
                                  },
                                  {
                                    title: '但是，當使用電視直播聯繫未經法院批准或因某些原因未申請時，這些證人將被要求提供主要證據並在公開法庭上接受盤問。法庭的氣氛可能會增加兒童的恐懼和焦慮，從而影響他們作證的表現。在這些情況下支援者的陪伴可給予兒童證人情緒支援，以紓解他們在法庭作供時所受到的壓力及困擾。',
                                  },
                                ]
                              },
                              sc: "common.tbc"
                            }
                          ],
                        }
                      ]
                    }
                  ]
                },
              ]
            }
          ]
        }
      ]
    }
  ]
}