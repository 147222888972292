export const physicalAbuse = {
  title: {
    en: "Physical Abuse",
    tc: "身體虐待",
    sc: "身体虐待",
  },
  img: "hh1.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
      color: "#367399",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Physical Abuse",
        tc: "身體虐待",
        sc: "身体虐待",
      },
      title: {
        en: "Physical Abuse",
        tc: "身體虐待",
        sc: "身体虐待",
      },
      img: "witness-kids-2.png",
      aspectRatio: 336 / 273,
      showTips: true,
      points: [
        {
          en: "Shout for help!",
          tc: "高聲呼救！",
          sc: "高声呼救！",
        },
        {
          en: "Tell a trusted person!",
          tc: "把事情告知你信任的成年人。",
          sc: "把事情告知你信任的成年人。",
        },
        {
          en: "They will help you to seek medical treatment.",
          tc: "他們會協助你前往求診。",
          sc: "他们会协助你前往求诊。",
        },
        {
          en: "Remember the details (when, where, who) of the incident.",
          tc: "記下事發經過的詳細資料（時間、地點、人物）。",
          sc: "记下事发经过的详细资料（时间、地点、人物）。",
        },
      ],
    },
  ],
};
