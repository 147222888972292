<template>
  <div>
    <!-- <BackButton class="mb-3" /> -->
    <ExpansionPanels
      :items="items"
      :recommend="recommend"
      :open="open"
      :showDescn="false"
      v-if="open != null"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-card {
  border-radius: 20px;
  justify-content: center;
}
</style>

<script>
// @ is an alias to /src
import BackButton from "@/components/atoms/BackButton.vue";
import ExpansionPanels from "@/components/atoms/ExpansionPanels.vue";
import config from "../../../../config";
import axios from "axios";

export default {
  name: "AdultHome",
  components: {
    BackButton,
    ExpansionPanels,
  },
  created() {
    this.fetchCategories("/adults_video_cat?order=asc&per_page=100", "common.stayAlert");
    this.fetchCategories("/adults_stayinfo_cat?order=asc&per_page=100", "common.legally");
    this.fetchCategories("/adults_learnmore_cat?order=asc&per_page=100", "common.learnMore");
    this.fetchCategories(
      "/adults_kol_says_cat?order=asc&per_page=100",
      "common.teaching_materials"
    );
    this.fetchCategories("/adults_chill_out_cat?order=asc&per_page=100", "common.chill");
    
  },
  methods: {
    setData(val) {
      this.open = val == undefined ? -1 : val;
    },

    fetchCategories(url, title) {
      axios.get(config.wordpressUrl + url).then((response) => {
        const data = response.data;

        if (data) {
          let categories = [];

          data.map((item) => {
            categories.push(this.mapDataToChildren(item));
          });
          this.items[this.getTitleIndex(title)].children = categories;
        }
      });
    },
    getTitleIndex(title) {
      let i = undefined;
      this.items.forEach((item, _i) => {
        if (item.title == title) {
          i = _i;
        }
      });
      return i;
    },
    mapDataToChildren(item) {
      return {
        title: this.getFieldFromResp(item, "title"),
        image: this.getImageFromResp(item),
        id: item.id,
      };
    },

    getRImageUrl(img) {
      return require(`@/assets/images/linking/adults/${img}`);
    },
  },
  beforeRouteEnter(to, from, next) {
    // var path = from.path;
    next((vm) => {
      vm.setData(from.meta.openItem);
    });
  },

  data: () => ({
    open: null,
    items: [
      {
        title: "common.stayAlert",
        image: require("@/assets/images/smart_guard.png"),
        content: "Smart Guard...",
        route: "AdultsVideoPage",
        children: [
          // {
          //   title: "stayAlert.cybercrime",
          //   image: require("@/assets/images/parents/cybercrime.png"),
          //   id: 1,
          // },
          {
            title: "stayAlert.fraud",
            image: require("@/assets/images/parents/fraud.png"),
            id: 1,
          },
          {
            title: "stayAlert.physical_harm",
            image: require("@/assets/images/parents/physical_harm.png"),
            id: 2,
          },
          {
            title: "stayAlert.media_interview",
            image: require("@/assets/images/parents/media_interview.png"),
            id: 3,
          },
          // {
          //   title: "stayAlert.others",
          //   image: require("@/assets/images/parents/other_info.png"),
          //   id: 4,
          // },
        ],
      },
      {
        title: "common.legally",
        image: require("@/assets/images/stay_informed_legally.png"),
        content: "Stay Informed Legally...",
        route: "AdultStayInformed",
        children: [
          {
            title: "legally.cruelty_child",
            image: require("@/assets/images/parents/cruelty_child.png"),
            id: 1,
          },
          //  {
          //   title: "legally.theft",
          //   // image: require("@/assets/images/parents/cruelty_child.png"),
          // },
          {
            title: "legally.triad",
            image: require("@/assets/images/parents/triad.png"),
            id: 2,
          },
          {
            title: "legally.child_porn",
            image: require("@/assets/images/parents/child _porn.png"),
            id: 3,
          },
          // {
          //   title: "legally.drug",
          //   image: require("@/assets/images/parents/drug.png"),
          // },
          {
            title: "legally.sexual_offences",
            image: require("@/assets/images/parents/sex_abuse.png"),
            id: 4,
          },
        ],
      },
      {
        title: "common.chill",
        image: require("@/assets/images/chill_out_corner.png"),
        content: "Chill Out...",
        route: "Articles",
        children: [
          {
            title: "chill.articles",
            image: require("@/assets/images/articles.png"),
          },
        ],
      },
      {
        title: "common.learnMore",
        image: require("@/assets/images/learn_more.png"),
        content: "Learn More...",
        route: "LearnMore",
        children: [
          {
            title: "learnMore.statistics",
            image: require("@/assets/images/statistics.png"),
            id: 1,
          },
          {
            title: "learnMore.top5crimes",
            image: require("@/assets/images/top5crimes.png"),
            id: 2,
          },
          {
            title: "learnMore.age_dist",
            image: require("@/assets/images/age_dist.png"),
            id: 3,
          },
          {
            title: "learnMore.child_abuse",
            image: require("@/assets/images/child_abuse.png"),
            id: 4,
          },
          {
            title: "learnMore.sp_discretion",
            image: require("@/assets/images/superintendent_discretion.png"),
            id: 5,
          },
          {
            title: "learnMore.binding_over",
            image: require("@/assets/images/parents/binding_over.png"),
            id: 6,
          },
          {
            title: "learnMore.court",
            image: require("@/assets/images/parents/court.png"),
            id: 7,
          },
          {
            title: "learnMore.procedures",
            image: require("@/assets/images/parents/procedures.png"),
            id: 8,
          },
          {
            title: "learnMore.csss",
            image: require("@/assets/images/parents/csss.png"),
            id: 9,
          },
          {
            title: "stayAlert.others",
            image: require("@/assets/images/kids/other_info.png"),
            id: 10,
          },
        ],
      },
      {
        title: "common.teaching_materials",
        image: require("@/assets/images/parents/teaching_materials_icon.png"),
        route: "Materials",
        children: [
          {
            title: "teaching_materials.ppt1",
            image: require("@/assets/images/learnMore/adults/tm01.png"),
            id: 1,
          },
          {
            title: "teaching_materials.ppt2",
            image: require("@/assets/images/learnMore/adults/tm02.png"),
            id: 2,
          },
          {
            title: "teaching_materials.ppt3",
            image: require("@/assets/images/learnMore/adults/tm03.png"),
            id: 3,
          },
          {
            title: "teaching_materials.ppt4",
            image: require("@/assets/images/learnMore/adults/tm04.png"),
            id: 4,
          },
        ],
      },
      {
        title: "common.link",
        image: require("@/assets/images/parents/partner_org_icon.png"),
        route: "Partnership",
        children: [
          {
            title: "common.link",
            image: require("@/assets/images/friendly_links.png"),
          },
        ],
      },
    ],
    recommend: [
      {
        image: require("@/assets/images/linking/adults/naked_chat.jpg"),
        desc: {
          en: "What is Naked Chat Blackmail？",
          tc: "什麼是裸聊勒索？",
          sc: "什么是裸聊勒索？",
        },
        url: {
          en:
            "https://cyberdefender.hk/en-us/naked_chat/?playlist=f13d8ba&video=a358f20",
          tc:
            "https://cyberdefender.hk/naked_chat/?playlist=f13d8ba&video=a358f20",
          sc:
            "https://cyberdefender.hk/zh-cn/naked_chat/?playlist=f13d8ba&video=a358f20",
        },
      },
      {
        image: require("@/assets/images/linking/adults/employ_fraud.jpg"),
        desc: {
          en: "What is Online Employment Fraud？",
          tc: "什麼是網上求職騙案？",
          sc: "什么是网上求职骗案？",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/online_employment_fraud/",
          tc: "https://cyberdefender.hk/online_employment_fraud/",
          sc: "https://cyberdefender.hk/zh-cn/online_employment_fraud/",
        },
      },
      {
        image: require("@/assets/images/linking/adults/cp.jpg"),
        desc: {
          en: "Child pornography",
          tc: "兒童色情",
          sc: "儿童色情",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/child_porn/",
          tc: "https://cyberdefender.hk/child_porn/",
          sc: "https://cyberdefender.hk/zh-cn/child_porn/",
        },
      },
      {
        image: require("@/assets/images/linking/adults/sexual_violence.png"),
        desc: {
          en: "What's Sexual Violence ?",
          tc: "何謂性暴力?",
          sc: "何谓性暴力？",
        },
        url: {
          en: "https://www.police.gov.hk/svi/en/definitions.html",
          tc: "https://www.police.gov.hk/svi/tc/definitions.html",
          sc: "https://www.police.gov.hk/svi/sc/definitions.html",
        },
      },
      {
        image: require("@/assets/images/linking/adults/grooming.jpg"),
        desc: {
          en: "Online Child Sexual Grooming",
          tc: "性誘識兒童",
          sc: "性诱识儿童",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/child_grooming/",
          tc: "https://cyberdefender.hk/child_grooming/",
          sc: "https://cyberdefender.hk/zh-cn/child_grooming/",
        },
      },
      {
        image: require("@/assets/images/linking/adults/guidelines.jpg"),
        desc: {
          en: "Internet Guidelines for teenagers",
          tc: "給年青人和兒童的上網指引",
          sc: "给年青人和儿童的上网指引",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/guideline_for_teens/",
          tc: "https://cyberdefender.hk/guideline_for_teens/",
          sc: "https://cyberdefender.hk/zh-cn/guideline_for_teens/",
        },
      },
      {
        image: require("@/assets/images/linking/adults/tips.jpg"),
        desc: {
          en: "Tips for cultivating good online habits",
          tc: "社交媒體應用程式進行私隱設定",
          sc: "社交媒体应用程序进行隐私设定",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/privacy_setting/",
          tc: "https://cyberdefender.hk/privacy_setting/",
          sc: "https://cyberdefender.hk/zh-cn/privacy_setting/",
        },
      },
      {
        image: require("@/assets/images/linking/adults/protect.jpg"),
        desc: {
          en: "保護兒童免受網絡性罪行威脅(Chinese version only)",
          tc: "保護兒童免受網絡性罪行威脅",
          sc: "保护儿童免受网络性罪行威胁",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/child-protection/",
          tc: "https://cyberdefender.hk/child-protection/",
          sc: "https://cyberdefender.hk/zh-cn/child-protection/",
        },
      },
      {
        image: require("@/assets/images/linking/adults/device.jpg"),
        desc: {
          en: "Secure Your Device",
          tc: "保護你的裝置",
          sc: "保护你的装置",
        },
        url: {
          en: "https://cyberdefender.hk/en-us/secure-your-device/",
          tc: "https://cyberdefender.hk/secure-your-device/",
          sc: "https://cyberdefender.hk/zh-cn/secure-your-device/",
        },
      },
      {
        image: require("@/assets/images/linking/adults/fcsv2.jpg"),
        desc: {
          en: "心有所信‧虐兒案件調查組(Chinese version only)",
          tc: "心有所信‧虐兒案件調查組",
          sc: "心有所信‧虐儿案件调查组",
        },
        url: {
          en: "https://www.youtube.com/watch?v=phkjIO4cFD4",
          tc: "https://www.youtube.com/watch?v=phkjIO4cFD4",
          sc: "https://www.youtube.com/watch?v=phkjIO4cFD4",
        },
      },
      {
        image: require("@/assets/images/linking/adults/fcsv.jpg"),
        desc: {
          en: "心有所信‧家庭衝突及性暴力政策組(Chinese version only)",
          tc: "心有所信‧家庭衝突及性暴力政策組",
          sc: "心有所信‧家庭冲突及性暴力政策组",
        },
        url: {
          en:
            "https://www.youtube.com/watch?v=xn6LZpJ4kl4&list=PLslN1DbNu8TMgpfz6b1V1jp3LK52-tU3E&index=3",
          tc:
            "https://www.youtube.com/watch?v=xn6LZpJ4kl4&list=PLslN1DbNu8TMgpfz6b1V1jp3LK52-tU3E&index=3",
          sc:
            "https://www.youtube.com/watch?v=xn6LZpJ4kl4&list=PLslN1DbNu8TMgpfz6b1V1jp3LK52-tU3E&index=3",
        },
      },
    ],
  }),
};
</script>
