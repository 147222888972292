<template>
  <div class="home">
    <BackButton />
    <div :class="{ desktop: !isMobile, mobile: isMobile }" class="unselectable">
      <v-responsive id="t1" :aspect-ratio="1028 / 577" width="100%">
        <v-img :src="require('@/assets/images/game/game1/game1_bg1.png')">
          <!-- Header -->
          <v-row class="align-center pa-3">
            <v-col cols="3">
              <v-img
                id="timer"
                :src="require('@/assets/images/game/game1/timer.png')"
                contain
                class="text-center align-center"
              >
                <div class="timer-text">
                  {{ time + $t("game1.s") }}
                </div>
              </v-img>
            </v-col>
            <v-col cols="6">
              <v-img
                class="align-center text-center"
                id="header"
                :src="require('@/assets/images/game/game1/game_header1.png')"
                contain
                width="75%"
              >
                <div class="stage-text">
                  {{ $t("game1.level") }} {{ currentLevel }}
                </div>
              </v-img>
            </v-col>
            <v-col cols="2">
              <v-img
                id="close_btn"
                :src="require('@/assets/images/game/game1/game1_close_btn.png')"
                @click="goToGameCenter"
              />
            </v-col>
          </v-row>

          <!-- Tick -->
          <v-img
            id="tick"
            :src="require('@/assets/images/game/game1/tick.png')"
            :style="{ display: displayTick ? 'block' : 'none' }"
            contain
          />

          <!-- Cross -->
          <v-img
            id="cross"
            :src="require('@/assets/images/game/game1/cross.png')"
            :style="{ display: displayCross ? 'block' : 'none' }"
            contain
          />

          <!-- Card Container -->
          <v-row
            id="card-container"
            class="cards"
            style="margin: auto; height: 70%"
            :style="{ width: containerWidth }"
          >
            <div
              id="t2"
              style="
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin: auto;
              "
            >
              <span
                class="card"
                v-for="(card, index) in cards"
                :key="index"
                :style="{ padding: cardPadding }"
              >
                <div
                  class="card"
                  :class="{ flipped: card.flipped, found: card.found }"
                  @click="flipCard(card)"
                  :style="{
                    width: isMobile ? '50px' : '96px',
                    height: isMobile ? '70px' : '135px',
                    width: cardContainerWidth / cardWidthDivider + 'px',
                    height:
                      ((cardContainerWidth / cardWidthDivider) * 235) / 167 +
                      'px',
                  }"
                >
                  <div class="back"></div>
                  <div class="front">
                    <v-img
                      :src="card.image"
                      contain
                      :aspect-ratio="167 / 235"
                      width="200px"
                    />
                  </div>
                </div>
              </span>
            </div>
          </v-row>
          <GameDialog
            v-if="showSplash"
            :title="dialog.title"
            :subtitle="dialog.subtitle"
            :headerText="dialog.headerText"
            :success="dialog.success"
            :leftButtonText="dialog.leftButtonText"
            :rightButtonText="dialog.rightButtonText"
            :leftButtonAction="dialog.leftButtonAction"
            :rightButtonAction="dialog.rightButtonAction"
            :wrapperStyle="{ top: '17%' }"
          />
        </v-img>
      </v-responsive>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// #game_container {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   #bg1 {
//     position: absolute;
//     width: 100%;
//     max-height: 75%;
//     left: 0;
//   }
//   #bg2 {
//     // position: absolute;
//     width: 100%;
//     max-height: 75%;
//     left: 0;
//   }
//   img {
//     // position: absolute;
//   }
// }
// .bg {
//   width: 100%;
//   display: flex;
//   flex: 1;
// }
// #game_container {
//   background-size: contain;
//   display: flex;
//   flex: 1;
// }

/*
<div class="score">LEVEL 1 : {{ scoreA }}</div>
<div class="score">LEVEL 2 : {{ scoreB }}</div>
<div class="score">LEVEL 3 : {{ scoreC }}</div>
<div class="score">MAX SCORE : {{ maxscore }}</div>
*/
</style>

<script>
// @ is an alias to /src
import BackButton from "@/components/atoms/BackButton.vue";
import ExpansionPanels from "@/components/atoms/ExpansionPanels.vue";
import GameDialog from "@/components/pages/ChillOutCorner/GameDialog.vue";
import Game1Data from "@/components/pages/ChillOutCorner/Game1Data.js";
import _ from "lodash";
import moment from "moment";

export default {
  name: "Game1",
  components: {
    BackButton,
    ExpansionPanels,
    GameDialog,
  },

  data: () => ({
    cards: [
      {
        image: "",
        flipped: false,
        found: false,
      },
    ],

    started: false,
    startTime: 120,
    flipBackTimer: null,
    timer: null,
    time: "120",
    displayTick: false,
    displayCross: false,
    currentLevel: 1,
    showSplash: true,
    timeSpent: 0,
    // GameDialog Data
    dialog: {
      title: "",
      subtitle: "",
      headerText: "",
      success: true,
      leftButtonText: "",
      rightButtonText: "",
      leftButtonAction: () => {},
      rightButtonAction: () => {},
    },

    CardTypes: [],

    CardPool: [
      // Card and image

      { name: "TT1", image: require("@/assets/images/game/game1/TT-1.png") },

      { name: "TT2", image: require("@/assets/images/game/game1/TT-2.png") },

      { name: "TT3", image: require("@/assets/images/game/game1/TT-3.png") },

      { name: "AA1", image: require("@/assets/images/game/game1/AA-1.png") },

      { name: "AA2", image: require("@/assets/images/game/game1/AA-2.png") },

      { name: "AA3", image: require("@/assets/images/game/game1/AA-3.png") },

      { name: "LL1", image: require("@/assets/images/game/game1/LL-1.png") },

      { name: "LL2", image: require("@/assets/images/game/game1/LL-2.png") },

      { name: "LL3", image: require("@/assets/images/game/game1/LL-3.png") },

      { name: "KK1", image: require("@/assets/images/game/game1/KK-1.png") },

      { name: "KK2", image: require("@/assets/images/game/game1/KK-2.png") },

      { name: "KK3", image: require("@/assets/images/game/game1/KK-3.png") },

      { name: "DD1", image: require("@/assets/images/game/game1/dog-1.png") },

      { name: "DD2", image: require("@/assets/images/game/game1/dog-2.png") },

      { name: "DD3", image: require("@/assets/images/game/game1/dog-3.png") },
    ],

    cardContainerWidth: 0,

    cardWidthDivider: 7,
    containerWidth: "70%",
    cardPadding: "0 4% 4% 0",
  }),

  methods: {
    // We can add all function here

    playlevel() {
      // replay fail level function
      this.getleveldata();
      // if (this.currentLevel == 1) {
      //   this.level();
      //   this.time = 120;
      //   this.showSplash = false;
      // } else if (this.currentLevel == 2) {
      //   this.level2();
      //   this.time = 120;
      //   this.showSplash = false;
      // } else if (this.currentLevel == 3) {
      //   this.level3();
      //   this.time = 120;
      //   this.showSplash = false;
      // }
    },

    goToGameCenter() {
      // Go back game center page

      // this.$router.push("/ChillOutCorner");
      this.$router.go(-1);
    },

    goToIndex() {
      // Go back Home page
      this.$router.push("/#");
    },

    startwindows() {
      // Wellcome windows message & button
      this.showSplash = true;
      this.dialog.headerText = null;
      this.dialog.title = "game1.welcome";
      this.dialog.subtitle = "game1.youhave120s";
      this.dialog.leftButtonText = "game1.play";
      this.dialog.leftButtonAction = this.startBox;
      this.dialog.rightButtonText = "game1.playlater";
      this.dialog.rightButtonAction = this.goToGameCenter;
    },

    startBox() {
      // Play button function
      // this.resetgame(true);
      // this.initGame(5);
      // this.resetgame(true);
      // this.showSplash = false;
      this.getleveldata();
    },

    shuffleCards() {
      let cards = [].concat(
        _.cloneDeep(this.CardTypes),
        _.cloneDeep(this.CardTypes)
      );
      return _.shuffle(cards);
    },

    resetgame(showAllCardsAtBeginning) {
      // Show all card 5s
      let cards = this.shuffleCards();
      this.turns = 0;
      this.score = 0;
      this.started = false;
      this.startTime = 120;

      _.each(cards, (card) => {
        card.flipped = showAllCardsAtBeginning ? true : false; // call line 103
        card.found = false;
      });

      this.cards = cards;

      if (showAllCardsAtBeginning) {
        // show all Cards
        setInterval(() => {
          _.each(cards, (card) => {
            card.flipped = false;
          });
        }, 5000); // show card time : 5 Second
      }
    },

    flippedCards() {
      return _.filter(this.cards, (card) => card.flipped);
    },

    sameFlippedCard() {
      // same card

      let flippedCards = this.flippedCards();
      if (flippedCards.length == 2) {
        if (flippedCards[0].name == flippedCards[1].name) return true;
      }
    },

    setCardFounds() {
      _.each(this.cards, (card) => {
        if (card.flipped) card.found = true;
      });
    },

    checkAllFound() {
      let foundCards = _.filter(this.cards, (card) => card.found);
      if (foundCards.length == this.cards.length) return true;
    },

    startGame() {
      // Game Start

      this.started = true;
      this.startTime = 120;

      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          this.stopTimer();
          this.timeIsUp();
        }
      }, 1000);
    },

    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },

    timeIsUp() {
      this.stopTimer();
      (this.dialog.success = false), (this.dialog.title = null);
      this.dialog.headerText = "game1.timeup";
      this.dialog.subtitle = "game1.gamefail";
      this.dialog.leftButtonText = "game1.tryagain";
      this.dialog.leftButtonAction = this.playlevel;
      this.dialog.rightButtonText = "game1.leave";
      this.dialog.rightButtonAction = this.goToGameCenter;
      this.showSplash = true;
    },

    initGame(numOfpairs = 5) {
      // Level 1 add card number
      this.CardTypes = [];

      for (let i = 0; i < this.CardPool.length && i < numOfpairs; ++i) {
        this.CardTypes.push(this.CardPool[i]);
      }
    },

    finishGame() {
      // Finish Game function
      this.started = false;
      clearInterval(this.timer);
      this.timeSpent = this.timeSpent + (120 - this.time);

      // check Level time
      let leveltime = 120 - this.time;
      this.leveltime = leveltime;
      console.log("This Level Time is: " + this.leveltime);

      // Level formula
      /*
      let score = 1000 - (moment().diff(this.startTime, "seconds") - this.CardTypes.length * 5) * 3 - (this.turns - this.CardTypes.length) * 5;
      this.score = Math.max(score, 0);
      this.showSplash = true;
      */
      this.dialog.title = "";
      this.dialog.headerText = "game1.welldone";
      this.dialog.subtitle = "game1.level" + this.currentLevel;
      this.dialog.leftButtonText = "game1.nextlevel";
      this.dialog.rightButtonText = "game1.leave";
      this.dialog.rightButtonAction = this.goToGameCenter;

      this.dialog.leftButtonAction = this.getleveldata;

      if (this.currentLevel == Game1Data.length) {
        console.log("currentlevel" + Game1Data.length);
        this.dialog.subtitle = "game1.level" + Game1Data.length;
        this.dialog.headerText = "game1.amazing";
        this.dialog.leftButtonText = "game1.playagain";
        this.dialog.leftButtonAction = this.goToGameCenter;
        this.dialog.rightButtonText = "game1.leave";
        this.dialog.rightButtonAction = this.goToIndex;

        console.log("All Level Time is: " + this.timeSpent);

        this.currentLevel--;
      }
      this.currentLevel++;
      setTimeout(() => {
        this.showSplash = true;
      }, 1000);
    },

    flipCard(card) {
      if (card.found || card.flipped) return;

      if (!this.started) {
        this.startGame();
      }

      let flipCount = this.flippedCards().length;
      if (flipCount == 0) {
        card.flipped = !card.flipped;
      } else if (flipCount == 1) {
        card.flipped = !card.flipped;
        this.turns += 1;

        if (this.sameFlippedCard()) {
          // Match!
          this.flipBackTimer = setTimeout(() => {
            this.displayTick = true;
            setTimeout(() => (this.displayTick = false), 1100);
            this.clearFlipBackTimer();
            this.setCardFounds();
            this.clearFlips();

            if (this.checkAllFound()) {
              this.finishGame();
            }
          }, 300); // Timem
        } else {
          // Wrong match
          this.flipBackTimer = setTimeout(() => {
            this.displayCross = true;
            setTimeout(() => (this.displayCross = false), 1100);
            this.clearFlipBackTimer();
            this.clearFlips();
          }, 1000); // Time
        }
      }
    },

    clearFlips() {
      // clearflips

      _.map(this.cards, (card) => (card.flipped = false));
    },

    clearFlipBackTimer() {
      clearTimeout(this.flipBackTimer);
      this.flipBackTimer = null;
    },

    getleveldata() {
      let curGameData = Game1Data[this.currentLevel - 1];

      this.resetgame(true);

      this.time = curGameData.time;
      this.showSplash = curGameData.showSplash;
      this.initGame(curGameData.pairs);

      this.containerWidth = curGameData.containerWidth;
      this.cardWidthDivider = curGameData.cardWidthDivider;
      this.cardPadding = curGameData.cardPadding;
      this.onWindowsResize(50);
      this.resetgame(true);
    },

    level() {
      this.getleveldata();
    },

    // level2() {
    //   // Level 2 function
    //   this.currentLevel = 2;
    //   this.showSplash = false;
    //   this.time = 120;
    //   this.initGame(10);
    //   this.containerWidth = "80%";
    //   this.cardWidthDivider = 9;
    //   this.cardPadding = "0 1% 1% 0";
    //   this.onWindowsResize(50);
    //   this.resetgame(true);
    // },

    // level3() {
    //   // Level 3 function
    //   this.currentLevel = 3;
    //   this.showSplash = false;
    //   this.time = 120;
    //   this.initGame(15);
    //   this.containerWidth = "90%";
    //   this.cardWidthDivider = 12;
    //   this.cardPadding = "0 1% 1% 0";
    //   this.onWindowsResize(50);
    //   this.resetgame(true);
    // },

    onWindowsResize(x = 0) {
      let _w = parseInt(document.getElementById("card-container").offsetWidth);

      console.log(typeof x);
      if (typeof x != "object") {
        _w += x;
      }
      console.log("_w", _w);
      this.cardContainerWidth = _w;
    },
  },

  created() {
    this.startwindows();
  },

  mounted() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      window.addEventListener("resize", this.onWindowsResize);
      this.onWindowsResize();
    });
  },

  destroyed() {
    window.removeEventListener("resize", this.onWindowsResize);
  },
};
</script>

<style lang="scss" scoped>
// @import "compass";
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  background-color: #262727;
  color: White;
  font-size: 16px;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  margin: 2em; // Top
}

.info {
  text-align: center;
  padding-bottom: 2em;
  border-bottom: 5px solid #555;
}
.info > div {
  display: inline-block; // Time & Turns 間距
  width: 200px;
}
.info > div .label {
  margin-right: 6px;
}
.info > div .value {
  font-weight: bold; // Text size
  text-align: center;
}
.cards {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
}

.cards .card {
  position: relative;
  display: flex;
  // padding: 0 1% 1% 0;
  // width: 50px;
  // height: 155px;
  // margin: 0vw 0vw;
  //   margin: 0.5em 0.3em;
  //   margin: 1em 2em;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  background-size: cover;
}

.cards .card .front,
.cards .card .back {
  // border-radius: 5px;
  width: "100%";
  height: "100%";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //   width: 100%;
  //   height: 100%;
  background-color: transparent; // Card Colour
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cards .card .back {
  background-image: url("../../../assets/images/game/game1/card_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  font-size: 12px;
}

.cards .card .front {
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
}

.cards .card.flipped .back,
.cards .card.found .back {
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.cards .card.flipped .front,
.cards .card.found .front {
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.cards .card.found {
  opacity: 0.3;
}

.flex-center {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // top: 3%;
}

#timer {
  //position: absolute;
  top: 10%;
  left: 9%;
  width: 100%;
}

#tick,
#cross {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 20vw;
  z-index: 4;
  pointer-events: none;
}

#close_btn {
  // position: absolute;
  width: 3vw;
  // top: 10%;
  right: -2vw;
}

#header {
  //   position: absolute;
  width: 50vw;
  left: 0;
  right: 0;
  top: 0;
  z-index: 44;
  margin: 0 auto 0 auto;
}

.timer-text {
  color: #fff;
  margin-left: 20%;
  font-size: 1.5em;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 2);
}

.stage-text {
  color: #fff;
  font-size: 1.5em;
}

.mobile .stage-text {
  font-size: 3vw;
}

.mobile .timer-text {
  font-size: 3vw;
}

.unselectable {
  user-select: none;
}
</style>
