<template>
  <div :class="isMobile ? '' : 'container'" ref="container">
    <!-- <v-img :sources="require('@/assets/images/activity/run2023.jpg')" /> -->
    <v-row>
      <v-col
        style="background-color: #231816"
        :style="{
          'background-color': banners.length > 0 ? '#231816' : 'tramspraent',
          padding: isMobile ? '0' : '0 24px 12px 24px',
        }"
      >
        <v-carousel
          v-model="carouselIndex"
          ref="carousel"
          height="auto"
          hide-delimiter-background
          :show-arrows="false"
          style="padding-bottom: 20px"
          @change="setInterval"
          :interval="interval"
          :light="false"
          v-if="banners.length > 0"
        >
          <v-carousel-item v-for="(item, i) in banners" :key="i">
            <v-responsive
              width="100%"
              aspect-ratio="2"
              class="d-flex justify-center mb-2"
              id="video-container-1"
            >
              <youtube
                v-if="item.type == 'youtube'"
                :video-id="$youtube.getIdFromUrl(filterValue(item.youtube))"
                :ref="'youtube-' + i"
                :player-vars="{ autoplay: 1 }"
                :width="width"
                :height="height"
                @ended="next()"
              />
              <v-img
                v-if="item.type == 'image'"
                :src="filterValue(item.image)"
                contain
                :width="width"
                :height="height"
              />
              <video-player
                v-if="item.type == 'media'"
                class="video-player-box vjs-big-play-centered"
                :ref="'videoPlayer-' + i"
                :options="{
                  // videojs options
                  width,
                  height,
                  autoplay: true,
                  muted: true,
                  language: 'en',
                  playbackRates: [0.7, 1.0, 1.5, 2.0],
                  sources: [
                    {
                      type: 'video/mp4',
                      src: filterValue(item.video),
                    },
                  ],
                }"
                :playsinline="true"
                @ended="next()"
              />

              <!--  -->
            </v-responsive>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        :class="isMobile ? '' : 'mt-4'"
        :style="{
          'background-color': filterValue(bannerDesc) ? 'white' : 'transparent',
        }"
      >
        <p
          class="mb-0 b-tx c-font-family"
          :style="{
            'font-size': isMobile ? '3vw' : '1.25vw',
            color: isMobile ? 'grey' : '#005385',
          }"
        >
          {{ filterValue(bannerDesc) }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.v-carousel {
  .v-carousel__controls {
    height: 35px !important;
  }
}
</style>

<script>
// @ is an alias to /src
import config from "../../../config";
import axios from "axios";

export default {
  name: "Banner",
  computed: {
    currentRouteName() {
      this.shdDisplayBanner;
      return this.$route.name;
    },
    shdDisplayBanner() {
      // console.log(this.$route);

      const path = this.$route.path;

      if (path.includes("ChillOutCorner")) {
        return false;
      }

      if (this.$route.name == "HelpingHand") {
        return false;
      }

      return true;
    },
    player() {
      return this.$refs.videoPlayer.player;
    },
    height() {
      // console.log(
      //   "this.$ref.youtube.clientHeight",
      //   this.$ref.youtube.clientHeight
      // );
      return this.$ref.youtube.clientHeight;
    },
  },
  // props: ["bannerDesc"],
  props: {
    // bannerDesc: Object,
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.setWidthHeight();

    const _this = this;
    this.timer = window.setInterval(() => {
      _this.next();
      _this.setWidthHeight();
    }, this.interval);
  },
  data: () => ({
    interval: 96000,
    playerOptions: {},
    carouselIndex: 0,
    timer: null,

    banners: [],

    width: 0,
    height: 0,
    bannerDesc: {},
  }),
  watch: {
    width: function (prev, cur) {
      //
      if (prev != cur) {
        this.setWidthHeight();
      }
    },
  },
  methods: {
    fetchData() {
      //
      axios
        .get(config.wordpressUrl + `/configs?order=asc&per_page=100`)
        .then((response) => {
          let data = response.data[0];

          if (data != null) {
            this.bannerDesc = this.getFieldFromResp(
              { acf: data.acf.home_page_banner_description },
              "description"
            );

            console.log("this.bannerDesc", this.bannerDesc);

            this.banners = data.acf.banners.map((banner) => {
              return {
                image: this.getFieldFromResp({ acf: banner }, "image.image"),
                type: banner.banner_type,
                video: this.getFieldFromResp({ acf: banner }, "video"),
                youtube: this.getFieldFromResp({ acf: banner }, "youtube_link"),
              };
            });
          }
        });
    },
    setWidthHeight() {
      console.log("setWidthHeight");
      const container = document.getElementById("video-container-1");
      if (container) {
        const _w = container.offsetWidth;
        const _h = container.offsetHeight;

        this.width = _w;
        this.height = _h;
        console.log("this.width", this.width);
        console.log(
          "offsetWidth",
          document.getElementById("video-container-1").offsetWidth
        );
      } else {
        setTimeout(() => this.setWidthHeight(), 500);
      }
    },
    setInterval(index) {
      clearInterval(this.timer);

      if (this.banners[index].type == "image") {
        this.interval = 5000;
      } else {
        this.interval = 96000;
      }

      if (this.banners[index].type == "youtube") {
        // auto play youtube when paused before
        if (this.$refs["youtube-" + index] != null) {
          this.$refs["youtube-" + index][0].player.playVideo();
        }
      } else {
        // pause all youtube
        this.banners.forEach((banner, i) => {
          if (banner.type == "youtube") {
            if (this.$refs["youtube-" + i][0]) {
              this.$refs["youtube-" + i][0].player.pauseVideo();
            }
          }
        });
      }

      const _this = this;
      this.timer = window.setInterval(() => {
        _this.next();
      }, this.interval);

      var index = -1;
      if (this.carousel > 0) {
        index = this.carousel - 1;
      }
    },
    playerReadied(player) {
      // console.log("the player is readied", player);
      // player.playVideo();
    },
    next() {
      this.$refs.carousel.next();
    },
    onPlayerEnded() {
      this.next();
    },
  },
};
</script>
