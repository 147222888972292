<template>
  <div style="padding: 0">
    <BackButton />

    <ContentTemplate contentUrlPath="additional_menu_item" />
  </div>
</template>

<script>
import BackButton from "@/components/atoms/BackButton.vue";
import ContentTemplate from "@/components/templates/ContentTemplate";

// @ is an alias to /src
export default {
  name: "Category",
  components: {
    BackButton,
    ContentTemplate,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {},
  data() {
    return {};
  },
};
</script>
