var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BackButton'),_c('SessionTitle',{staticClass:"mb-6",attrs:{"text":_vm.$t('home.activities'),"color":"#757575"}}),(_vm.item)?_c('v-container',{attrs:{"id":"act-details"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":_vm.item.img.en,"max-width":"800"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? _vm.item.title.en : _vm.$i18n.locale == "tc" ? _vm.item.title.tc : _vm.item.title.sc)+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"text-body-1",staticStyle:{"text-shadow":"2px 2px 3px rgba(0, 0, 0, 0.5)"}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$i18n.locale == 'en'
              ? _vm.item.html.en
              : _vm.$i18n.locale == 'tc'
              ? _vm.item.html.tc
              : _vm.item.html.sc
          )}})])],1)],1):_vm._e(),(_vm.id == 0)?_c('div',{staticClass:"mx-0 px-0 mt-3",class:_vm.isMobile ? '' : 'container',staticStyle:{"min-height":"100px","text-align":"center"},attrs:{"fluid":""}},[_c('DynamicMarquee')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }