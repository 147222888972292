export const sexualAbuse = {
  title: {
    en: "Sexual Assault",
    tc: "性侵犯",
    sc: "性侵犯",
  },
  img: "hh3.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
      // color: "#E36666",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Sexual Assault",
        tc: "性侵犯",
        sc: "性侵犯",
      },
      title: {
        en: "Sexual Assault",
        tc: "性侵犯",
        sc: "性侵犯",
      },
      img: "witness-kids-3.png",
      aspectRatio: 315 / 277,
      showTips: true,
      points: [
        {
          en: "Say “NO” and shout for help!",
          tc: "說「不」及高聲呼救！",
          sc: "说「不」及高声呼救！",
        },
        {
          en: "Remember the details (where, when, who) of the incident",
          tc: "記下事發經過的詳細資料（時間、地點、人物）。",
          sc: "记下事发经过的详细资料（时间、地点、人物）。",
        },
        {
          en: "Don’t keep it to yourself!",
          tc: "不要啞忍！",
          sc: "不要哑忍！",
        },
        {
          en: "Tell a trusted adult.",
          tc: "把事情告知你信任的成年人。",
          sc: "把事情告知你信任的成年人。",
        },
        {
          en:
            "For sexual offences such as rape, the trusted adult will help you retain the concerned clothing and may ask you not to take a shower before seeking medical treatment and reporting to police.",
          tc:
            "在面對性罪行如強姦的時候，你信任的成年人會協助你保留有關衣物，亦可能會告知你在接受醫療檢驗前不要洗澡，以及聯絡警方。",
          sc:
            "在面对性罪行如强奸的时候，你信任的成年人会协助你保留有关衣物，亦可能会告知你在接受医疗检验前不要洗澡，以及联络警方。",
        },
        {
          en:
            "Trusted adult will accompany you to a safe place e.g. police station, hospital…",
          tc: "你信任的成年人會陪同你前往安全地方，如警署、醫院…",
          sc: "你信任的成年人会陪同你前往安全地方，如警署、医院…",
        },
      ],
    },
  ],
};
