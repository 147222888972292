<template>
  <div>
    <SessionTitle class="mb-6" :text="$t('common.aboutUs')" color="#000000"/>

    <span v-html="getText(data).content"></span>
  </div>
</template>
<script>

// @ is an alias to /src
import SessionTitle from "../atoms/SessionTitle.vue";
export default {
  name: "AboutUs",
  components: {
    SessionTitle
  },
  methods:{
    getText(item){
      if(this.$i18n.locale == 'en'){
        return item.en
      } else if(this.$i18n.locale == 'tc'){
        return item.tc
      } else {
        return item.sc
      }
    }
  },
  data: () => ({
    data:{
      en:{
        content: "<b>Who are we?</b><br/>We are the Family Conflict and Sexual Violence Policy Unit, attached under Crime Wing in the Hong Kong Police Force.<br/><br/><b>What do we do?</b><br/>We are responsible for formulating and implementing Force policies and procedures regarding child and elder abuse, domestic and sexual violence, and juvenile crime. We monitor these reports in order to identify and respond to the emerging trends.  FCSV represents the Force in the legislative process whenever new law is proposed with regard to FCSV’s policy areas.<br/><br/><b>Who do we work with?</b><br/>FCSV works closely with other government departments and non-government social service organisations on multi-disciplinary procedures, inter-agency co-operation, training and co-ordination within its policy areas.  FCSV often provides talks and seminars to schools and various institutions as well."
      },
      tc:{
        content: "<b>我們是誰</b><br/>我們是警務處刑事部轄下的家庭衝突及性暴力政策組。<br/><br/><b>我們的工作</b><br/>我們負責制訂和推行警隊處理虐待兒童、虐待長者、家庭暴力、性暴力及青少年罪行的政策和程序。 我們密切監察這些個案進行研究，以掌握最新趨勢和採取相應行動。每當政府就我們政策範圍內的事項立法時，我們將代表警隊在立法過程中跟進有關事宜。<br/><br/><b>我們的合作伙伴</b><br/>我們與其他政府部門和非政府社會服務組織緊密合作，致力於我們政策範圍內的跨專業程序、機構間合作、訓練及協調等相關事宜。我們亦會到訪不同學校或機構參與研討會和提供相關講座。"
      },
      sc:{
        content: "<b>我们是谁</b><br/>我们是警务处刑事部辖下的家庭冲突及性暴力政策组。<br/><br/><b>我们的工作</b><br/>我们负责制订和推行警队处理虐待儿童、虐待长者、家庭暴力、性暴力及青少年罪行的政策和程序。 我们密切监察这些个案进行研究，以掌握最新趋势和采取相应行动。每当政府就我们政策范围内的事项立法时，我们将代表警队在立法过程中跟进有关事宜。<br/><br/><b>我们的合作伙伴</b><br/>我们与其他政府部门和非政府社会服务组织紧密合作，致力于我们政策范围内的跨专业程序、机构间合作、训练及协调等相关事宜。我们亦会到访不同学校或机构参与研讨会和提供相关讲座。"
      }
    }
  }),
};
</script>
