<template>
  <v-card class="mt-4 text-center d-flex flex-column" height="100%">
    <v-img :src="filterValue(items.image)" height="120"></v-img>

    <v-container
      style="min-height:200px"
      class="d-flex justify-center align-center flex-column"
    >
      <div class="justify-center" style="color:#D03A03;">
        {{ $t("learnMore.law") + " : " + items.law }}
      </div>

      <div class="justify-center" style="color:#D03A03;">
        {{ filterValue(items.title) }}
      </div>

      <div class="justify-center">
        {{ $t("learnMore.example") }}
        <span>{{ filterValue(items.example) }}</span>
        <!-- <span v-for="(j, jindex) in items.sample" :key="jindex">
          {{ filterValue(j) }}
          <span v-if="jindex != items.sample.length - 1">, </span>
        </span> -->
      </div>
    </v-container>

    <v-divider></v-divider>

    <v-card-title class="justify-center text-subtitle-1" style="color:#a34100">
      {{ $t("learnMore.penalty") + filterValue(items.penalty) }}
    </v-card-title>

    <v-container
      style="min-height:100px"
      class="d-flex justify-center align-center flex-column"
    >
      <span
        v-for="(k, kindex) in items.donts"
        :key="kindex"
        class="justify-center"
      >
        <v-icon color="red"> mdi-close </v-icon>
        <span class="text-subtitle-2">
          {{ getText(k) }}
        </span>
      </span>
    </v-container>

    <v-spacer></v-spacer>

    <v-card-actions class="justify-end text-right pt-1">
      <v-btn
        text
        style="color: blue"
        class="btn"
        @click="openUrl(filterValue(items.url))"
        :style="{ color: btnColor }"
      >
        <div style="font-size: 14px">
          {{ $t("common.more") }}
        </div>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card__title {
  justify-content: center !important;
  word-break: normal !important;
}
// .v-sheet.v-card:not(.v-sheet--outlined) {
//   box-shadow: none;
// }
// .v-card__subtitle,
// .v-card__text,
// .v-card__title {
//   padding-left: 0px;
// }
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-sheet.v-card {
  border-radius: 20px !important;
}
</style>

<script>
import FastAverageColor from "fast-average-color";
const { hex } = require("wcag-contrast");
let fac;

// @ is an alias to /src
export default {
  name: "LawCard",
  components: {},
  props: ["items"],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    // btnColor(image) {
    //   return 'blue'
    // }
  },
  mounted() {},
  methods: {
    async getMainImageColor(img) {
      let mainColor = "#042851";
      fac = new FastAverageColor();

      await fac
        .getColorAsync(img, {
          ignoredColor: [
            [255, 255, 255, 255], // white
            [0, 0, 0, 255], // black
            [183, 183, 183, 255], // grey
            [221, 88, 74, 255], // red
            [140, 163, 223, 255], //blue
          ],
        })
        .then((color) => {
          // console.log("Average color", color);

          mainColor = color.hex;
          let contrast = hex(color.hex, "#fff");
          this.btnColor = contrast >= 5 ? color.hex : "#6b6d7f";
        })
        .catch((e) => {
          console.log("??", e);
        });

      console.log(mainColor);

      fac.destroy();
    },
    getText(item) {
      if (this.$i18n.locale == "en") {
        return item.en;
      } else if (this.$i18n.locale == "tc") {
        return item.tc;
      } else {
        return item.sc;
      }
    },
    openUrl(url) {
      window.open(url);
    },
    getImageUrl(img) {
      return require(`@/assets/images/learnMore/kids/${img}`);
    },
  },
  async created() {
    // console.log(this.items.image)
    if (this.items && this.items.image) {
      await this.getMainImageColor(this.items.image);
    }
  },
  data() {
    return {
      btnColor: "#042851",
    };
  },
};
</script>
