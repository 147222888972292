export const witness = {
  title: {
    en: "As a Witness…",
    tc: "作為證人…",
    sc: "common.tbc",
  },
  subTitle: {
    en: "Help your friend or someone you know!",
    tc: "幫助你身邊的人",
    sc: "common.tbc",
  },
  img: "witness-kids.png",
  aspectRatio: 364 / 121,
  onCardClick: true,
  children: [
    {
      contentHeader: {
        en: "As a Witness…",
        tc: "作為證人…",
        sc: "common.tbc",
      },
      img: "witness-kids-1.png",
      aspectRatio: 366 / 200,
      // content: "Seek help\nYou are not alone",
      showTips: true,
      points: [
        {
          en: "Listen to him/ her. Your support is very important",
          tc: "你的聆聽是最大的鼓舞",
          sc: "common.tbc",
        },
        // {
        //   en: "Give him/ her reassurance",
        //   tc: "鼓勵他們求助",
        //   sc: "common.tbc"
        // },
        {
          en: "Encourage him/ her to seek help",
          tc: "鼓勵他們求助",
          sc: "common.tbc",
        },
        // {
        //   en: "Tell him/ her to face the situation and solve the problem",
        //   tc: "common.tbc",
        //   sc: "common.tbc"
        // },
        // {
        //   en: "Listen to him/ her. Your support is very important",
        //   tc: "common.tbc",
        //   sc: "common.tbc"
        // },
        // {
        //   en: "To prevent recurrence of similar incident",
        //   tc: "common.tbc",
        //   sc: "common.tbc"
        // }
      ],
      children: [
        // {
        //   title: {
        //     en: "Physical Abuse",
        //     tc: "common.tbc",
        //     sc: "common.tbc",
        //   },
        //   img: "witness-kids-2.png",
        //   aspectRatio: 336 / 273,
        //   showTips: true,
        //   points: [
        //     {
        //       en: "Any superficial medical findings? Seek medical treatment immediately",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Take photos of the injuries with consent",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Leave the place of danger",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Under certain circumstances, Police might consider application of C or P order",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Seek help from social service organisations. Find them under ‘Supporting Organisations’!",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //   ],
        //   children: [
        // {
        //   title: {
        //     en: "Sexual Abuse",
        //     tc: "common.tbc",
        //     sc: "common.tbc",
        //   },
        //   img: "witness-kids-3.png",
        //   aspectRatio: 315 / 277,
        //   showTips: true,
        //   points: [
        //     {
        //       en: "For serious sexual offences, securing evidence is very crucial…retain the clothes of victim for securing evidence; do not take a shower before reporting to the police",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Remember the location of the incident",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Share the burden with an adult you trust. Don’t keep it to yourself!",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //   ],
        //   children: [
        // {
        //   title: {
        //     en: "Cybercrime",
        //     tc: "common.tbc",
        //     sc: "common.tbc",
        //   },
        //   img: "witness-kids-4.png",
        //   aspectRatio: 336 / 271,
        //   showTips: true,
        //   points: [
        //     {
        //       en: "Learn the offences, visit Videos Gallery",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Reduce and limit the loss as far as possible",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Stop using the involved accounts. Protect yourself from further data/ monetary loss.",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Enhance your cyber security",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //     {
        //       en: "Help prevent other victims falling into such traps",
        //       tc: "common.tbc",
        //       sc: "common.tbc"
        //     },
        //   ],
        //   children: [
        //     {
        //       title: {
        //         en: "Cyberbullying",
        //         tc: "common.tbc",
        //         sc: "common.tbc",
        //       },
        //       img: "witness-kids-5.png",
        //       aspectRatio: 278 / 225,
        //       showTips: true,
        //       points: [
        //         {
        //           en: "Don’t take revenge, it won’t do any good!",
        //           tc: "common.tbc",
        //           sc: "common.tbc"
        //         },
        //         {
        //           en: "Stay safe in the cyber world",
        //           tc: "common.tbc",
        //           sc: "common.tbc"
        //         },
        //         {
        //           en: "Report. Many social media platforms have clear policies and reporting processes",
        //           tc: "common.tbc",
        //           sc: "common.tbc"
        //         },
        //         {
        //           en: "Do not further circulate any video/ do not download any concerned videos",
        //           tc: "common.tbc",
        //           sc: "common.tbc"
        //         },
        //         {
        //           en: "Do not fan the flames! Do not add any more comments",
        //           tc: "common.tbc",
        //           sc: "common.tbc"
        //         },
        //         {
        //           en: "Warning signs of victim: Noticeable increases/ decreases in device use, emotionally upset",
        //           tc: "common.tbc",
        //           sc: "common.tbc"
        //         },
        //         {
        //           en: "Avoid escalating the situation",
        //           tc: "common.tbc",
        //           sc: "common.tbc"
        //         },
        //       ],
        // children: [
        {
          img: "witness-kids-6.png",
          aspectRatio: 366 / 200,
          content: {
            en: "Don’t burden yourself",
            tc: "別獨自負起重担",
            sc: "common.tbc",
          },
          children: [
            {
              img: "witness-kids-7.png",
              aspectRatio: 366 / 200,
              content: {
                en:
                  "Offer to accompany your friend to share with an adult you trust as soon as possible, who can be a parent, teacher or social worker…",
                tc: "可以一起去告訴給信任的成人聽，例如父母、老師或社工",
                sc: "common.tbc",
              },
              children: [
                {
                  img: "witness-kids-8.png",
                  aspectRatio: 366 / 200,
                  content: {
                    en:
                      "After understanding the situation, the case may be reported to police if necessary!",
                    tc: "成人會了解情況，有需要時便會向警察求助",
                    sc: "common.tbc",
                  },
                  // children: [
                  //   {
                  //     img: "witness-kids-9.png",
                  //     aspectRatio: 366 / 200,
                  //     content: {
                  //       en: "Give a statement to help us\nunderstand your friend’s situation",
                  //       tc: "common.tbc",
                  //       sc: "common.tbc"
                  //     },
                  //   }
                  // ]
                },
              ],
            },
          ],
          //       }
          //     ]
          //   }
          // ]
          //   }
          // ]
          //     }
          //   ]
        },
      ],
    },
  ],
};
