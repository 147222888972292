<template>
  <v-card class="d-flex flex-column mt-4" height="100%">
    <v-img :aspect-ratio="1.7" :src="filterValue(items.image)" width="100%" cover />

    <div class="d-flex align-center justify-center " style="min-height: 70px">
      <v-card-title
        class="text-center py-0"
        :style="{
          'font-size': isMobile ? '0.875rem' : '1.25rem',
          'line-height': isMobile ? '1.25rem' : '1.35rem',
        }"
      >
        {{ $t(filterValue(items.title)) }}
      </v-card-title>
    </div>
    <v-card-actions class="justify-end text-right pt-1" v-if="isShowMore">
      <v-btn
        text
        style="color: blue"
        class="btn"
        :style="{ color: btnColor }"
        @click="
          $router.push({
            name: items.route,
            query: { id: items.id, item: items },
          })
        "
      >
        <div :style="{ 'font-size': '14px' }">
          {{
            items.btnText != undefined ? $t(items.btnText) : $t("common.more")
          }}
        </div>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card__title {
  justify-content: center !important;
  word-break: normal !important;
}
// .v-sheet.v-card:not(.v-sheet--outlined) {
//   box-shadow: none;
// }
// .v-card__subtitle,
// .v-card__text,
// .v-card__title {
//   padding-left: 0px;
// }
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-sheet.v-card {
  border-radius: 10px !important;
}
</style>

<script>
import FastAverageColor from "fast-average-color";
const { hex } = require("wcag-contrast");
let fac;
// @ is an alias to /src

export default {
  name: "Card",
  components: {},
  props: {
    items: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowMore: {
      type: Boolean,
      default: false,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    showDescn: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openUrl(url) {
      if (url) {
        // window.location.href = url;
        window.open(url, "_blank").focus();
      }
    },
    async getMainImageColor(img) {
      let mainColor = "#042851";
      fac = new FastAverageColor();

      await fac
        .getColorAsync(img, {
          ignoredColor: [
            [255, 255, 255, 255], // white
            [0, 0, 0, 255], // black
            [183, 183, 183, 255], // grey
            [221, 88, 74, 255], // red
            [140, 163, 223, 255], //blue
          ],
        })
        .then((color) => {
          // console.log("Average color", color);

          mainColor = color.hex;

          let contrast = hex(color.hex, "#fff");
          // console.log(this.items.title + " - hex", contrast);

          this.btnColor = contrast >= 5 ? color.hex : "#6b6d7f";
        })
        .catch((e) => {
          // console.log(e);
        });

      // console.log(mainColor);

      fac.destroy();
    },
  },
  async created() {
    // console.log(this.items.image)
    if (this.items && this.items.image) {
      await this.getMainImageColor(this.items.image);
    }
  },
  data: () => ({
    btnColor: "#042851",
  }),
};
</script>
