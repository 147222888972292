<template>
  <div :class="isMobile ? '' : 'container'">
    <v-row>
      <v-col cols="7">
        <SessionTitle :text="$t('common.sponsoredBy')" color="#757575" />
      </v-col>
      <v-col class="text-right"> </v-col>
    </v-row>

    <div
      :class="isMobile ? 'marquee-mobile' : 'marquee'"
      v-if="images.length > 0"
    >
      <Marquee
        direction="row"
        :reverse="true"
        :hoverPause="false"
        :speed="speed"
        :pause="paused"
      >
        <div class="marquee-content">
          <figure v-for="(src, i) in sponsor" :key="i" class="image-frame">
            <v-img
              class="d-flex justify-center align-center text-center"
              :src="src"
              :width="isMobile ? 150 : 250"
              :height="isMobile ? '10vh' : '20vh'"
              contain
            >
            </v-img>
          </figure>
        </div>
      </Marquee>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Marquee from "vue-dynamic-marquee";
import VueLazyload from "vue-lazyload";
import Vue from "vue";
import SessionTitle from "../atoms/SessionTitle.vue";
Vue.use(VueLazyload, {
  observer: true,
  loading: "",
});

const normalSpeed = 80;

function randomUrl() {
  const random = () => Math.round(Math.random() * 50 + 30) * 10;
  return `https://source.unsplash.com/random/${random() *
    1.3}x${random()}?monument,history`;
}

export default {
  name: "DynamicMarquee",
  components: {
    Marquee,
    SessionTitle,
  },
  data: () => ({
    images: [],
    speed: { type: "pps", number: normalSpeed },
    state: "playing",
    paused: false,
    length: 0,
    sponsor: [
      // require("@/assets/images/icon.png"),
      require("@/assets/images/sponsor/logo1.png"),
      require("@/assets/images/sponsor/logo2.png"),
      require("@/assets/images/sponsor/logo3.png"),
      require("@/assets/images/sponsor/logo4.png"),
      require("@/assets/images/sponsor/logo5.png"),
      require("@/assets/images/sponsor/logo6.png"),
      require("@/assets/images/sponsor/logo7.png"),
      require("@/assets/images/sponsor/logo8.png"),
      require("@/assets/images/sponsor/logo9.png"),
      require("@/assets/images/sponsor/logo10.png"),
      require("@/assets/images/sponsor/logo11.png"),
      require("@/assets/images/sponsor/logo12.png"),
      require("@/assets/images/sponsor/logo13.png"),
      require("@/assets/images/sponsor/logo14.png"),
      require("@/assets/images/sponsor/logo15.png"),
      require("@/assets/images/sponsor/logo16.png"),
      require("@/assets/images/sponsor/logo17.png"),
      require("@/assets/images/sponsor/logo18.png"),
      require("@/assets/images/sponsor/logo19.png"),
      require("@/assets/images/sponsor/sup_logo_1.png"),
      require("@/assets/images/sponsor/sup_logo_2.png"),
      require("@/assets/images/sponsor/sup_logo_3.png"),
      require("@/assets/images/sponsor/sup_logo_4.png"),
      require("@/assets/images/sponsor/sup_logo_5.png"),
      require("@/assets/images/sponsor/sup_logo_6.png"),
    ],
  }),
  methods: {
    slowDown() {
      console.log("a");

      this.state = "slowing";
      const loop = () => {
        if (this.state !== "slowing") return clearInterval(interval);
        const { speed } = this;
        if (speed.number < 1) {
          this.paused = true;
          return clearInterval(interval);
        }
        speed.number *= 0.5;
      };

      const interval = setInterval(loop, 30);
    },
    accelerate() {
      console.log("b");

      this.state = "acc";
      this.paused = false;
      const loop = () => {
        if (this.state !== "acc") return clearInterval(interval);
        const { speed } = this;
        if (speed.number > normalSpeed - 1) {
          speed.number = normalSpeed;
          return clearInterval(interval);
        }
        speed.number /= 0.5;
      };

      const interval = setInterval(loop, 20);
    },
  },
  created() {
    for (let i = 0; i < 12; i++) {
      this.images.push(randomUrl());
    }
  },
};
</script>

<style lang="scss" scoped>
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.marquee {
  position: relative;
  width: 100%;
  height: 20vh;
  margin: auto;
}
.marquee-mobile {
  position: relative;
  width: 100%;
  height: 10vh;
  margin: auto;
}
.marquee-content {
  height: 100%;
  padding: 0;
  display: flex;
}

.image-frame {
  width: auto;
  height: auto;
  position: relative;
  margin-right: 30px;
  display: block;
}

// .image-frame:hover .overlay {
// 	opacity: 0.7;
// }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}

.image-frame .icon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.interaction {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-10px, 100%);
  display: flex;

  caption {
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 13px;
    color: #333;
    margin: 10px;
  }

  transition: all 0.3s;
}

.image-frame:hover .interaction {
  transform: translate(-20px, 20px);
  background: #fafafa;
  :not(caption) {
    opacity: 1;
  }
}
.image {
  height: 100%;

  transition: opacity 2s;
  &[lazy="loading"] {
    opacity: 0;
  }
}
</style>
