export const cyberbullying = {
  title: {
    en: "Cyberbullying",
    tc: "網絡欺凌",
    sc: "网络欺凌"
  },
  img: "hh4.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
      // color: "#6DC3F2",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Cyberbullying",
        tc: "網絡欺凌",
        sc: "网络欺凌"
      },
      title: {
        en: "Cyberbullying",
        tc: "網絡欺凌",
        sc: "网络欺凌"
      },
      img: "witness-kids-5.png",
      aspectRatio: 278 / 225,
      showTips: true,
      points: [
        {
          en: "Observe more about the behavior of your children.",
          tc: "多留意子女的行為。",
          sc: "多留意子女的行为。"
        },
        {
          en: "Talk more with your children to find out what is happening to them.",
          tc: "多與子女傾談，以了解發生在他們身上的事情。",
          sc: "多与子女倾谈，以了解发生在他们身上的事情。"
        },
        {
          en: "Encourage your children to share through thoughtful discussions.",
          tc: "鼓勵子女在參與深入討論時作出分享。",
          sc: "鼓励子女在参与深入讨论时作出分享。"
        },
        {
          en: "Spot warning signs of your children e.g. noticeable increases/ decreases in device use, emotionally upset.",
          tc: "留意子女身上有否出現危險徵兆，如顯著增加／減少使用電子產品；情緒低落等。",
          sc: "留意子女身上有否出现危险征兆，如显着增加／减少使用电子产品；情绪低落等。"
        },
        {
          en: "If becomes a victim, seek assistance from school, social worker or police.",
          tc: "如果不幸成為受害人，請立即向警方舉報。",
          sc: "如果不幸成为受害人，请立即向警方举报。"
        },
      ],
    }
  ],
};
