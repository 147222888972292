<template>
  <div>
    <BackButton />
    <VideoPage v-for="(i, index) in data" :key="index" :data="i" />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import VideoPage from "@/components/templates/VideoPage.vue";
import BackButton from "@/components/atoms/BackButton.vue";
import config from "../../../../config";
import axios from "axios";

export default {
  name: "AdultsVideoPage",
  components: {
    VideoPage,
    BackButton,
  },
  computed: {},
  props: [],
  data: () => ({
    data: [],
  }),
  mounted() {
    // let path = this.$route.fullPath;
    // let tmp = path.substr(path.indexOf("=") + 1, path.length);
    // this.data = this.getData(parseInt(tmp));
  },
  created() {
    const video_cat = this.$route.query.item;
    if (video_cat.title.en == "Media Interview") {
      this.fetchMediaInterviewData();
    } else {
      this.fetchData();
    }
  },
  methods: {
    fetchMediaInterviewData() {
      axios
        .get(config.wordpressUrl + "/adults_media_cat?order=asc&per_page=100")
        .then((response) => {
          axios
            .get(config.wordpressUrl + "/adults_media?order=asc&per_page=100")
            .then((resp) => {
              console.log("?resp?", resp)
              let dataset = [];

              response.data.map((item) => {
                // let _video = _.cloneDeep(resp.data);

                console.log("resp.data", resp.data.length);
                let videoList = [];

                resp.data.map((i) => {
                  if (i.acf.category != null && i.acf.category.ID == item.id) {
                    videoList.push(this.mapDataToVideoData(i));
                  }
                });

                videoList = videoList.sort((a, b) => {
                  if (a.order > b.order) {
                    return -1;
                  }
                  if (a.order < b.order) {
                    return 1;
                  }
                  return 0;
                });

                dataset.push({
                  info: [
                    {
                      id: item.id,
                      image: this.getImageFromResp(item),
                      title: this.getFieldFromResp(item, "title"),
                    },
                  ],
                  videoList,
                });
              });

              this.data = dataset;

              console.log("dataset", dataset);
            });
        });
    },
    fetchData() {
      axios
        .get(config.wordpressUrl + "/adults_video?order=asc&per_page=100")
        .then((response) => {
          console.log(response.data);
          const id = this.$route.query.id;
          console.log("query.id", id);

          console.log("item", this.$route.query.item);

          let videoData = {
            info: [this.$route.query.item],
            videoList: [],
          };

          const data = response.data;
          if (data) {
            data.map((item) => {
              let category = item.acf.category;

              if (category != null && category.ID == id) {
                videoData.videoList.push(this.mapDataToVideoData(item));
              }
            });

            this.data = [videoData];
            console.log("this.data", this.data);
          }
        });
    },
    mapDataToVideoData(item) {
      return {
        title: this.getFieldFromResp(item, "title"),
        desc: this.getFieldFromResp(item, "description"),
        category: this.getFieldFromResp(item, "category", null),
        image: item.acf.image,
        videoId: this.$youtube.getIdFromUrl(item.acf.link),
      };
    },
    getImageUrl(img) {
      return require(`@/assets/images/parents/${img}`);
    },
    getRImageUrl(img) {
      return require(`@/assets/images/linking/adults/${img}`);
    },
    getData(id) {
      switch (id) {
        case 1: {
          return [
            {
              info: [
                {
                  title: "stayAlert.fraud",
                  image: this.getImageUrl("fraud.png"),
                },
              ],
              videoList: [
                {
                  desc: {
                    en:
                      "“Hello, you’re receiving this call from the Mainland official. We suspect that you have violated the laws……” Commissioner of Police Siu Sir and Anti-Deception Coordination Centre will guide you through the truth!",
                    tc:
                      "喂，我是內地官員，依家懷疑你犯咗法……警務處處長蕭Sir 同反詐騙協調中心助你洞悉真相！",
                    sc:
                      "喂，我是内地官员，现在怀疑你犯了法……警务处处长萧Sir 和反诈骗协调中心助你洞悉真相！",
                  },
                  category: {
                    en: "Telephone Deception",
                    tc: "假冒內地官員電話騙案",
                    sc: "假冒内地官员电话骗案",
                  },
                  title: {
                    en: "ADCC Action!",
                    tc: "反詐時速",
                    sc: "反诈时速",
                  },
                  videoId: "r8TJjcIXWYc",
                  image: require("@/assets/images/stayAlert/adults/fraud_1.png"),
                },
              ],
            },
          ];
        }
        case 2: {
          return [
            {
              info: [
                {
                  title: "stayAlert.physical_harm",
                  image: this.getImageUrl("physical_harm.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en:
                      "Everyone is stressed out. But why would you vent it out on the children? How about asking for help?",
                    tc:
                      "你有壓力，我有壓力，做咩要發洩喺小朋友身上呀？不如試下搵人幫手啦！",
                    sc:
                      "你有压力，我有压力，为什么要发泄在小朋友身上呢？要不试试找人帮忙吧！",
                  },
                  category: {
                    en: "Child Protection",
                    tc: "保護兒童",
                    sc: "保护儿童",
                  },
                  title: {
                    en:
                      "Social Workers By Your Side Through Every Adversity – Early Identification Better Child Protection",
                    tc: "「北斗同行」 - 及早識別 保護兒童",
                    sc: "「北斗同行」 - 及早识别 保护儿童",
                  },
                  videoId: "RQys6PNZXl8",
                  image: require("@/assets/images/stayAlert/adults/physical_harm_1.png"),
                },
              ],
            },
          ];
        }
        case 3: {
          return [
            {
              info: [
                {
                  title: "stayAlert.sexual_violence",
                  image: this.getImageUrl("sexual_violence.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「非禮12歲女童」案件",
                    tc: "拘捕。「非禮12歲女童」案件",
                    sc: "拘捕。「非礼12岁女童」案件",
                  },
                  videoId: "S-HiWq9VFjY",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「向兒童作猥褻行為及製作兒童色情物品」案件",
                    tc: "拘捕。「向兒童作猥褻行為及製作兒童色情物品」案件",
                    sc: "拘捕。「向儿童作猥亵行为及制作儿童色情物品」案件",
                  },
                  videoId: "71HxDQr4cvk",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「施用藥物以獲得非法性行為」案件",
                    tc: "拘捕。「施用藥物以獲得非法性行為」案件",
                    sc: "拘捕。「施用药物以获得非法性行为」案件",
                  },
                  videoId: "n1s2PZkk0wE",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「梯間非禮8歲女童及拍照」案件",
                    tc: "拘捕。「梯間非禮8歲女童及拍照」案件",
                    sc: "拘捕。「梯间非礼8岁女童及拍照」案件",
                  },
                  videoId: "SldHnARBT0Q",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「稱占卜轉運非禮女學生」案件",
                    tc: "拘捕。「稱占卜轉運非禮女學生」案件",
                    sc: "拘捕。「称占卜转运非礼女学生」案件",
                  },
                  videoId: "x54UfDjam0k",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「非禮14歲女學生」案件",
                    tc: "拘捕。「非禮14歲女學生」案件",
                    sc: "拘捕。「非礼14岁女学生」案件",
                  },
                  videoId: "OVlvJf0Ts",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「非法禁錮及管有兒童色情物品」案件",
                    tc: "拘捕。「非法禁錮及管有兒童色情物品」案件",
                    sc: "拘捕。「非法禁锢及管有儿童色情物品」案件",
                  },
                  videoId: "nObtujDhSPs",
                  image: require("@/assets/images/img_icon.png"),
                },
              ],
            },
            {
              info: [
                {
                  title: "helpingHand.physical_abuse",
                  image: this.getImageUrl("physical_harm.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「母親身體虐待嬰兒」案件",
                    tc: "拘捕。「母親身體虐待嬰兒」案件",
                    sc: "拘捕。「母亲身体虐待婴儿」案件",
                  },
                  videoId: "se_-fk1Y_1Q",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "謀殺及殘酷對待兒童罪判刑",
                    tc: "謀殺及殘酷對待兒童罪判刑",
                    sc: "谋杀及残酷对待儿童罪判刑",
                  },
                  videoId: "DW7oTWu03m8",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「童黨欺凌」案件",
                    tc: "拘捕。「童黨欺凌」案件",
                    sc: "拘捕。「童党欺凌」案件",
                  },
                  videoId: "FTgkKAF1B1s",
                  image: require("@/assets/images/img_icon.png"),
                },
              ],
            },
            {
              info: [
                {
                  title: "stayAlert.cybercrime",
                  image: this.getImageUrl("cybercrime.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「利用網絡遊戲武器誘使女童拍裸照」案件",
                    tc: "拘捕。「利用網絡遊戲武器誘使女童拍裸照」案件",
                    sc: "拘捕。「利用网络游戏武器诱使女童拍裸照」案件",
                  },
                  videoId: "hmxyJhqb_Qk",
                  image: require("@/assets/images/img_icon.png"),
                },
              ],
            },
            {
              info: [
                {
                  title: "legally.damage",
                  image: this.getImageUrl("cruelty_child.png"),
                  content: "common.tbc",
                },
              ],
              videoList: [
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「刑毁連鎖教育中心」案件",
                    tc: "拘捕。「刑毁連鎖教育中心」案件",
                    sc: "拘捕。「刑毁连锁教育中心」案件",
                  },
                  videoId: "4CzihE4qqNM",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「13及15歲非法收債、刑事毁壞及刑事恐嚇」案件",
                    tc: "拘捕。「13及15歲非法收債、刑事毁壞及刑事恐嚇」案件",
                    sc: "拘捕。「13及15岁非法收债、刑事毁坏及刑事恐吓」案件",
                  },
                  videoId: "zGjhD63gzVQ",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「非法放債及收數」案件",
                    tc: "拘捕。「非法放債及收數」案件",
                    sc: "拘捕。「非法放债及收数」案件",
                  },
                  videoId: "1FhycwhrftE",
                  image: require("@/assets/images/img_icon.png"),
                },
                {
                  desc: {
                    en: "",
                    tc: "",
                    sc: "",
                  },
                  title: {
                    en: "拘捕。「高利貸放債集團」案件",
                    tc: "拘捕。「高利貸放債集團」案件",
                    sc: "拘捕。「高利贷放债集团」案件",
                  },
                  videoId: "knWGBnH3Rf4",
                  image: require("@/assets/images/img_icon.png"),
                },
              ],
            },
          ];
        }
        default:
          return {
            info: [],
            videoList: [],
          };
      }
    },
  },
};
</script>
