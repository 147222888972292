<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  mixins: [Bar],
  props: ["chartData", "options"],
  data() {
    return {
      dataList: null,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale: function (newLocale, oldLocale) {
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    if (this.$refs.canvas != undefined) {
      this.renderChart(this.chartData, this.options);
    }
  },

};
</script>