<template>
  <div id="cc" :class="isMobile ? 'mobile' : ''">
    <v-dialog
      v-model="dialog"
      :width="isMobile ? '100%' : '80%'"
      :fullscreen="isMobile"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="hotline-dialog-btn"
          class="d-none"
          dark
          v-bind="attrs"
          v-on="on"
          style="background-color: transparent"
          width="110"
        >
          <v-icon color="white" class="pr-1">mdi-phone-in-talk</v-icon>
          <span class="text-subtitle-1 white--text">{{
            $t("common.letstalk")
          }}</span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("common.letstalk") }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false" aria-label="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-for="item in data" :key="item.title.en">
            <v-subheader h4 class="pl-0">
              {{ item.title[$i18n.locale] }}
            </v-subheader>
            <div class="d-flex flex-wrap" style="padding-right: 0px;">
              <div
                :class="isMobile ? 'contact-container-mb' : 'contact-container'"
                v-for="d in item.child"
                :key="d.title.en"
                @click="phoneCall(d.hotline)"
              >
                <v-card outlined elevation="1" class="hotline-card">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-h6 mb-1"
                        style="font-size: 18px !important; white-space: normal;"
                      >
                        {{ d.title[$i18n.locale] }}
                      </v-list-item-title>
                      <div class="d-flex align-center">
                        <v-icon class="mr-2">mdi-phone</v-icon>
                        <v-list-item-subtitle>
                          {{ d.hotline }}
                        </v-list-item-subtitle>
                        <v-icon v-if="d.male == true" color="blue"
                          >mdi-human-male</v-icon
                        >
                        <v-icon v-if="d.female == true" color="red"
                          >mdi-human-female</v-icon
                        >
                        <v-icon v-if="d.hr24 == true" color="green"
                          >mdi-hours-24</v-icon
                        >
                        <div v-if="d.ethnic == true" class="itemText">
                          <!-- <div v-if="$i18n.locale == 'en'" style="width: 40%">
                          {{ ethnic[$i18n.locale] }}
                        </div>
                        <div v-else>
                          {{ ethnic[$i18n.locale] }}
                        </div> -->
                        {{ ethnic[$i18n.locale] }}
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.itemText {
    border: 1px solid;
    padding: 4px 8px;
    border-radius: 20px;
    flex: none;
    // background-color: #ffce74;
    color: brown;
}
.contact-container {
  width: 50%;
  padding: 0 12px 12px 0;
}
.contact-container-mb {
  width: 100%;
  padding: 0 12px 12px 0;
}
.v-sheet.v-card {
  border-color: #042851;
  border-width: 1px 1px 1px 7px;
  box-shadow: none !important;
}
.hotline-card {
  cursor: pointer;
}
.v-subheader {
  font-weight: bold !important;
  color: #042851 !important;
  font-size: 18px !important;
}
</style>

<script>
// @ is an alias to /src

import { $ } from "@daybrush/utils";
export default {
  components: {
    //
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {},
  methods: {
    //
    phoneCall(tel) {
      window.location.href = "tel:" + tel;
    },
  },
  data: () => ({
    dialog: false,
    ethnic: {
          en: "Ethnic Minority",
          tc: "少數族裔",
          sc: "少数族裔",
        },
    data: [
      {
        title: {
          en: "Emergency/ Shelter Accommodation Service/ Helpline Service",
          tc: "緊急 /庇護住宿服務 /求助熱線服務",
          sc: "紧急 /庇护住宿服务 /求助热线服务",
        },
        child: [
          {
            title: {
              en: "Tung Wah-CEASE Crisis Centre (Domestic/Sexual Violence)",
              tc: "東華三院 -芷若園 (家庭暴力及性暴力)",
              sc: "东华三院 -芷若园 (家庭暴力及性暴力)",
            },
            hotline: "18281",
            female: true,
            male: true,
            hr24: true,
          },
          {
            title: {
              en: "Caritas – Family Crisis Support Centre",
              tc: "香港明愛 -向晴軒",
              sc: "香港明爱 -向晴轩",
            },
            hotline: "18288",
            female: true,
            male: true,
            hr24: true,
          },
          {
            title: {
              en: "Christian Family Service Centre – Serene Court",
              tc: "基督教家庭服務中心 -恬寧居",
              sc: "基督教家庭服务中心 -恬宁居",
            },
            hotline: "23813311",
            female: true,
            male: false,
            hr24: true,
          },
          {
            title: {
              en: "Harmony House",
              tc: "和諧之家",
              sc: "和谐之家",
            },
            hotline: "25220434",
            female: true,
            male: false,
            hr24: true,
          },
          {
            title: {
              en: "Po Leung Kuk –Sunrise Court / Wai On Home / Dawn Court",
              tc: "保良局 -昕妍居 /維安中心 /曉妍居",
              sc: "保良局 -昕妍居 /维安中心 /晓妍居",
            },
            hotline: "81001155",
            female: true,
            male: false,
            hr24: true,
          },
          {
            title: {
              en: "The Zubin Foundation - Call Mira Helpline Service",
              tc: "小彬紀念基金會 - Call Mira 支援熱線",
              sc: "小彬纪念基金会 - Call Mira 支援热线",
            },
            hotline: "96823100",
            female: true,
            male: false,
            ethnic: true,
            hr24: false,
          },
        ],
      },
      {
        title: {
          en: "Emotional Support Service",
          tc: "情緒支援及求助熱線服務",
          sc: "情緒支援及求助熱線服務",
        },
        child: [
          {
            title: {
              en: "Harmony House Women Hotline",
              tc: "和諧之家 -婦女求助熱線",
              sc: "情绪支援及求助热线服务",
            },
            hotline: "25220434",
            female: true,
            male: false,
            hr24: true,
          },
          {
            title: {
              en: "Harmony House Men Hotline",
              tc: "和諧之家 -MAN男士熱線",
              sc: "和谐之家 -MAN男士热线",
            },
            hotline: "22951386",
            female: false,
            male: true,
            hr24: false,
          },
          {
            title: {
              en: "Harmony House Children Hotline",
              tc: "和諧之家 –小白兒童熱線",
              sc: "和谐之家 –小白儿童热线",
            },
            hotline: "27518822",
            female: true,
            male: true,
            hr24: false,
          },
          {
            title: {
              en: "HK Federation of Women’s Centres Helpline",
              tc: "香港婦女中心協會求助熱線",
              sc: "香港妇女中心协会求助热线",
            },
            hotline: "23866255",
            female: true,
            male: false,
            hr24: false,
          },
          {
            title: {
              en:
                "Po Leung Kuk–Family and Intimate Partner Relationship Hotline for Men",
              tc: "保良局 -家庭及伴侶關係男士專線",
              sc: "保良局 -家庭及伴侣关系男士专线",
            },
            hotline: "28901930",
            female: false,
            male: true,
            hr24: false,
          },
          {
            title: {
              en: "End Child Sexual Abuse Foundation – Hug line",
              tc: "護苗基金 – 護苗線",
              sc: "护苗基金 – 护苗线",
            },
            hotline: "28899933",
            female: true,
            male: false,
            hr24: false,
          },
          {
            title: {
              en: "Mother’s Choice - Support Line",
              tc: "母親的抉擇 - 支援熱線",
              sc: "母亲的抉择 - 支援热线",
            },
            hotline: "23135678",
            female: true,
            male: false,
            hr24: false,
          },
          {
            title: {
              en: "Mother’s Choice Whatsapp Message Service",
              tc: "母親的抉擇Whatsapp 短訊",
              sc: "母亲的抉择Whatsapp 短讯",
            },
            hotline: "56335678",
            female: true,
            male: false,
            hr24: false,
          },
          {
            title: {
              en: "Against child Abuse – Parent-child Support Line",
              tc: "防止虐待兒童會 - 求助及親子支援熱線",
              sc: "防止虐待儿童会 - 求助及亲子支援热线",
            },
            hotline: "27551122",
            female: true,
            male: true,
            hr24: false,
          },
          {
            title: {
              en: "The Samaritan Befrienders Hong Kong",
              tc: "香港撒瑪利亞防止自殺會",
              sc: "香港撒玛利亚防止自杀会",
            },
            hotline: "23892222",
            female: true,
            male: true,
            hr24: true,
          },
          {
            title: {
              en: "Ethnic Minority Well-being Centre",
              tc: "少數族裔精神健康支援服務中心",
              sc: "少数族裔精神健康支援服务中心",
            },
            hotline: "96823100",
            female: true,
            male: true,
            ethnic: true,
            hr24: false,
          },
        ],
      },
      {
        title: {
          en: "Sexual Violence Crisis Intervention Hotline",
          tc: "性暴力危機介入熱線",
          sc: "性暴力危机介入热线",
        },
        child: [
          {
            title: {
              en: "Rainlily Hotline",
              tc: "風雨蘭求助熱線",
              sc: "风雨兰求助热线",
            },
            hotline: "23755322",
            female: true,
            male: false,
            hr24: false,
          },
          {
            title: {
              en: "SafeChat WhatsApp Support Line",
              tc: "SafeChat WhatsApp 支援",
              sc: "SafeChat WhatsApp 帮助",
            },
            hotline: "23755322",
            female: true,
            male: true,
            hr24: false,
          },
        ],
      },
      {
        title: {
          en: "Integrated Social Service",
          tc: "綜合社會服務",
          sc: "综合社会服务",
        },
        child: [
          {
            title: {
              en: "Social Welfare Department (Hotline)",
              tc: "社會福利署熱線",
              sc: "社会福利署热线",
            },
            hotline: "23432255",
            female: true,
            male: true,
            hr24: true,
          },
          {
            title: {
              en: "Save the Children Hong Kong",
              tc: "香港救助兒童會",
              sc: "香港救助儿童会",
            },
            hotline: "31608686",
            female: true,
            male: true,
            hr24: false,
          },
        ],
      },
    ],
  }),
};
</script>
