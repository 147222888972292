<template>
  <v-img
    class="align-center justify-center"
    :src="data.bg"
    cover
    style="flex: 1"
    :height="isMobilePhone ? '80px' : '120px'"
    :style="{ display: isShow ? 'flex' : 'none' }"
  >
    <v-container
      class="align-center justify-space-between"
      style="flex: 1"
      :style="{ display: isShow ? 'flex' : 'none' }"
    >
      <span
        :style="{ color: data.txColor }"
        id="header-tx"
        :class="isMobilePhone ? 'header-tx-xs' : ''"
        >{{ $t(data.tx) }}</span
      >
      <div>
        <v-img
          :src="getContentHeaderIcon()"
          :width="isMobilePhone ? 60 : 80"
        />
      </div>
    </v-container>
  </v-img>
</template>

<style lang="scss" scoped>
#header-tx {
  color: white;
  font-size: 50px;
}
.header-tx-xs {
  font-size: 35px !important;
}
</style>

<script>
// @ is an alias to /src
import config from "../../../config";
import axios from "axios";

export default {
  name: "ContentHeader",
  props: [],
  created() {
    //
    this.fetchData();
    console.log(this.$route);

    this.data = this.setHeaderData();
    console.log("this.data", this.data);
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({
    //
    data: {},
    isShow: false,
    headerImages: {},
  }),
  methods: {
    fetchData() {
      axios
        .get(config.wordpressUrl + `/configs?order=asc&per_page=100`)
        .then((response) => {
          let data = response.data[0];

          if (data != null) {
            console.log("data.teens_header_image", data);
            this.headerImages = {
              kids: this.getImageFromResp(
                { acf: data.acf.kids_header_image },
                "image"
              ),
              teens: this.getImageFromResp(
                { acf: data.acf.teens_header_image },
                "image"
              ),
              adults: this.getImageFromResp(
                { acf: data.acf.adults_header_image },
                "image"
              ),
            };
          }

          console.log("headerImages", this.headerImages);
        });
    },
    setHeaderData() {
      const name = this.$route.name.toString().toLowerCase();
      const fullPath = this.$route.fullPath.toString().toLowerCase();

      if (
        ![
          "home",
          "helpinghand",
          "aboutus",
          "kidshelpinghand",
          "teenshelpinghand",
          "adulthelpinghand",
          "activitydetails",
          "allactivities",
          "videogallery"
        ].includes(name)
      ) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }

      if (name.includes("kids") || fullPath.includes("kids")) {
        return {
          bg: require("@/assets/images/kids_bg.png"),
          tx: "common.kids",
          txColor: "#a34100",
        };
      } else if (name.includes("teens") || fullPath.includes("teens")) {
        return {
          bg: require("@/assets/images/teens_bg.png"),
          tx: "common.teens",
          txColor: "#375c00",
        };
      } else if (name.includes("adult") || fullPath.includes("adult")) {
        return {
          bg: require("@/assets/images/parents_bg.png"),
          tx: "common.adults",
          txColor: "#004fa3",
        };
      }

      return {
        bg: require("@/assets/images/kids_bg.png"),
        tx: "common.kids",
        txColor: "#a34100",
      };
    },
    getBackgroundImage() {
      //   require("@/assets/images/teens_bg.png");
      //   require("@/assets/images/kids_bg.png");
      //   require("@/assets/images/adult.png");
    },
    getContentHeaderIcon() {
      // headerImages;
      const name = this.$route.name.toString().toLowerCase();
      const fullPath = this.$route.fullPath.toString().toLowerCase();

      if (name.includes("kids") || fullPath.includes("kids")) {
        return this.headerImages.kids[this.$i18n.locale];
      } else if (name.includes("teens") || fullPath.includes("teens")) {
        return this.headerImages.teens[this.$i18n.locale];
      } else if (name.includes("adult") || fullPath.includes("adult")) {
        return this.headerImages.adults[this.$i18n.locale];
      }

      return require("@/assets/images/TT-1.png");
    },
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      this.data = this.setHeaderData();
    },
  },
};
</script>
