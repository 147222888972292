<template>
  <div style="padding: 0">
    <div v-for="(post, i) in postList" :key="i">
      <ImageAndDescWithShowMoreBtnPage
        v-if="post.template == 'Image & description with show more button'"
        :item="post"
      />
      <YoutubeWithDesc
        v-if="post.template == 'Video with description'"
        :item="post"
      />
      <HTML v-if="post.template == 'HTML'" :item="post" />

      <ChartPage v-if="post.template == 'Chart'" :item="post" />

      <LawPage v-if="post.template == 'Law'" :item="post" />

      <PDFListPage v-if="post.template == 'PDF List'" :item="post" />

    </div>
  </div>
</template>

<script>
import ImageAndDescWithShowMoreBtnPage from "@/components/templates/ImageAndDescWithShowMoreBtnPage";
import YoutubeWithDesc from "@/components/templates/YoutubeWithDesc";
import HTML from "@/components/templates/HTML";
import ChartPage from "@/components/templates/ChartPage";
import LawPage from "@/components/templates/LawPage";
import PDFListPage from "@/components/templates/PDFListPage.vue";
import config from "../../../config";
import axios from "axios";
import _ from "lodash";

// @ is an alias to /src
export default {
  name: "Category",
  components: {
    ImageAndDescWithShowMoreBtnPage,
    YoutubeWithDesc,
    HTML,
    ChartPage,
    LawPage,
    PDFListPage,
  },
  props: {
    contentUrlPath: String,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const path = this.$route.fullPath;
      let cat_id = path
        .substr(path.indexOf("=") + 1, path.length)
        .split("&")[0];

        console.log("${this.contentUrlPath", this.contentUrlPath)
      axios
        .get(
          config.wordpressUrl + `/${this.contentUrlPath}?order=asc&per_page=100`
        )
        .then((response) => {
          let data = response.data;
          console.log("resp", data);
          let postList = [];
          data.map((item) => {
            let category = item.acf.category;

            if (category != null && category.ID == cat_id) {
              postList.push({
                template: item.acf.type,
                desc: this.getFieldFromResp(item, "description"),
                more: this.getFieldFromResp(item, "show_more_desctiption"),
                image: this.getImageFromResp(item),
                videoId: this.getFieldFromResp(item, "video_link"),
                html: this.getFieldFromResp(item, "html"),
                chart_options: item.acf.chart_options,
                law: item.acf.law,
                order: parseFloat(item.acf.order),
                pdf: item.acf?.pdf_list?.map(e => ({
                  filePath: e.pdf,
                  title: this.getFieldFromResp({acf: e}, "title"),
                  image: e.image,
                }))
              });
            }
          });
          // sort by order : highest on top
          postList = postList.sort((a, b) => {
            if (a.order > b.order) {
              return -1;
            }
            if (a.order < b.order) {
              return 1;
            }
            return 0;
          });

          console.log("postList", postList)

          this.postList = postList;
        });
    },
    getLocale(item) {
      if (this.$i18n.locale == "en") {
        return item.en;
      } else if (this.$i18n.locale == "tc") {
        return item.tc;
      } else {
        return item.sc;
      }
    },
  },
  data() {
    return {
      selectedItem: null,
      postList: [],
    };
  },
};
</script>
