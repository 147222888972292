export default [
  // {
  //   id: 'chatbot',
  //   name: 'Chatbot',
  //   imageUrl: require('@/assets/web_icon.png')
  // },
  {
    id: 'ted',
    name: 'Ted',
    name_tc: 'Ted',
    imageUrl: require('@/assets/ted.png')
  },
  {
    id: 'alessia',
    name: 'Alessia',
    name_tc: 'Alessia',
    imageUrl: require('@/assets/alessia.png')
  },
  {
    id: 'leo',
    name: 'Leo',
    name_tc: 'Leo',
    imageUrl: require('@/assets/leo.png')
  },
  {
    id: 'koko',
    name: 'Koko',
    name_tc: 'Koko',
    imageUrl: require('@/assets/koko.png')
  },
]
