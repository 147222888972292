export const witness = {
  title: {
    en: "Child as a Witness…",
    tc: "兒童作為證人…",
    sc: "common.tbc"
  },
  subTitle: {
    en: "It’s too much burden for a child!",
    tc: "對兒童來說是一個重担",
    sc: "common.tbc"
  },
  img: "witness-teens.png",
  aspectRatio: 364 / 121,
  onCardClick: true,
  children: [
    {
      contentHeader: {
        en: "Child as a Witness…",
        tc: "兒童作為證人…",
        sc: "common.tbc"
      },
      img: "witness-teens-1.png",
      aspectRatio: 367/274,
      // content: "Seek help\nYou are not alone",
      showTips: true,
      points: [
        {
          en: "Listen to him/ her. Your support is very important",
          tc: "你的聆聽是最大的鼓舞",
          sc: "common.tbc"
        },
        {
          en: "Encourage him/ her to seek help",
          tc: "鼓勵他們求助",
          sc: "common.tbc"
        },
        {
          en: "Give him/ her reassurance",
          tc: "給予安慰及信任",
          sc: "common.tbc"
        },
        {
          en: "If the problem cannot be solved, seek help!",
          tc: "若果不能解決問題，盡快求助!",
          sc: "common.tbc"
        },
      ],
      children: [
        {
          img: "witness-teens-2.png",
          aspectRatio: 366/200,
          content: {
            en: "Briefly ask the victim about the incident.\nEmpower the child to speak out.",
            tc: "簡單問一下發生甚麼事，鼓勵孩子勇敢說出來",
            sc: "common.tbc"
          },
          children: [
            {
              img: "witness-teens-3.png",
              aspectRatio: 366/200,
              content: {
                en: "After your assessment, report the case if necessary.\nWe are here to help.",
                tc: "當你了解情況後，有需要請向警方求助",
                sc: "common.tbc"
              },
            }
          ]
        }
      ]
    }
  ]
}