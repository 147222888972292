import { render, staticRenderFns } from "./PhotoFrame.vue?vue&type=template&id=970bcefc&scoped=true&"
import script from "./PhotoFrame.vue?vue&type=script&lang=js&"
export * from "./PhotoFrame.vue?vue&type=script&lang=js&"
import style0 from "./PhotoFrame.vue?vue&type=style&index=0&id=970bcefc&prod&lang=scss&scoped=true&"
import style1 from "./PhotoFrame.vue?vue&type=style&index=1&id=970bcefc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "970bcefc",
  null
  
)

export default component.exports