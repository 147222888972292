<template>
  <div style="position: relative">
    <div
      :style="{
        width: isMobile ? '100vw' : '300vw',
        left: isMobile ? '-12px' : '-125vw',
      }"
      style="position: absolute; background-color: #91e7f6; height: 100%"
    />
    <v-row class="">
      <v-col class="d-flex" style="z-index: 9">
        <p
          :class="isMobile ? 'text-h6' : 'text-h5'"
          class="text-left mb-0 c-font-family b-font"
          :style="{
            color: color != undefined ? color : '#175082',
            'font-family': '',
          }"
        >
          {{ $t("home.activities") }}
        </p>
      </v-col>

      <v-col class="d-flex justify-end align-center">
        <v-btn
          text
          style="color: #757575; font-weight: bold"
          class="btn"
          @click="$router.push({ name: 'AllActivities' })"
        >
          <p
            :class="isMobile ? 'text-subtitle-2' : 'text-h6'"
            style="color: #175082"
            class="mb-0 c-font-family b-font"
          >
            {{ $t("common.more") }} >>
          </p>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        :cols="isMobile ? 12 : 4"
        v-for="item in items?.slice(0, 3)"
        :key="item.id"
        class="d-flex flex-column mb-4"
      >
        <v-card
          :tile="true"
          class="text-left flex d-flex flex-column"
          style="border-radius: 0 !important; height: 100%"
        >
          <div style="height: 200px">
            <v-img
              :src="getImage(item.img)"
              class="card-image"
              height="200px"
              cover
            ></v-img>
          </div>
          <v-card-title class="text-wrap text-center h-title px-8">
            <div
              class="h-title"
              style="width: 100%"
              v-for="title in ($i18n.locale == 'en'
                ? item.title.en
                : $i18n.locale == 'tc'
                ? item.title.tc
                : item.title.sc
              ).split('<br>')"
            >
              {{ title }}
            </div>
          </v-card-title>
          <v-card-text class="h-body text-wrap limit-line-3">
            {{
              $i18n.locale == "en"
                ? item.content.en
                : $i18n.locale == "tc"
                ? item.content.tc
                : item.content.sc
            }}
          </v-card-text>
          <v-spacer />
          <v-card-actions>
            <v-spacer />
            <v-btn
              rounded
              small
              outlined
              color="black"
              dark
              class="c-font-family"
              style="border-width: 2px"
              @click="
                $router.push({
                  name:
                    item.title.en?.includes(
                      'Review for Child Protection Campaign 2021 and 2022'
                    ) || item?.id == 501
                      ? 'VideoGallery'
                      : 'ActivityDetails',
                  query: { id: item.id },
                })
              "
            >
              <div class="c-font-family">{{ $t("common.more") }} >></div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.v-card__title {
  justify-content: center !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn {
  text-transform: none !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-sheet.v-card {
  border-radius: 10px !important;
}

.limit-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.limit-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.limit-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
<script>
// @ is an alias to /src
import SessionTitle from "../atoms/SessionTitle.vue";
import config from "/config";
import axios from "axios";
export default {
  name: "Activity",
  props: ["isShowMore"],
  components: {
    SessionTitle,
  },
  created() {
    this.fetchData();
  },
  methods: {
    getImage(item) {
      if (this.$i18n.locale == "en") {
        return item.en;
      } else if (this.$i18n.locale == "tc") {
        return item.tc;
      } else {
        return item.sc;
      }
    },
    fetchData() {
      axios
        .get(config.wordpressUrl + "/activity_and_event?order=asc&per_page=100")
        .then((response) => {
          const id = this.$route.query.id;
          console.log("activity_and_event", response.data);

          let activity = [];

          const data = response.data;
          console.log("raw activity", data);
          if (data) {
            data.map((item, index) => {
              activity.push({
                title: this.getFieldFromResp(item, "title"),
                content: this.getFieldFromResp(item, "description"),
                img: this.getImageFromResp(item, "image"),
                html: this.getFieldFromResp(item, "html"),
                sequence: parseInt(item.acf?.sequence ?? "0"),
                id: index,
              });
            });

            console.log("activity", activity);
            console.log(JSON.stringify(activity[1].title.tc.split("<br>")));
            const index = activity.findIndex(
              (item) =>
                item?.title?.en?.includes(
                  "Review for Child Protection Campaign 2021 and 2022"
                ) || item?.id == 501
            );
            if (index !== -1) {
              const item = activity.splice(index, 1)[0];
              activity.push(item);
            }

            activity = activity?.sort((a, b) => a.sequence - b.sequence);

            this.items = activity;
          }
        });
    },
  },

  data: () => ({
    items: [],
    items1: [
      {
        id: 1,
        title: "Card 1 Title",
        text: "",
        image: "https://via.placeholder.com/300x200",
      },
      {
        id: 2,
        title: "Card 2 Title",
        text: "Card 2 text goes here. This is a longer text that will be truncated after three lines.This is a longer text that will be truncated after three linesThis is a longer text that will be truncated after three lines",
        image: "https://via.placeholder.com/300x200",
      },
      {
        id: 3,
        title: "Card 3 Title",
        text: "Card 3 text goes here. This is a longer text that will be truncated after three lines.",
        image: "https://via.placeholder.com/300x200",
      },
    ],
  }),
};
</script>
