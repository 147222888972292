export const cybercrime = {
  title: {
    en: "Cybercrime",
    tc: "網絡罪案",
    sc: "网络罪案"
  },
  img: "hh2.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Cybercrime",
        tc: "網絡罪案",
        sc: "网络罪案"
      },
      title: {
        en: "Cybercrime",
        tc: "網絡罪案",
        sc: "网络罪案"
      },
      img: "witness-kids-4.png",
      aspectRatio: 336 / 271,
      showTips: true,
      points: [
        {
          en: "Teach your children not to download suspicious or inappropriate software/ applications.",
          tc: "教導子女不要下載可疑或不良軟件／應用程式。",
          sc: "教导子女不要下载可疑或不良软件／应用程序。"
        },
        {
          en: "Observe more about the browsing history of children over the Internet",
          tc: "多留意兒童上網的瀏覽紀錄。",
          sc: "多留意儿童上网的浏览纪录。"
        },
        {
          en: "Monitor your children on their spending over the Internet",
          tc: "留意子女的網上消費。",
          sc: "留意子女的网上消费。"
        },
        {
          en: "Teach your children about staying safe in cyber world and help them enhance the security of their accounts.",
          tc: "教導子女在網絡世界注意安全，並幫助他們增強帳戶的保安。",
          sc: "教导子女在网络世界注意安全，并帮助他们增强账户的保安。"
        },
        {
          en: "Teach your children to set strong passwords for accounts/ applications and change them regularly",
          tc: "教導子女登入網上帳戶／應用程式時使用高強度密碼，並定期更改。",
          sc: "教导子女登入网上账户／应用程式时使用高强度密码，并定期更改。"
        },
        {
          en: "If your children become a victim of cybercrime, report the case to police immediately.",
          tc: "如果不幸成為受害人，請立即向警方舉報。",
          sc: "如果不幸成为受害人，请立即向警方举报。"
        },
      ],
    }
  ],
};
