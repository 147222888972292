export default {
  custom: {
    header: {
      bg: 'rgba(5, 48, 95, 0.95)',
      text: '#05305ff2'
    },
    launcher: {
      bg: 'lightgrey'
    },
    messageList: {
      // bg: '#e5ddd5'
      bg: 'transparent',
      bgImage: require('@/assets/chatroom_bkg.png'),
    },
    sentMessage: {
      bg: '#0e73c9',
      text: '#fffffff2'
    },
    receivedMessage: {
      bg: '#fffffff2',
      text: '#51585c'
    },
    systemMessage: {
      bg: '#e1f3fb',
      text: '#51585c'
    },
    userInput: {
      bg: '#f0f0f0',
      text: '#51585c'
    },
    userList: {
      bg: '#fff',
      text: '#212121'
    },
    voiceBtn: {
      text: '#042851',
    },
    buttons: {
      bg: '#0e73c9',
    }
  }
}

