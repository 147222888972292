<template>
    <!-- <v-app-bar-nav-icon @click="toggleDrawer">
      <v-icon :x-large="!isMobile"> mdi-menu </v-icon>
    </v-app-bar-nav-icon> -->
    <v-navigation-drawer
      v-model="showDrawer"
      absolute
      fixed
      app
      temporary
      :style="{
        'background-color': '#042851',
        width: isMobile ? '70vw' : '30vw',
      }"
    >
      <v-img
        class="pointer"
        @click="$router.push({ name: 'Home' }).catch((err) => {})"
        :src="require('@/assets/images/drawer_icon.png')"
        v-if="isMobile"
      >
      </v-img>

      <a
        style="color: white"
        @click="$router.push({ name: 'Home' }).catch((err) => {})"
        v-else
      >
        <v-container>
          <v-row>
            <v-col cols="2">
              <v-img
                class="pointer"
                contain
                :src="require('@/assets/images/web_icon.png')"
              >
              </v-img>
            </v-col>

            <!-- <v-col>
              <h1>Let's T.A.L.K.</h1>
            </v-col>
          </v-row>

          <v-row> -->
            <v-col align-self="center">
              <h2 style="line-height: 30px">
                保護兒童 愛中成長
              </h2>
              <h6>
                Protect Our Next Generation With Love
              </h6>
            </v-col>
          </v-row>
        </v-container>
      </a>

      <v-divider></v-divider>

      <!-- <v-treeview
        v-model="tree"
        :items="items"
        item-key="title"
        open-on-click
        open-all
      >
        <template v-slot:label="{ item }">
          <div
            class="v-treeview-node__label"
            v-bind:style="{ color: item.color }"
            style="text-align: left; padding-left: 12px; padding-top: 3px"
            @click="onItemClick(item)"
          >
            {{ $t(item.title) }}
          </div>
        </template>
      </v-treeview> -->

      <v-list>
        <template v-for="item in items">
          <v-list-group
                  :value="true"
                  no-action
                >
                <template v-slot:activator>
                    <v-list-item-content >
                      <v-list-item-title v-bind:style="{ color: item.color }">{{ $t(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(subItem, i) in item.children"
                  :key="i"
                  link
                >
                  <v-list-item-title v-text="$t(subItem.title)" @click="onItemClick(subItem)" v-bind:style="{ color: item.color }"></v-list-item-title>
                </v-list-item>
          </v-list-group>
        </template>
      </v-list>
      <v-list-item no-action >
        <v-list-item-title @click="onItemClick(contactItem)">{{ $t(contactItem.title) }}</v-list-item-title>
      </v-list-item>


    </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
</style>

<script>
// @ is an alias to /src
import config from "../../../config";
import axios from "axios";

const additionalMenus = [
  {
    type: "kids",
    items: [
      {
        title: "additional cat 1",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#FF8940",
        route: "KidsHome",
        id: 3,
      },
      {
        title: "additional cat 2",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#FF8940",
        route: "KidsHome",
        id: 4,
      },
    ],
  },
  {
    type: "teens",
    items: [
      {
        title: "additional cat 1",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#9AF2D0",
        route: "TeensHome",
        id: 3,
      },
      {
        title: "additional cat 2",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#9AF2D0",
        route: "TeensHome",
        id: 4,
      },
    ],
  },
  {
    type: "adults",
    items: [
      {
        title: "additional cat 1",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#7CE1FF",
        route: "AdultHome",
        id: 6,
      },
      {
        title: "additional cat 2",
        image: require("@/assets/images/parents/learn_more.png"),
        icon: require("@/assets/images/parents/learn_more_icon.png"),
        content: "Learn More...",
        color: "#7CE1FF",
        route: "AdultHome",
        id: 7,
      },
    ],
  },
];

export default {
  name: "NavigationDrawer",
  components: {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchAdditionalMenus((catIndex, ageIndex) => {
        if (catIndex != null && ageIndex != null) {
          let children = this.items[ageIndex].children;

          let additionalMenuItem = additionalMenus[ageIndex].items[catIndex];
          if (children.indexOf(additionalMenuItem) == -1) {
            children.push(additionalMenuItem);
          }
          this.items[ageIndex].children = children;
        }
      });
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    onItemClick(item) {
      this.$router
        .replace({
          name: item.route,
          params: { id: item.id },
          query: {
            t: item.id,
          },
        })
        .catch((err) => {
          this.showDrawer = !this.showDrawer;
        });
    },
  },
  data: () => ({
    showDrawer: false,
    links: ["Home", "About Us", "Team", "Services", "Blog", "Contact Us"],
    selectedItem: 0,
    tree: [],
    items: [
      {
        title: "common.kids",
        subtitle: "common.kidsAge",
        color: "#FF8940",
        children: [
          {
            title: "kids.watchVideos",
            image: require("@/assets/images/kids/smart_guard.png"),
            icon: require("@/assets/images/kids/smart_guard_icon.png"),
            content: "Watch Films...",
            color: "#FF8940",
            route: "KidsHome",
            id: 0,
          },
          {
            title: "kids.letsPlay",
            image: require("@/assets/images/kids/fun_corner.png"),
            icon: require("@/assets/images/kids/fun_corner_icon.png"),
            content: "Fun Corner...",
            color: "#FF8940",
            route: "KidsHome",
            id: 1,
          },
          {
            title: "kids.learnMore",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            color: "#FF8940",
            route: "KidsHome",
            id: 2,
          },
        ],
      },
      {
        title: "common.teens",
        subtitle: "common.teensAge",
        color: "#9AF2D0",
        darkColor: "#33AB63",
        children: [
          {
            title: "teens.stayAlert",
            image: require("@/assets/images/teens/smart_guard.png"),
            icon: require("@/assets/images/teens/smart_guard_icon.png"),
            content: "Smart Guard...",
            color: "#9AF2D0",
            route: "TeensHome",
            id: 0,
          },
          {
            title: "teens.legally",
            image: require("@/assets/images/teens/stay_informed_legally.png"),
            icon: require("@/assets/images/teens/stay_informed_legally_icon.png"),
            content: "Stay Informed Legally...",
            color: "#9AF2D0",
            route: "TeensHome",
            id: 1,
          },
          {
            title: "teens.chill",
            image: require("@/assets/images/teens/chill_out_corner.png"),
            icon: require("@/assets/images/teens/chill_out_corner_icon.png"),
            content: "Chill Out...",
            color: "#9AF2D0",
            route: "TeensHome",
            id: 2,
          },
        ],
      },
      {
        title: "common.adults",
        subtitle: "",
        color: "#7CE1FF",
        darkColor: "#00B1FF",
        children: [
          {
            title: "adults.stayAlert",
            image: require("@/assets/images/parents/smart_guard.png"),
            icon: require("@/assets/images/parents/smart_guard_icon.png"),
            content: "Smart Guard...",
            color: "#7CE1FF",
            route: "AdultHome",
            id: 0,
          },
          {
            title: "adults.legally",
            image: require("@/assets/images/parents/stay_informed_legally.png"),
            icon: require("@/assets/images/parents/stay_informed_legally_icon.png"),
            content: "Stay Informed Legally...",
            color: "#7CE1FF",
            route: "AdultHome",
            id: 1,
          },
          {
            title: "adults.chill",
            image: require("@/assets/images/parents/chill_out_corner.png"),
            icon: require("@/assets/images/parents/chill_out_corner_icon.png"),
            content: "Chill Out...",
            color: "#7CE1FF",
            route: "AdultHome",
            id: 2,
          },
          {
            title: "adults.learnMore",
            image: require("@/assets/images/parents/learn_more.png"),
            icon: require("@/assets/images/parents/learn_more_icon.png"),
            content: "Learn More...",
            color: "#7CE1FF",
            route: "AdultHome",
            id: 3,
          },
          {
            title: "adults.teaching_materials",
            image: require("@/assets/images/parents/teaching_materials.png"),
            icon: require("@/assets/images/parents/teaching_materials_icon.png"),
            route: "AdultHome",
            color: "#7CE1FF",
            id: 4,
          },
          {
            title: "adults.link",
            image: require("@/assets/images/parents/partner_org.png"),
            icon: require("@/assets/images/parents/partner_org_icon.png"),
            content: "Partner Organizations...",
            color: "#7CE1FF",
            route: "AdultHome",
            id: 5,
          },
        ],
      },
      
    ],
    contactItem:
      {
        title: "common.aboutUs",
        route: "AboutUs",
        color: "#fff",
      },
  }),
};
</script>
