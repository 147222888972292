export const config = {
  requirePassword: false,
  versionNo: "v1.5.0",
  // wordpressUrl: "http://192.168.1.112:8083/wordpress/wp-json/wp/v2",
  wordpressUrl: "https://www.childprotection.gov.hk/wp/wp-json/wp/v2",
  // wordpressUrl: "https://202.128.225.229/wp/wp-json/wp/v2",
  // wordpressUrl: "https://202.128.227.95/wp/wp-json/wp/v2",
  showAdditionalMenus: true,
};

export default config;
