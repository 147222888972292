<template>
  <div :class="isMobile ? '' : 'container'">
    <v-row class="mb-3">
      <v-col cols="7">
        <SessionTitle :text="$t('home.bestVideos')" color="#757575" />
      </v-col>
      <v-col class="text-right">
        <!-- <v-progress-circular color="#757575" :value="73" :size="30"><p style="font-size:16px;" class="pt-3">73</p></v-progress-circular> -->
      </v-col>
    </v-row>

    <div class="marquee" v-if="images.length > 0">
      <Marquee
        direction="row"
        :reverse="true"
        :hoverPause="false"
        :speed="speed"
        :pause="paused"
      >
        <div class="marquee-content">
          <figure
            v-for="(src, i) in videoList"
            :key="i"
            class="image-frame"
            @mouseover="slowDown"
            @mouseout="accelerate"
            @click="popUpDialog(src.videoId)"
          >
            <v-img
              class="d-flex justify-center align-center text-center"
              :src="src.image"
              :width="isMobile ? '70vw' : '20vw'"
              :height="isMobile ? '20vh' : '23vh'"
              style="border-radius: 15px"
            >
              <v-btn color="white" fab dark :x-large="!isMobile">
                <v-icon
                  color="grey"
                  :size="windowWidth * (isMobile ? 0.07 : 0.03)"
                >
                  mdi-play
                </v-icon>
              </v-btn>
            </v-img>
          </figure>
        </div>
      </Marquee>
    </div>
    <v-dialog
      persistent
      v-model="dialog"
      scrollable
      :width="isMobile ? '90vw' : '85vw'"
    >
      <v-card :height="isMobile ? '50vh' : '85vh'">
        <v-card-title>
          <v-row>
            <v-col cols="10"> {{ $t("home.bestVideos") }} </v-col>
            <v-col cols="2">
              <v-btn
                absolute
                top
                right
                icon
                color="red"
                class="btn"
                @click="closeVideo()"
              >
                <v-icon> mdi-close-circle </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <youtube
          :video-id="videoId"
          ref="youtube"
          :player-vars="{ autoplay: 1 }"
          width="100%"
          height="100%"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Marquee from "vue-dynamic-marquee";
import VueLazyload from "vue-lazyload";
import Vue from "vue";
import SessionTitle from "../atoms/SessionTitle.vue";
Vue.use(VueLazyload, {
  observer: true,
  loading: "",
});

const normalSpeed = 80;

function randomUrl() {
  const random = () => Math.round(Math.random() * 50 + 30) * 10;
  return `https://source.unsplash.com/random/${random() *
    1.3}x${random()}?monument,history`;
}

export default {
  name: "DynamicMarquee",
  components: {
    Marquee,
    SessionTitle,
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  data: () => ({
    images: [],
    speed: { type: "pps", number: normalSpeed },
    state: "playing",
    paused: false,
    length: 0,
    dialog: false,
    videoId: "",
    videoList: [
      {
        image: require(`@/assets/images/bestVideo/1.jpg`),
        videoId: "0hEKJI4M1Js",
      },
      // {
      //   image: require(`@/assets/images/bestVideo/2.png`),
      //   videoId: "exCvN6EMNLA",
      // },
      {
        image: require(`@/assets/images/bestVideo/3.png`),
        videoId: "tVJt2ZbJ4qE",
      },
      {
        image: require(`@/assets/images/bestVideo/4.png`),
        videoId: "omer4QntwU8",
      },
      {
        image: require(`@/assets/images/bestVideo/5.png`),
        videoId: "3VNnfWzAibk",
      },
      {
        image: require(`@/assets/images/bestVideo/6.png`),
        videoId: "TLh2sJQFRAw",
      },
      {
        image: require(`@/assets/images/bestVideo/7.png`),
        videoId: "4qGlz83A-X4",
      },
      {
        image: require(`@/assets/images/bestVideo/8.png`),
        videoId: "punotmEsaro",
      },
      {
        image: require(`@/assets/images/bestVideo/9.png`),
        videoId: "NErYStRwZ6s",
      },
      {
        image: require(`@/assets/images/bestVideo/10.png`),
        videoId: "-XUn_EZGmNU",
      },
    ],
  }),
  methods: {
    slowDown() {
      console.log("a");

      this.state = "slowing";
      const loop = () => {
        if (this.state !== "slowing") return clearInterval(interval);
        const { speed } = this;
        if (speed.number < 1) {
          this.paused = true;
          return clearInterval(interval);
        }
        speed.number *= 0.5;
      };

      const interval = setInterval(loop, 30);
    },
    accelerate() {
      console.log("b");

      this.state = "acc";
      this.paused = false;
      const loop = () => {
        if (this.state !== "acc") return clearInterval(interval);
        const { speed } = this;
        if (speed.number > normalSpeed - 1) {
          speed.number = normalSpeed;
          return clearInterval(interval);
        }
        speed.number /= 0.5;
      };

      const interval = setInterval(loop, 20);
    },
    popUpDialog(vid) {
      this.dialog = !this.dialog;
      this.videoId = vid;
      this.slowDown();
    },
    closeVideo() {
      this.dialog = !this.dialog;
      this.player.stopVideo();
      this.accelerate();
    },
  },
  created() {
    for (let i = 0; i < 12; i++) {
      this.images.push(randomUrl());
    }
  },
};
</script>

<style lang="scss" scoped>
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.marquee {
  position: relative;
  width: 100%;
  height: 30vh;
  margin: 0 auto;
}
.marquee-mobile {
  position: relative;
  width: 100%;
  height: 30vh;
  margin: 0 auto;
}
.marquee-content {
  height: 100%;
  padding: 0;
  display: flex;
}

.image-frame {
  width: auto;
  height: 150px;
  position: relative;
  margin-right: 30px;
  display: block;
}

// .image-frame:hover .overlay {
// 	opacity: 0.7;
// }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}

.image-frame .icon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.interaction {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-10px, 100%);
  display: flex;

  caption {
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 13px;
    color: #333;
    margin: 10px;
  }

  transition: all 0.3s;
}

.image-frame:hover .interaction {
  transform: translate(-20px, 20px);
  background: #fafafa;
  :not(caption) {
    opacity: 1;
  }
}
.image {
  height: 100%;

  transition: opacity 2s;
  &[lazy="loading"] {
    opacity: 0;
  }
}
</style>
