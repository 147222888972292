export const sexualAbuse = {
  title: {
    en: "Sexual Assault",
    tc: "性侵犯",
    sc: "性侵犯",
  },
  img: "hh3.png",
  col: 6,
  btns: [
    {
      tx: "common.go",
      // color: "#E36666",
    },
  ],
  children: [
    {
      contentHeader: {
        en: "Sexual Assault",
        tc: "性侵犯",
        sc: "性侵犯",
      },
      title: {
        en: "Sexual Assault",
        tc: "性侵犯",
        sc: "性侵犯",
      },
      img: "teens_sexualAbuse.png",
      aspectRatio: 315 / 277,
      showTips: true,
      points: [
        {
          en: "Say “NO” and shout for help!",
          tc: "說「不」及高聲呼救！",
          sc: "说「不」及高声呼救！",
        },
        {
          en: "Remember the details (where, when, who) of the incident",
          tc: "記下事發經過的詳細資料（時間、地點、人物）。",
          sc: "记下事发经过的详细资料（时间、地点、人物）。",
        },
        {
          en: "Don’t keep it to yourself!",
          tc: "不要啞忍！",
          sc: "不要哑忍！",
        },
        {
          en: "Tell a trusted person.",
          tc: "把事情告知你信任的成年人。",
          sc: "把事情告知你信任的成年人。",
        },
        {
          en:
            "For serious sexual offences such as rape, securing evidence is very crucial, e.g. retain clothing of victim, do not take a shower before medical examination.",
          tc:
            "在面對嚴重性罪行（如強姦）的時候，確保證據得以保存非常重要，例如保留有關衣物，在接受醫療檢驗前不要洗澡。",
          sc:
            "在面对严重性罪行（如强奸）的时候，确保证据得以保存非常重要，例如保留有关衣物，在接受医疗检验前不要洗澡。",
        },
        {
          en: "Report the case to police immediately.",
          tc: "立即報警。",
          sc: "立即报警。",
        },
      ],
    },
  ],
};
