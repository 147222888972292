<template>
  <v-card
    style="border-radius: 14px; width: 100%; position: relative; margin: auto"
    :style="containerStyle"
    max-width="600"
  >
    <a v-if="onCardClick">
      <v-img
        :v-if="image != null"
        :aspect-ratio="aspectRatio"
        :src="getImageUrl(image)"
        cover
        width="100%"
        style="margin: auto"
      >
      </v-img>
    </a>

    <v-img
      :v-if="image != null"
      :aspect-ratio="aspectRatio"
      :src="getImageUrl(image)"
      cover
      width="100%"
      style="margin: auto"
      v-else
    >
    </v-img>
    <div style="flex-direction: column" class="d-flex justify-center px-2">
      <v-card-text class="pa-2">
        <!-- Title -->
        <div
          v-if="title"
          class="d-flex align-center justify-center text-center"
          style="height: 30px; margin: auto;"
        >
          <span
            id="title"
            :style="{ 'font-size': isMobile ? '12px' : '20px' } "
            :class="isMobile ? '' : 'pb-1'"
          >
            {{ title }} 
          </span>
        </div>
        <!-- Tips -->
        <div v-if="showTips" class="d-flex align-center justify-center ma-2">
          <div>
            <v-img
              :src="getImageUrl('tips.png')"
              width="38"
              height="38"
              contain
            ></v-img>
          </div>
          <span id="tips-tx">{{ $t("helpingHand.tips") }}</span>
        </div>

        <!-- Sub title -->
        <div v-if="subTitle" class="d-flex align-center justify-center mb-2">
          <span id="sub-title">
            {{ subTitle }}
          </span>
        </div>

        <!-- Content -->
        <v-card-subtitle
          v-if="content"
          class="px-0 pb-0 mb-2 pt-0 content d-flex align-center justify-center"
          :style="{ 'min-height': contentMinHeight ? '70px' : '0' }"
          :class="showAllContent ? '' : 'content-max-3lines'"
        >
          {{ content }}
        </v-card-subtitle>

        <!-- Bullet Points -->
        <div
          v-if="points && points.length > 0"
          class="d-flex justify-start flex-column bullet-points-container"
        >
          <div
            v-for="(point, i) in points"
            :key="i"
            class="d-flex align-baseline justify-start mb-2"
            :style="{ display: 'none' }"
          >
            <span>
              <v-img
                class="mr-2"
                :src="getImageUrl('star.png')"
                width="10"
                height="10"
                contain
              />
            </span>

            <!-- Normal Case -->
            <span class="bullet-points-tx" v-if="typeof point == 'string'">
              {{ point }}
            </span>

            <!-- Read More Case -->
            <div class="bullet-points-tx" v-if="typeof point == 'object'">
              {{ point.tx }}
              <BulletPointReadMore :details="point.details" />
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <v-container v-if="btns && btns.length > 0" class="px-0">
          <v-row
            class="mx-0"
            :class="
              btns.length == 1 ? 'justify-center' : 'justify-space-between'
            "
          >
            <v-btn
              class="mb-2"
              rounded
              v-for="(btn, i) in btns"
              :key="i"
              :color="btn.color != null ? btn.color : btnColor"
              style="height: 24px; font-size: 12px; font-weight: bold"
              :style="{ width: btns.length == 1 ? '100%' : '120px' }"
              @click="btn.onClick ? btn.onClick() : true"
            >
              <span v-if="btn.tx" style="color: #fff"> {{ $t(btn.tx) }} </span>
            </v-btn>
          </v-row>
        </v-container>

        <!-- Read More Button -->
        <v-card-subtitle
          v-if="showReadMore"
          class="pa-0 px-2 text-right"
          style="color: 1569bc"
          @click="showAllContent = !showAllContent"
        >
          {{
            !showAllContent ? $t("common.more") + "" : $t("common.less") + ""
          }}
        </v-card-subtitle>
      </v-card-text>
    </div>
  </v-card>
</template>

<style lang="scss">
.content-max-3lines {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 3; /* number of lines to show */
  // -webkit-box-orient: vertical;
}
#tips-tx {
  color: #9e6900;
  font-size: 24px;
  margin-left: 8px;
}
.bullet-points-container {
  width: 90%;
  margin: 0 auto 30px auto;
}
.bullet-points-tx {
  color: #565656;
  font-size: 14px;
  width: 100%;
}
#title {
  font-size: 20px;
  color: #565656;
  font-weight: bold;
  white-space: pre-wrap;
}
#sub-title {
  font-size: 16px;
  color: #565656;
  white-space: pre-wrap;
}
.content {
  font-size: 14px;
  color: #565656;
  white-space: pre-wrap;
  text-align: center;
}
</style>

<script>
// import color from "dominant-color";
import FastAverageColor from "fast-average-color";
import BulletPointReadMore from "../molecules/BulletPointReadMore.vue";
const { hex } = require("wcag-contrast");
let fac;
// @ is an alias to /src

export default {
  name: "CommonImageCard",
  components: {
    BulletPointReadMore,
  },
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    content: {
      type: String,
    },
    contentMinHeight: {
      type: Boolean,
      default: true,
    },
    image: {
      type: String,
    },
    btns: {
      type: Array,
      default: () => [],
    },
    showReadMore: {
      type: Boolean,
      default: false,
    },
    showTips: {
      type: Boolean,
      default: false,
    },
    points: {
      type: Array,
      default: () => [],
    },
    aspectRatio: {
      type: Number | String | null,
      default: 1.6,
    },
    onCardClick: {
      type: Boolean,
      default: false,
    },
    containerStyle: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    openUrl(url) {
      if (url) {
        // window.location.href = url;
        window.open(url, "_blank").focus();
      }
    },
    getImageUrl(img) {
      if (img) {
        return require(`@/assets/images/helping_hand/${img}`);
      }
    },
    async getMainImageColor(img) {
      let btns = this.btns;
      if (btns && btns.length > 0) {
        let mainColor = "#042851";
        fac = new FastAverageColor();

        await fac
          .getColorAsync(this.getImageUrl(img), {
            ignoredColor: [
              [255, 255, 255, 255], // white
              [0, 0, 0, 255], // black
              // [183, 183, 183, 255], // grey
              // [221, 88, 74, 255], // red
              // [140, 163, 223, 255], //blue
            ],
          })
          .then((color) => {
            // console.log("Average color", color);

            mainColor = color.hex;
            let contrast = hex(color.hex, "#fff");
            this.btnColor = contrast >= 5 ? color.hex : "#6b6d7f";
          })
          .catch((e) => {
            // console.log(e);
          });

        fac.destroy();
      }
    },
  },
  async created() {
    await this.getMainImageColor(this.image);
  },
  data: () => ({
    items: [],
    isVisible: true,
    showAllContent: false,
    btnColor: "#042851",
  }),
  watch: {
    content: function(cur, prev) {
      if (cur != prev) {
        this.showAllContent = false;
        this.getMainImageColor(this.image);
      }
    },
  },
};
</script>
