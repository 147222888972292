<template>
  <v-carousel
    height="auto"
    hide-delimiter-background
    delimiter-icon="mdi-minus"
    :show-arrows="false"
    cycle
    @change="onSlideChange"
    :interval="interval"
    style="margin: auto; padding-top: 10px;"
  >
    <v-carousel-item v-for="(item, i) in items" :key="i">
      <youtube v-if="item.isVideo" :video-id="item.src" ref="youtube" width="100%" height="100%"/>
      <v-img v-if="!item.isVideo" :src="item.src" :cover="true"></v-img>
    </v-carousel-item>
  </v-carousel>
</template>



<style lang="scss" scoped>
.v-carousel {
  width: 90%;
}
</style>
<script>
// @ is an alias to /src

export default {
  name: "JumpingJackBanner",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({
    interval: 10000,
    items: [
      {
        src: "2qHn3soaUkI",
        isVideo: true
      },
      {
        src: "https://www.police.gov.hk/info/img/index/b_banner_svi_en.jpg",
        isVideo: false
      },
      {
        src: "https://www.police.gov.hk/info/img/index/b_banner_awp.jpg",
        isVideo: false
      },
      {
        src: "https://www.police.gov.hk/info/img/index/b_banner_adcc.jpg",
        isVideo: false
      },
    ],
  }),
  methods: {
     onSlideChange(slideNumber) {
        if (slideNumber === 0) {
            this.interval = 45000;
        } else {
            this.interval = 6000;
        }
    }
  }
};
</script>
