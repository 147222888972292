var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",class:_vm.isMobile ? '' : 'container'},[_c('v-row',[_c('v-col',{staticStyle:{"background-color":"#231816"},style:({
        'background-color': _vm.banners.length > 0 ? '#231816' : 'tramspraent',
        padding: _vm.isMobile ? '0' : '0 24px 12px 24px',
      })},[(_vm.banners.length > 0)?_c('v-carousel',{ref:"carousel",staticStyle:{"padding-bottom":"20px"},attrs:{"height":"auto","hide-delimiter-background":"","show-arrows":false,"interval":_vm.interval,"light":false},on:{"change":_vm.setInterval},model:{value:(_vm.carouselIndex),callback:function ($$v) {_vm.carouselIndex=$$v},expression:"carouselIndex"}},_vm._l((_vm.banners),function(item,i){return _c('v-carousel-item',{key:i},[_c('v-responsive',{staticClass:"d-flex justify-center mb-2",attrs:{"width":"100%","aspect-ratio":"2","id":"video-container-1"}},[(item.type == 'youtube')?_c('youtube',{ref:'youtube-' + i,refInFor:true,attrs:{"video-id":_vm.$youtube.getIdFromUrl(_vm.filterValue(item.youtube)),"player-vars":{ autoplay: 1 },"width":_vm.width,"height":_vm.height},on:{"ended":function($event){return _vm.next()}}}):_vm._e(),(item.type == 'image')?_c('v-img',{attrs:{"src":_vm.filterValue(item.image),"contain":"","width":_vm.width,"height":_vm.height}}):_vm._e(),(item.type == 'media')?_c('video-player',{ref:'videoPlayer-' + i,refInFor:true,staticClass:"video-player-box vjs-big-play-centered",attrs:{"options":{
                // videojs options
                width: _vm.width,
                height: _vm.height,
                autoplay: true,
                muted: true,
                language: 'en',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                  {
                    type: 'video/mp4',
                    src: _vm.filterValue(item.video),
                  },
                ],
              },"playsinline":true},on:{"ended":function($event){return _vm.next()}}}):_vm._e()],1)],1)}),1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{class:_vm.isMobile ? '' : 'mt-4',style:({
        'background-color': _vm.filterValue(_vm.bannerDesc) ? 'white' : 'transparent',
      })},[_c('p',{staticClass:"mb-0 b-tx c-font-family",style:({
          'font-size': _vm.isMobile ? '3vw' : '1.25vw',
          color: _vm.isMobile ? 'grey' : '#005385',
        })},[_vm._v(" "+_vm._s(_vm.filterValue(_vm.bannerDesc))+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }