<template>
  <v-breadcrumbs
    :items="items"
    :style="{ display: isVisible ? 'flex' : 'none' }"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        @click="$router.push({ name: item.href })"
        :disabled="item.disabled"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<style lang="scss">
.title {
  margin-bottom: 0;
}

.v-breadcrumbs__item {
  color: #2e5fb7;
}
</style>

<script>
// @ is an alias to /src

const hideBreadcrumbsList = ["Home", "AdultHome", "HelpingHand"];

export default {
  name: "Breadcrumbs",
  components: {},
  props: ["name"],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({
    //
    items: [],
    isVisible: true,
  }),
  created() {
    this.items.push({
      text: this.$route.name,
      disabled: true,
    });

    if (hideBreadcrumbsList.includes(this.$route.name)) {
      this.isVisible = false;
    }
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      console.log("ddd", this.$route.name);
      this.items = [
        {
          text: "Home",
          disabled: false,
          href: "Home",
        },
        {
          text: "Adults",
          disabled: false,
          href: "AdultHome",
        },
      ];
      this.items.push({
        text: this.$route.name,
        disabled: true,
      });

      if (hideBreadcrumbsList.includes(this.$route.name)) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    },
  },
};
</script>
