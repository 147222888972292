<template>
  <v-bottom-navigation v-model="value" fixed app height="8vh">
    <v-btn value="home" @click="navigate('Home')" depressed>
      <span :class="isMobile ? '' : 'text-subtitle-1'">
        {{ $t("common.home") }}</span
      >
      <v-icon :x-large="!isMobile">mdi-home</v-icon>
    </v-btn>

    <v-btn
      style="width: 40%"
      value="helping-hand"
      @click="handleHelpingHandNavigation()"
      depressed
    >
      <span style="color: red">{{ $t("common.needHelp") }}</span>
      <v-icon style="visibility: hidden">mdi-web</v-icon>
      <v-img
        style="position: absolute; bottom: 3.5vh; width: 64px"
        :src="require('../../assets/images/helping_hand_btn.png')"
      />
    </v-btn>

    <v-btn
      value="helping-hand"
      @click="openHotlineDialog()"
      depressed
    >
      <span style="color: red">{{ $t("common.letstalk") }}</span>
      <v-icon style="visibility: hidden">mdi-web</v-icon>

      <span style="position: absolute; bottom: 3.5vh; width: 64px; height:64px; padding-top: 7px;">
        <v-avatar color="#ff2c00" size="50" class="mx-1">
          <v-icon color="white" size="40">mdi-phone</v-icon>
        </v-avatar>
      </span>
    </v-btn>

    <!-- <v-btn @click="openHotlineDialog()" depressed>
      <span :class="isMobile ? '' : 'text-subtitle-1'" class="red--text">
        {{ $t("common.letstalk") }}</span
      >
      <v-avatar color="#ff2c00" size="24">
        <v-icon color="white" size="15">mdi-phone</v-icon>
      </v-avatar>
    </v-btn> -->

    <!-- <v-menu top :close-on-click="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn value="lang" v-bind="attrs" v-on="on">
          <span :class="isMobile ? '' : 'text-subtitle-1'">{{
            $t("common.language")
          }}</span>
          <v-icon :x-large="!isMobile">mdi-web</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="changeLang(item.value)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </v-bottom-navigation>
</template>
<style lang="scss" scored>
.v-menu {
  min-width: "50%";
}
</style>

<style lang="scss"></style>

<script>
// @ is an alias to /src
import $ from "jquery";

export default {
  name: "BottomNavigation",
  components: {},
  computed: {
    currentRouteName() {
      console.log(this.$route);
      return this.$route.name;
    },
  },
  data: () => ({
    value: "recent",
    items: {
      tc: {
        title: "繁",
        value: "tc",
      },
      sc: {
        title: "简",
        value: "sc",
      },
      en: {
        title: "EN",
        value: "en",
      },
    },
  }),
  methods: {
    openHotlineDialog() {
      $("#hotline-dialog-btn").click();
    },
    changeLang(value) {
      this.$i18n.locale = value;
    },
    handleHelpingHandNavigation() {
      const route = this.$route;
      if (route.fullPath.includes("Kids")) {
        this.navigate("KidsHelpingHand");
      } else if (route.fullPath.includes("Teens")) {
        this.navigate("TeensHelpingHand");
      } else if (route.fullPath.includes("Adult")) {
        this.navigate("AdultHelpingHand");
      } else {
        this.navigate("HelpingHand");
      }
    },
    navigate(route) {
      if (this.currentRouteName == route) {
        window.location.reload();
      } else {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>
