<template>
  <v-container>
    <div>
      <v-row>
        <v-spacer />
        <v-col :cols="isMobile ? 12 : 12">
          <HorizontalCard
            :items="data.info[0]"
            :isShowMore="false"
            v-if="data && data.info != undefined && data.info.length > 0"
            class="mb-4"
          />
        </v-col>
        <v-spacer />
      </v-row>
      <YoutubePopUp
        :videoList="data.videoList"
        v-if="data && data.videoList != null && data.videoList.length > 0"
      />
    </div>
  </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import YoutubeBanner from "@/components/organisms/YoutubeBanner.vue";
import YoutubePopUp from "@/components/organisms/YoutubePopUp.vue";

import Card from "@/components/organisms/Card.vue";
import HorizontalCard from "@/components/organisms/HorizontalCard.vue";
export default {
  name: "VideoPage",
  components: {
    YoutubeBanner,
    YoutubePopUp,
    Card,
    HorizontalCard,
  },
  computed: {},
  props: ["data"],
  data: () => ({
    // data: [],
  }),
  created() {
    // this.data = this.getData(this.$route.params.id);
  },
  updated() {
    // this.data = this.getData(this.$route.params.id);
  },
  methods: {},
};
</script>
