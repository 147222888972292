<template>
  <div class="justify-center align-center mb-6">
    <youtube
      :video-id="$youtube.getIdFromUrl(filterValue(item.videoId))"
      ref="youtube"
      :player-vars="{ autoplay: 1 }"
      width="100%"
    />

    <div v-html="$t(fixWordpressUrl(filterValue(item.desc)))" />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import BackButton from "@/components/atoms/BackButton.vue";

export default {
  components: {
    BackButton,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
};
</script>
